import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapSmall } from 'globalStyles';
import { ReportRequest6DTO } from 'models/dto/Reports/ReportRequests_6_DTO';
import Norm from 'models/norm';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IReportRequestOptions } from '../ReportRequest';

const ReportOptions6 = (props: IReportRequestOptions) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'adminReports']);
  const [selectedStandard, setSelectedStandard] = useState<Norm | undefined>(undefined);
  const [selectedCustomStandards, setSelectedCustomStandards] = useState<Norm[]>([]);

  useEffect(() => {
    const isoStandards = appContext.globalDataCache.norms.getItemsISO();
    let tempISOStandard: Norm | undefined = undefined;
    let tempCustomStandards: Norm[] = [];

    if (isoStandards.length > 0) {
      tempISOStandard = isoStandards[0];
      setSelectedStandard(tempISOStandard);
    } else {
      const customStandards = appContext.globalDataCache.norms.getItemsCustom();
      if (customStandards.length > 0) {
        tempCustomStandards = [customStandards[0]];
        setSelectedCustomStandards(tempCustomStandards);
      }
    }
    updateOptions(tempISOStandard, tempCustomStandards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOptions = (isoStandard: Norm | undefined, customStandards: Norm[]) => {
    const reportRequest = (props.reportRequest as ReportRequest6DTO).clone();
    reportRequest.standard = isoStandard;
    reportRequest.customStandards = customStandards;
    props.onUpdate(reportRequest, isoStandard !== undefined || customStandards.length > 0);
  };

  const getISOStandardOptions = (): IDropdownOption[] => {
    const isoStandards = appContext.globalDataCache.norms.getItemsISO();

    const items: IDropdownOption[] = isoStandards.map((s) => {
      return {
        key: s.isoNormId ?? 0,
        text: s.name,
        data: s,
      };
    });

    if (items.length === 0) {
      items.push({
        key: 0,
        text: t('adminReports:Dialogs.Request.Options.Report6.NoISOStandards'),
        data: undefined,
      });
    }

    return items;
  };

  const getCustomStandardOptions = () => {
    const customStandards = appContext.globalDataCache.norms.getItemsCustom();

    return customStandards.map((s) => {
      return {
        key: s.normId ?? 0,
        text: s.name,
        data: s,
      };
    });
  };

  //
  // Main render
  //
  return (
    <Stack tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Dropdown
          label={t('adminReports:Dialogs.Request.Options.Report6.Standard')}
          selectedKey={selectedStandard?.isoNormId}
          options={getISOStandardOptions()}
          calloutProps={{ calloutMaxHeight: 250 }}
          onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            setSelectedStandard(option?.data as Norm);
            updateOptions(option?.data as Norm, selectedCustomStandards);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          label={t('adminReports:Dialogs.Request.Options.Report6.CustomStandards')}
          selectedKeys={selectedCustomStandards.map((s) => s.normId)}
          options={getCustomStandardOptions()}
          multiSelect
          disabled={getCustomStandardOptions().length === 0}
          calloutProps={{ calloutMaxHeight: 250 }}
          onChange={(ev, option) => {
            let newSelectedCustomStandards = [...selectedCustomStandards];
            if (option?.selected === true) {
              newSelectedCustomStandards.push(option.data);
            } else {
              newSelectedCustomStandards = newSelectedCustomStandards.filter((a) => a.normId !== option?.data.normId);
            }
            setSelectedCustomStandards(newSelectedCustomStandards);
            updateOptions(selectedStandard, newSelectedCustomStandards);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default ReportOptions6;
