import { SubscriptionTypes } from 'utils/subscription';

export default class StripeSubscription {
  type: SubscriptionTypes;

  numberOfSeats: number;

  payPerYear: boolean;

  isActive: boolean;

  start: Date;

  cancelAt?: Date;

  canceledAt?: Date;

  minQuantity: number;

  maxStandards: number;

  userFactor: number;

  countryCode?: string;

  returnUrl?: string;

  constructor() {
    this.type = SubscriptionTypes.Basic;
    this.numberOfSeats = 1;
    this.payPerYear = false;
    this.isActive = false;
    this.start = new Date();
    this.minQuantity = 0;
    this.maxStandards = 0;
    this.userFactor = 0;
  }

  static getPrice(type: SubscriptionTypes) {
    switch (type) {
      case SubscriptionTypes.Basic:
        return 59;
      case SubscriptionTypes.Business:
        return 85;
      case SubscriptionTypes.Premium:
        return 119;
    }

    return 0;
  }

  static getUserFactor(type: SubscriptionTypes) {
    switch (type) {
      case SubscriptionTypes.Basic:
        return 25;
      case SubscriptionTypes.Business:
        return 50;
      case SubscriptionTypes.Premium:
        return 100;
    }

    return 100;
  }

  static getMaxStandards(type: SubscriptionTypes) {
    switch (type) {
      case SubscriptionTypes.Basic:
        return 2;
      case SubscriptionTypes.Business:
        return 4;
      case SubscriptionTypes.Premium:
        return -1;
    }

    return -1;
  }

  static getMinQuantity(type: SubscriptionTypes) {
    switch (type) {
      case SubscriptionTypes.Basic:
        return 1;
      case SubscriptionTypes.Business:
        return 1;
      case SubscriptionTypes.Premium:
        return 1;
    }

    return 1;
  }
}
