import React, { useContext, useRef, useState } from 'react';
import { PrimaryButton, Stack, Persona, PersonaSize, Text } from '@fluentui/react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import User from 'models/user';
import RichTextEditor, { IRichTextEditor } from 'components/Text/RichTextEditor';

interface IAddCommentProps extends WithTranslation {
  onSend: (newComment: string, mentions: User[]) => void;
  isActionPending: boolean;
}

//
// In this component, features to mentions have been disabled
//
const AddComment = (props: IAddCommentProps) => {
  const { t } = useTranslation(['Activity']);
  const appContext = useContext(AppContext);
  const [value, setValue] = useState<string>('');
  const [checkInputHasFocus, setCheckInputHasFocus] = useState<boolean>(false);
  const editorRef = useRef<IRichTextEditor | null>(null);

  const setNewValue = (newValue: string | undefined) => {
    setValue(newValue ? newValue : '');
  };

  const sendMessage = () => {
    try {
      props.onSend(value, []);
      setValue('');
      editorRef.current?.focus();
    } catch (error) {
      console.error(error);
    }
  };

  const onKeyDown = (e: Partial<React.KeyboardEvent>) => {
    if (e.ctrlKey === true && e.key === 'Enter' && value.length > 0) {
      sendMessage();
    }
  };

  //
  // Main render
  //
  return (
    <Stack horizontal tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <Persona text={appContext.user.name} hidePersonaDetails={true} size={PersonaSize.size24} />
      </Stack.Item>
      <Stack.Item grow>
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <RichTextEditor
              ref={editorRef}
              placeholder={props.t('commentTrail:Add-placeholder')}
              placeholderReadonly={props.t('commentTrail:Readonly-placeholder')}
              html={value}
              maxLength={4000}
              onChange={(value) => {
                setNewValue(value);
              }}
              disallowFullscreen={true}
              maxHeight={300}
              compactToolbar={true}
              onFocus={() => setCheckInputHasFocus(true)}
              onBlur={() => {
                setCheckInputHasFocus(false);
              }}
              onKeyDown={onKeyDown}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
              <PrimaryButton
                disabled={props.isActionPending || value === ''}
                onClick={() => {
                  sendMessage();
                }}
              >
                {props.t('commentTrail:Send')}
              </PrimaryButton>
              {checkInputHasFocus && <Text variant="small">{t('activity:KeyTipNew')}</Text>}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default withTranslation(['translation', 'commentTrail', 'activity'])(AddComment);
