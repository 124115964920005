import React, { createRef, useRef, useState } from 'react';
import { Stack, PrimaryButton, Separator, ITextField, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall } from 'globalStyles';
import RichTextEditor, { IRichTextEditor } from 'components/Text/RichTextEditor';

interface IAddNewCheckListItem {
  newCheckListDescription: string;
  setNewCheckListDescription: (_newCheckListDescription: string) => void;
  allowEdit: boolean;
  singleLineEdit?: boolean;
  onAddNewCheckListItem: () => void;
}

export const AddNewCheckListItem = (props: IAddNewCheckListItem) => {
  const { t } = useTranslation(['task']);
  const checkInputRef = createRef<ITextField>();
  const [checkInputHasFocus, setCheckInputHasFocus] = useState<boolean>(false);
  const editorRef = useRef<IRichTextEditor | null>(null);

  const onKeyDown = (e: Partial<React.KeyboardEvent>) => {
    if (e.ctrlKey === true && e.key === 'Enter' && props.newCheckListDescription.length > 0) {
      props.onAddNewCheckListItem();
      if (e.preventDefault) e.preventDefault();
    }
  };

  return (
    <Stack tokens={globalStackTokensGapSmall} horizontal={props.singleLineEdit}>
      {!props.singleLineEdit && <Separator />}
      <Stack.Item grow>
        <RichTextEditor
          className="redlab-usetiful-task-checklist-additem"
          ref={editorRef}
          placeholder={t('task:CheckList.AddPlaceHolder')}
          html={props.newCheckListDescription}
          maxLength={1024}
          onChange={(value) => {
            if (!value) {
              props.setNewCheckListDescription('');
            } else {
              props.setNewCheckListDescription(value);
            }
          }}
          disallowFullscreen={true}
          maxHeight={300}
          compactToolbar={true}
          onFocus={() => setCheckInputHasFocus(true)}
          onBlur={() => {
            setCheckInputHasFocus(false);
          }}
          onKeyDown={onKeyDown}
          hideRemainingChars={true}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
          <PrimaryButton
            disabled={props.newCheckListDescription === '' || !props.allowEdit}
            onClick={() => {
              checkInputRef.current?.focus();
              props.onAddNewCheckListItem();
            }}
          >
            {t('task:CheckList.Add')}
          </PrimaryButton>
          {!props.singleLineEdit && checkInputHasFocus && <Text variant="small">{t('task:CheckList.KeyTipNew')}</Text>}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
