import { Stack, TooltipHost, getTheme } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { ISingleTag, SingleTag } from 'components/Tags/SingleTag';
import { globalStackTokensGapExtraSmall } from 'globalStyles';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

//
// Item collection
//
const maxItemsDefault = 3;
const maxItemsMin = 2;

interface ICellItemType {
  id: number | string;
  text: string;
  bold?: boolean;
}

interface IColumnCellItemsProps<ItemType> {
  items: ItemType[];
  compact?: boolean;
  maxItems?: number;
  showAll?: ItemType;
}

export const ColumnCellItems = <ItemType extends ICellItemType>(props: IColumnCellItemsProps<ItemType>) => {
  const getTooltip = (items: ItemType[]): JSX.Element | undefined => {
    if (items.length === 0) return undefined;

    return (
      <Stack tokens={globalStackTokensGapExtraSmall}>
        {moreItems.map((item) => {
          return <ColumnCellItem key={'cellitem-tooltip' + item.id} item={item} />;
        })}
      </Stack>
    );
  };

  let maxItemToShow = props.maxItems ?? maxItemsDefault;
  if (maxItemsDefault < maxItemsMin) maxItemToShow = maxItemsMin;
  const hasMoreItems = props.items.length > maxItemToShow;
  const itemsToShow = props.items.slice(0, maxItemToShow - (hasMoreItems ? 1 : 0));
  const moreItems = props.items.slice(maxItemToShow - (hasMoreItems ? 1 : 0));

  //
  // Main render
  //
  if (props.showAll) {
    return (
      <Stack horizontal>
        <ColumnCellItem item={props.showAll} compact={props.compact} />
      </Stack>
    );
  } else {
    return (
      <TooltipHost content={getTooltip(moreItems)}>
        <Stack horizontal wrap tokens={globalStackTokensGapExtraSmall}>
          {itemsToShow.map((item) => {
            return <ColumnCellItem key={'cellitem' + item.id} item={item} compact={props.compact} />;
          })}
          {hasMoreItems && <ColumnCellItemMore items={props.items} compact={props.compact} />}
        </Stack>
      </TooltipHost>
    );
  }
};

//
// Item
//
interface IColumnCellItemProps<ItemType> {
  item: ItemType;
  compact?: boolean;
}

export const ColumnCellItem = <ItemType extends ICellItemType>(props: IColumnCellItemProps<ItemType>) => {
  const appContext = useContext(AppContext);
  const theme = getTheme();

  const tag: ISingleTag = {
    tagId: props.item.id,
    tagName: props.item.text,
    tagColor: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
  };

  return <SingleTag key={tag.tagId} tag={tag} compact={props.compact} bold={props.item.bold} />;
};

//
// Item more
//
export const ColumnCellItemMore = <ItemType extends ICellItemType>(props: IColumnCellItemsProps<ItemType>) => {
  const { t } = useTranslation(['translation']);
  const moreCount = props.items.length - (props.maxItems ?? maxItemsDefault) + 1;
  const moreItem: ICellItemType = {
    id: 'moreItem',
    text: t('translation:General.List.MoreCount', { count: moreCount }),
    bold: true,
  };

  return <ColumnCellItem item={moreItem} compact={props.compact} />;
};
