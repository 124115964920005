export enum ReportContext {
  Risks = 1,
  Schedule = 2,
  Controls = 4,
  Assets = 5,
  Processes = 6,
  Requirements = 7,
  Objectives = 8,
  Library = 9,
  Tasks = 10,
}

export enum ReportType {
  RiskAssesment = 1,
  RiskTreatmentPlan = 2,
  OperationalPlanning = 3,
  SOA_Controls = 6,
  Assets = 7,
  ControlImplementation = 8,
  ProcessRiskControl = 9,
  AssetRiskControl = 10,
  RequirementImplementation = 11,
  ProcessDetails = 12,
  ObjectiveDetails = 13,
  AssetDetails = 14,
  LibraryApprovals = 15,
  Tasks = 16,
  RiskChanges = 17,
  ControlSpecification = 18,
}

export default class ReportDefinition {
  id: ReportType;

  name: string;

  description: string;

  icon: string;

  context: ReportContext[];

  contextUrl?: string;

  optionsHeight?: number;

  disableExcelExport?: boolean;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.description = '';
    this.icon = '';
    this.context = [];
  }
}
