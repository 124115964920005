import { toBool } from '../utils/string';
import WorkingHoursPattern from 'models/WorkingHoursPattern';
import RiskScoreModel from 'models/RiskScoreModel';
import { toInteger } from 'lodash';
import AppError from 'utils/appError';

//general settings
export const AutoUserLicense = 'AutoUserLicense';
export const DefLanguageCode = 'DefLanguageCode';
export const AuditYearStart = 'AuditYearStart';
export const RiskScoreDef = 'RiskScoreDef';
export const WorkingHours = 'WorkingHours';
export const OrgLanguageCodes = 'OrgLanguageCodes';
export const ModuleRisks = 'ModuleRisks';
export const ModuleOrg = 'ModuleOrg';
export const ModuleThemes = 'ModuleThemes';
export const ModuleControls = 'ModuleControls';
export const ModuleAssets = 'ModuleAssets';
export const ModuleStandards = 'ModuleStandards';
export const LibraryOpenInModal = 'LibraryOpenInModal';
export const ShowISOElementAds = 'ShowISOElementAds';
export const LegacyOutlookSeriesEnabled = 'LegacyOutlookSeriesEnabled';

//report settings
export const ReportStorageList = 'ReportStorageList';
export const ReportTemplateLink = 'ReportTemplateLink';
export const ReportDefaultStorageBehavior = 'ReportDefaultStorageBehavior';
export const ReportDefaultOutputFormat = 'ReportDefaultOutputFormat';
export const ReportAllowUserSettingOverride = 'ReportAllowUserSettingOverride';
export const ReportKeepWordNextToPDF = 'ReportKeepWordNextToPDF';

//auth settings
export const AzureAD = 'AzureAD';
export const AzureADGroupSync = 'AzureADGroupSync';
export const OutlookTasksAdvanced = 'OutlookTasksAdvanced';
export const OutlookTasksBasic = 'OutlookTasksBasic';
export const SharepointLibraries = 'SharepointLibraries';
export const SharepointLists = 'SharepointLists';
export const SharepointPages = 'SharepointPages';
export const SharepointReports = 'SharepointReports';
export const SharepointSearch = 'SharepointSearch';
export const TeamsKB = 'TeamsKB';
export const PowerBI = 'PowerBI';
export const PowerBIService = 'PowerBIService';
export const TeamsKBTaskTemplate = 'TeamsKBTaskTemplate';
export const TeamsCollaboration = 'TeamsCollaboration';

//automation settings
export const AzureDefender = 'AzureDefender';
export const Office365Defender = 'Office365Defender';
export const PowerAutomateSharePoint = 'PowerAutomateSharePoint';

export const Names = {
  AutoUserLicense,
  DefLanguageCode,
  AuditYearStart,
  WorkingHours,
  RiskScoreDef,
  OrgLanguageCodes,
  SharepointLibraries,
  SharepointLists,
  SharepointPages,
  SharepointReports,
  SharepointSearch,
  TeamsKB,
  TeamsKBTaskTemplate,
  TeamsCollaboration,
  ModuleRisks,
  ModuleOrg,
  ModuleThemes,
  ModuleControls,
  ModuleAssets,
  ModuleStandards,
  LibraryOpenInModal,
  OutlookTasksAdvanced,
  OutlookTasksBasic,
  AzureAD,
  AzureADGroupSync,
  ReportStorageList,
  ReportTemplateLink,
  ReportDefaultStorageBehavior,
  ReportDefaultOutputFormat,
  ReportAllowUserSettingOverride,
  ReportKeepWordNextToPDF,
  AzureDefender,
  PowerAutomateSharePoint,
  Office365Defender,
  PowerBI,
  PowerBIService,
  LegacyOutlookSeriesEnabled,
  ShowISOElementAds,
};

export class Settings {
  items: Setting[];

  constructor() {
    this.items = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(name: string): any {
    const setting = this.items.find((s) => {
      return s.settingName === name;
    });
    if (setting) {
      return setting.getValue();
    } else {
      return undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(name: string, value: any) {
    const setting = this.items.find((s) => {
      return s.settingName === name;
    });
    if (setting) {
      setting.setValue(setting.settingName, value);
    } else {
      return undefined;
    }
  }

  clone(): Settings {
    const newSettings = new Settings();
    newSettings.items = this.items.map((s) => s.clone());

    return newSettings;
  }
}

export default class Setting {
  settingName: string;

  settingValue: string | undefined;

  constructor() {
    this.settingName = '';
    this.settingValue = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValue(): any {
    const boolSettings = [
      AutoUserLicense, SharepointLibraries, SharepointLists, SharepointPages, SharepointReports, SharepointSearch,
      TeamsKB, TeamsCollaboration, ModuleRisks, ModuleOrg, ModuleThemes, ModuleControls, ModuleAssets, ModuleStandards,
      OutlookTasksAdvanced, OutlookTasksBasic, AzureAD, AzureADGroupSync, AzureDefender, PowerAutomateSharePoint,
      Office365Defender, LibraryOpenInModal, ReportAllowUserSettingOverride, ReportKeepWordNextToPDF, ShowISOElementAds,
      PowerBI, PowerBIService, LegacyOutlookSeriesEnabled
    ];

    const intSettings = [
      ReportStorageList, ReportTemplateLink, ReportDefaultStorageBehavior, ReportDefaultOutputFormat
    ];

    if (boolSettings.includes(this.settingName)) {
      return toBool(this.settingValue);
    }

    if (intSettings.includes(this.settingName)) {
      return toInteger(this.settingValue);
    }

    switch (this.settingName) {
      case TeamsKBTaskTemplate:
        return Number.parseInt(this.settingValue ?? '')
      case DefLanguageCode:
      case AuditYearStart:
        return this.settingValue;
      case RiskScoreDef:
        const riskScoreModel: RiskScoreModel = new RiskScoreModel();
        riskScoreModel.fromJSON(this.settingValue);

        return riskScoreModel;
      case WorkingHours:
        const workingHours: WorkingHoursPattern = new WorkingHoursPattern();
        workingHours.fromJSON(this.settingValue);
        
        return workingHours;
      case OrgLanguageCodes:
        if (!this.settingValue) return null;

        return JSON.parse(this.settingValue);
      default:
        throw new AppError('Setting not found: ' + this.settingName);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue(setting: string, value: any) {
    const jsonSettings = [
      RiskScoreDef, WorkingHours, OrgLanguageCodes
    ];

    const stringSettings = [
      AutoUserLicense, DefLanguageCode, AuditYearStart, SharepointLibraries, SharepointLists, SharepointPages,
      SharepointReports, SharepointSearch, TeamsKB, TeamsCollaboration, TeamsKBTaskTemplate, ModuleRisks, ModuleOrg,
      ModuleThemes, ModuleControls, ModuleAssets, ModuleStandards, OutlookTasksAdvanced, OutlookTasksBasic, AzureAD,
      AzureADGroupSync, AzureDefender, PowerAutomateSharePoint, Office365Defender, LibraryOpenInModal, ReportStorageList,
      ReportTemplateLink, ReportDefaultStorageBehavior, ReportDefaultOutputFormat, ReportAllowUserSettingOverride,
      ReportKeepWordNextToPDF, ShowISOElementAds, PowerBI, PowerBIService, LegacyOutlookSeriesEnabled
    ];

    if (jsonSettings.includes(setting)) {
      if (setting === RiskScoreDef) {
        this.settingValue = (value as RiskScoreModel).toJSON();
      } else if (setting === WorkingHours) {
        this.settingValue = (value as WorkingHoursPattern).toJSON();
      } else if (setting === OrgLanguageCodes) {
        this.settingValue = JSON.stringify(value);
      }
    } else if (stringSettings.includes(setting)) {
      this.settingValue = value.toString();
    } else {
      throw new AppError('Setting not found: ' + setting);
    }

    this.settingName = setting;
  }

  clone(): Setting {
    const newSetting = new Setting();
    newSetting.settingName = this.settingName;
    newSetting.settingValue = this.settingValue;

    return newSetting;
  }
}
