import { TFunction } from 'i18next';
import AppError from 'utils/appError';
import logger from 'services/Logging/logService';

export const getGraphSharepointErrorMsg = (error: AppError, t: TFunction<string[]>): string | undefined => {
  logger.debug('getGraphSharepointErrorMsg: start', error);

  if (error.code) {
    switch (error.code.toLowerCase()) {
      case 'itemnotfound':
        return t('sharepoint:Errors.AccessDenied'); //from Sharepoint
      case 'resourcelocked':
        return t('sharepoint:Errors.ResourceLocked'); //from Sharepoint
      case 'unknownerror':
        return t('sharepoint:Errors.AccessDenied'); //from Sharepoint, usually access related 
      case 'accessdenied':
        return t('sharepoint:Errors.AccessDenied'); //from Sharepoint
      case 'user_cancelled':
        return t('sharepoint:Errors.AccessDenied'); //from authService
      case 'consent_required':
        return t('sharepoint:Errors.AccessDenied'); //from authService
    }
  }

  if (error.message) {
    if (error.message.toLowerCase().includes('the resource you are attempting to access is locked')) {
      return t('sharepoint:Errors.ResourceLocked'); //from Sharepoint
    }

    // When backend Graph search fails due to missing permissions.
    // The user or administrator has not consented to use the application.
    if (error.message.includes('AADSTS65001')) {
      return t('sharepoint:Errors.IntegrationDisabled'); //from Entra ID
    }
  }

  logger.debug('getGraphSharepointErrorMsg: could not handle error');

  return undefined;
};

export const getGraphADErrorMsg = (error: AppError, t: TFunction<string[]> | TFunction<string>): string | undefined => {
  logger.debug('getGraphADErrorMsg', error);

  if (error.code) {
    switch (error.code) {
      case 'Authorization_RequestDenied':
        return t('translation:Errors.GuestAccount.NoPermissions');
      case 'user_cancelled':
        return ''; //empty means do not show
      case 'consent_required':
        return ''; //empty means do not show
    }
  }

  return undefined;
};
