import { Callout, IconButton, Stack, IContextualMenuProps, TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  globalStackTokensGapSmall,
  editIcon,
  deleteIcon,
  globalStackTokensGapExtraSmall,
  commentIcon,
} from 'globalStyles';
import { CircleRingIcon, CallOutRowStyle, SkypeCircleCheckIcon, StatusErrorFullIcon } from './CheckListStyles';
import { TaskCheckListItem, TaskCheckListItemState } from 'models/tasks/taskHelperClasses';
import RichTextEditor from 'components/Text/RichTextEditor';
import { Fragment, useState } from 'react';
import RichTextReadOnly from 'components/Text/RichTextReadOnly';
import EditTextFieldCallOut from 'components/CallOuts/EditTextFieldCallOut';

interface ICheckListItemRow {
  index: number;
  setShowIcon: (_itm: number | undefined) => void;
  statusButtonId: string;
  showIcon: number | undefined;
  isCallOutOpen: TaskCheckListItem | undefined;
  setIsCallOutOpen: (_itm: TaskCheckListItem | undefined) => void;
  isEditCallOutOpen: TaskCheckListItem | undefined;
  setIsEditCallOutOpen: (_itm: TaskCheckListItem | undefined) => void;
  item: TaskCheckListItem;
  allowEdit: boolean;
  allowFill: boolean;
  onComment: (item: TaskCheckListItem, comment: string | undefined) => void;
  onDelete: (item: TaskCheckListItem) => void;
  onUpdateState: (item: TaskCheckListItem, state: TaskCheckListItemState) => void;
  onUpdateDescription: (item: TaskCheckListItem, description: string) => void;
}

const getStatusIcon = (state?: TaskCheckListItemState, id?: string): JSX.Element | null => {
  if (state === TaskCheckListItemState.Success) return <IconButton id={id} iconProps={SkypeCircleCheckIcon} />;
  if (state === TaskCheckListItemState.Failed) return <IconButton id={id} iconProps={StatusErrorFullIcon} />;
  if (state === TaskCheckListItemState.ToDo) return <IconButton id={id} iconProps={CircleRingIcon} />;

  return null;
};

export const CheckListItemRow = (props: ICheckListItemRow) => {
  const { t } = useTranslation(['task', 'translation']);
  const [editComment, setEditComment] = useState<boolean>(false);
  const [comment, setComment] = useState<string | undefined>(props.item.comment);

  const updateCheckListItemState = (state: TaskCheckListItemState) => {
    props.onUpdateState(props.item, state);
    props.setIsCallOutOpen(undefined);
  };

  const updateComment = (value: string | undefined) => {
    props.onComment(props.item, value);
  };

  const itemEditMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'edit',
        text: t('task:CheckList.Operations.Edit'),
        iconProps: editIcon,
        onClick: () => {
          if (!props.item) return;

          props.setIsEditCallOutOpen(props.item);
        },
      },
      {
        key: 'delete',
        text: t('task:CheckList.Operations.Remove'),
        iconProps: deleteIcon,
        onClick: () => {
          if (!props.item) return;
          props.onDelete(props.item);
        },
      },
    ],
    directionalHintFixed: true,
    beakWidth: 0,
  };

  const getOperationOptions = () => {
    if (!props.allowFill || props.showIcon !== props.index) return <span />;

    return (
      <Stack horizontal>
        {props.allowFill && (
          <Stack.Item>
            <TooltipHost content={t('task:CheckList.AddComment')}>
              <IconButton iconProps={commentIcon} onClick={() => setEditComment(true)} />
            </TooltipHost>
          </Stack.Item>
        )}
        {props.allowEdit && (
          <Stack.Item>
            <IconButton menuProps={itemEditMenuProps} />
          </Stack.Item>
        )}
      </Stack>
    );
  };

  //
  // Main render
  //
  return (
    <Fragment>
      <Stack
        horizontal
        styles={{ root: { height: '100%' } }}
        verticalAlign="start"
        onMouseOver={() => {
          props.setShowIcon(props.index);
        }}
        onMouseLeave={() => {
          props.setShowIcon(undefined);
        }}
        tokens={globalStackTokensGapSmall}
      >
        <Stack.Item
          styles={{ root: { height: '100%' } }}
          onClick={() => {
            props.setIsCallOutOpen(props.item);
          }}
        >
          <Stack.Item grow>{getStatusIcon(props.item.state, props.statusButtonId)}</Stack.Item>
        </Stack.Item>
        <Stack.Item styles={{ root: { paddingTop: 3, width: '100%' } }}>
          <Stack tokens={globalStackTokensGapExtraSmall}>
            <Stack.Item
              onClick={() => {
                props.setIsCallOutOpen(props.item);
              }}
            >
              <RichTextReadOnly html={props.item?.description} hideBorder={true} linkify={true} stripParagraph={true} />
            </Stack.Item>
            <Stack.Item>
              {(props.item?.comment || editComment) && (
                <RichTextEditor
                  html={comment}
                  directEdit={editComment}
                  onChange={(value) => {
                    setComment(value);
                  }}
                  onAccept={(value, accept) => {
                    setEditComment(false);
                    if (accept) {
                      updateComment(value || undefined);
                    } else {
                      setComment(props.item?.comment);
                    }
                  }}
                  onStartEdit={() => {
                    setEditComment(true);
                  }}
                  placeholder={t('task:CheckList.AddComment')}
                  readOnly={!props.allowFill}
                  onBlur={() => {
                    setEditComment(false);
                    setComment(props.item?.comment);
                  }}
                  maxLength={512}
                  maxHeight={-1}
                  hideRemainingChars={true}
                  hideBorder={true}
                  hideEditIcon={true}
                  disallowFullscreen={true}
                />
              )}
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item styles={{ root: { minWidth: '60px' } }}>{getOperationOptions()}</Stack.Item>
      </Stack>
      {props.allowFill && props.isCallOutOpen && props.isCallOutOpen.id === props.item.id && (
        <Callout
          styles={{
            root: {
              padding: '10px 12px',
            },
          }}
          setInitialFocus
          target={`#${props.statusButtonId}`}
          onDismiss={() => {
            props.setIsCallOutOpen(undefined);
          }}
          beakWidth={16}
        >
          <Stack>
            <Stack
              verticalAlign="center"
              horizontal
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateCheckListItemState(TaskCheckListItemState.Success);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={SkypeCircleCheckIcon} />
              <Stack.Item>{t('task:CheckList.Success')}</Stack.Item>
            </Stack>
            <Stack
              verticalAlign="center"
              horizontal
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateCheckListItemState(TaskCheckListItemState.Failed);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={StatusErrorFullIcon} />
              <Stack>{t('task:CheckList.Failed')}</Stack>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={globalStackTokensGapSmall}
              onClick={() => {
                updateCheckListItemState(TaskCheckListItemState.ToDo);
              }}
              styles={CallOutRowStyle}
            >
              <IconButton iconProps={CircleRingIcon} />
              <Stack> {t('task:CheckList.Todo')}</Stack>
            </Stack>
          </Stack>
        </Callout>
      )}
      {props.isEditCallOutOpen && props.isEditCallOutOpen.id === props.item.id && (
        <EditTextFieldCallOut
          isVisible={true}
          targetId={props.statusButtonId}
          value={props.isEditCallOutOpen.description}
          onClose={() => {
            props.setIsEditCallOutOpen(undefined);
          }}
          onUpdate={(newText: string) => {
            if (props.isEditCallOutOpen) {
              props.onUpdateDescription(props.isEditCallOutOpen, newText);
            }
          }}
          maxLenght={4000}
          width={500}
          isHtml={true}
        />
      )}
    </Fragment>
  );
};
