import {
  DetailsList,
  IColumn,
  IDragDropContext,
  IDragDropEvents,
  mergeStyles,
  SelectionMode,
  Stack,
  Text,
  ScrollablePane,
  ScrollbarVisibility,
} from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddNewCheckListItem } from './AddNewCheckListItem';
import { CheckListItemRow } from './CheckListItemRow';
import {
  getGlobalStackItemStylesPaddingSceneScrollMinHeight,
  globalStackTokensGapLarge,
  globalStackTokensGapSmall,
} from 'globalStyles';
import AppContext from 'App/AppContext';
import { TaskCheckList, TaskCheckListItem, TaskCheckListItemState } from 'models/tasks/taskHelperClasses';
import { LearnMore } from 'components/Notification/Info';
import { globalKB_howToWorkWithFormsChecklist } from 'globalConstants';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { getRandomId } from 'utils/string';

interface ICheckList {
  allowEdit: boolean;
  allowFill: boolean;
  singleLineEdit?: boolean;
  checkList?: TaskCheckList;
  updateCheckList: (newCheckList: TaskCheckList) => void;
}

export const getRandomIds = (count: number | undefined): string[] => {
  return Array.from({ length: count ?? 0 }, () => 'rsb' + getRandomId());
};

const CheckList = (props: ICheckList) => {
  const { t } = useTranslation(['task', 'translation']);
  const appContext = useContext(AppContext);
  const [showIcon, setShowIcon] = useState<number | undefined>(undefined);
  const [_draggedItem, _setDraggedItem] = useState<TaskCheckListItem | undefined>(undefined);
  const [newCheckListDescription, setNewCheckListDescription] = useState<string>('');
  const [items, setItems] = useState<TaskCheckListItem[]>(props.checkList?.items ? props.checkList?.items : []);
  const [isCallOutOpen, setIsCallOutOpen] = useState<TaskCheckListItem | undefined>(undefined);
  const [isEditCallOutOpen, setIsEditCallOutOpen] = useState<TaskCheckListItem | undefined>(undefined);
  const [statusButtonIds, setStatusButtonIds] = useState<string[]>(getRandomIds(props.checkList?.items.length));

  useEffect(() => {
    setStatusButtonIds(getRandomIds(props.checkList?.items.length));
  }, [props.checkList]);

  const onDelete = (item: TaskCheckListItem) => {
    if (!props.checkList) return;

    const _newCheckList = props.checkList.clone();
    _newCheckList.items = _newCheckList.items.filter((itm) => {
      return itm.id !== item.id;
    });

    props.updateCheckList(_newCheckList);
  };

  const onAddNewCheckListItem = () => {
    if (newCheckListDescription === '' || !props.checkList) return;

    const _newCheckList = props.checkList.clone();
    const _taskCheckListItem = new TaskCheckListItem();
    _taskCheckListItem.description = newCheckListDescription;
    _taskCheckListItem.state = TaskCheckListItemState.ToDo;
    _newCheckList.items.push(_taskCheckListItem);
    props.updateCheckList(_newCheckList);
    setNewCheckListDescription('');
  };

  const updateState = (item: TaskCheckListItem, state: TaskCheckListItemState) => {
    if (!props.checkList) return;

    const _newCheckList = props.checkList.clone();
    _newCheckList.items = _newCheckList.items.map((_itm) => {
      if (_itm.id === item.id) {
        _itm.state = state;

        return _itm;
      } else return _itm;
    });
    props.updateCheckList(_newCheckList);
  };

  const updateDescription = (item: TaskCheckListItem, description: string) => {
    if (!props.checkList) return;
    const _newCheckList = props.checkList.clone();
    _newCheckList.items = _newCheckList.items.map((_itm) => {
      if (_itm.id === item.id) {
        _itm.description = description;

        return _itm;
      } else return _itm;
    });
    props.updateCheckList(_newCheckList);
  };

  const updateComment = (item: TaskCheckListItem, comment: string | undefined) => {
    if (!props.checkList) return;

    const _newCheckList = props.checkList.clone();
    _newCheckList.items = _newCheckList.items.map((_itm) => {
      if (_itm.id === item.id) {
        _itm.comment = comment;

        return _itm;
      } else return _itm;
    });
    props.updateCheckList(_newCheckList);
  };

  useEffect(() => {
    setItems(props.checkList?.items ? (props.checkList?.items ? props.checkList?.items : []) : []);
  }, [props.checkList]);

  const columns: IColumn[] = [
    {
      key: 'text',
      name: '',
      minWidth: 50,
      isMultiline: true,
      onRender: (item?: TaskCheckListItem, index?: number, column?: IColumn) => {
        if (!item || index === undefined) return;

        return (
          <CheckListItemRow
            allowEdit={props.allowEdit ?? true}
            allowFill={props.allowFill ?? true}
            index={index}
            setShowIcon={setShowIcon}
            statusButtonId={statusButtonIds[index]}
            showIcon={showIcon}
            isCallOutOpen={isCallOutOpen}
            setIsCallOutOpen={setIsCallOutOpen}
            isEditCallOutOpen={isEditCallOutOpen}
            setIsEditCallOutOpen={setIsEditCallOutOpen}
            item={item}
            onComment={updateComment}
            onDelete={onDelete}
            onUpdateState={updateState}
            onUpdateDescription={updateDescription}
          />
        );
      },
    },
  ];

  const _insertBeforeItem = (item?: TaskCheckListItem) => {
    if (!item || !_draggedItem) return;

    const insertIndex = items.indexOf(item);
    const newItems = items.filter((itm) => {
      return itm.id !== _draggedItem.id;
    });

    newItems.splice(insertIndex, 0, _draggedItem);

    setItems(newItems);
    const _newCheckList = new TaskCheckList();
    _newCheckList.items = newItems;

    props.updateCheckList(_newCheckList);
  };

  const dragAndDropEventHandler: IDragDropEvents = {
    canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
      return true;
    },
    canDrag: (item?: TaskCheckListItem) => {
      if (props.allowEdit) return true;

      return false;
    },
    onDragEnter: (item?: TaskCheckListItem, event?: DragEvent) => {
      if (!_draggedItem || !item) return '';
      const sourceIdx = items.indexOf(_draggedItem);
      const targetIdx = items.indexOf(item);

      if (sourceIdx > targetIdx) {
        // return string is the css classes that will be added to the entering element.
        return mergeStyles({
          border: '1px solid blue',
          borderTop: '1px solid blue',
          borderColor: 'blue',
        });
      } else if (sourceIdx < targetIdx) {
        // return string is the css classes that will be added to the entering element.
        return mergeStyles({
          border: '1px solid blue',
          borderBottom: '1px solid blue',
          borderColor: 'blue',
        });
      } else {
        return '';
      }
    },
    onDragLeave: (item?: TaskCheckListItem, event?: DragEvent) => {
      return;
    },
    onDrop: (item?: TaskCheckListItem, event?: DragEvent) => {
      if (_draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (
      item?: TaskCheckListItem,
      itemIndex?: number,
      selectedItems?: TaskCheckListItem[],
      event?: MouseEvent,
    ) => {
      _setDraggedItem(item);
    },
    onDragEnd: (item?: TaskCheckListItem, event?: DragEvent) => {
      _setDraggedItem(undefined);
    },
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      {items.length === 0 && (
        <Stack grow horizontalAlign="center" verticalAlign="center" tokens={globalStackTokensGapLarge}>
          <Stack.Item>
            <Text>{t('task:CheckList.NoData')}</Text>
          </Stack.Item>
          {hasUserFeature(appContext, FeatureTypes.KPIsAndForms) && (
            <Stack.Item styles={{ root: { width: '50%', minwidth: 200 } }}>
              <Text variant="small">{t('task:CheckList.UpgradeFormInfo')}</Text>
              <LearnMore learnMoreLink={globalKB_howToWorkWithFormsChecklist} />
            </Stack.Item>
          )}
        </Stack>
      )}
      {items.length > 0 && (
        <Stack.Item grow styles={getGlobalStackItemStylesPaddingSceneScrollMinHeight(80)}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsList
              compact
              items={items}
              columns={columns}
              selectionMode={SelectionMode.none}
              isHeaderVisible={false}
              dragDropEvents={props.allowEdit ? dragAndDropEventHandler : undefined}
            />
          </ScrollablePane>
        </Stack.Item>
      )}
      {props.allowEdit && (
        <Stack.Item style={{ paddingLeft: '5px' }}>
          <AddNewCheckListItem
            newCheckListDescription={newCheckListDescription}
            setNewCheckListDescription={setNewCheckListDescription}
            onAddNewCheckListItem={onAddNewCheckListItem}
            allowEdit={props.allowEdit ? true : false}
            singleLineEdit={props.singleLineEdit || appContext.isMobileView}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default CheckList;
