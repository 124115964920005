import {
  getWebParts,
  graphGetPagesForSite,
  graphCreateSitePage,
  graphPublishSitePage,
} from 'services/Graph/graphServicePage';
import { IAppContext } from './../../App/AppContext';
import { Client } from '@microsoft/microsoft-graph-client';
import { DriveItem, ColumnDefinition } from 'microsoft-graph';
import { StoreMgmtStates } from 'models/adminTenant';
import { EntityTypes } from 'models/entity';
import Package, { PackageTenantStates, PackageContent } from 'models/package';
import { ResourceListType } from 'models/resourceList';
import { apiUpdateLink } from 'services/Api/linkService';
import { apiUpdateList } from 'services/Api/listService';
import { apiDownloadPackageContentBlob } from 'services/Api/packageService';
import { graphSharepointManageRequest, apiRequest } from 'services/Auth/authConfig';
import {
  graphGetDrivesForSite,
  graphCreateDrive,
  graphGetDriveRoot,
  graphUploadFileToFolder,
  graphGetChildrenForDriveItem,
  graphCreateNewFolderInDrive,
} from 'services/Graph/graphServiceDrive';
import {
  graphCreateList,
  graphCreateListItem,
  graphGetAllListsForSite,
  graphGetListColumns,
  graphGetListItems,
} from 'services/Graph/graphServiceList';

import { ISite, ISitePage, IListItem, IList, getLinkNameFromListItem } from 'services/Graph/SharepointInterfaces';
import AppError from 'utils/appError';
import { decodeURIFromHtml, getRelativeURL } from 'utils/url';
import { DBProcesLoggerLogLevel, LogDTO } from 'models/dto/logDTO';
import { getLinkFromImport, getListFromImport, replaceLinksToEntity } from 'components/Links/Helpers/PageLinkHelper';

interface ITransformPageURL {
  source: string;
  target: string;
  isRel: boolean;
}

//
// Content upload to SharePoint
//
export const uploadContent = async (
  pack: Package,
  contents: PackageContent[],
  site: ISite | undefined,
  appContext: IAppContext,
): Promise<LogDTO | undefined> => {
  const log = new LogDTO('SharePoint package upload: ' + pack.name + ' ' + pack.code);
  log.dontTrim = true;

  try {
    //upload all the content to SharePoint
    //the pack variable contains the PackageImport records
    //the selectedPackage contains the PackageContent records
    log.add('Start');

    if (pack.tenants.length === 1 && pack.tenants[0].state === PackageTenantStates.ContentUpload) {
      const newFolders: Record<string, DriveItem> = {};
      const newCustomLists: Record<number, IList> = {};

      if (contents.length > 0) {
        if (!site || !site.id || !site.webUrl) {
          throw new AppError(
            'Package installation proces indicates that SharePoint content must be upload but there is no selected site',
          );
        }

        //set this tenant Id on the list. Apply the tenant of the selected site when it's different then the current logged in tenant
        //this way its possible to upload content to another SharePoint organization. This is only allowed in management and creator store mode
        let altSiteTenant: string | undefined = undefined;
        if (
          appContext.user.login.storeMgmtState === StoreMgmtStates.Manager ||
          appContext.user.login.storeMgmtState === StoreMgmtStates.Creator
        ) {
          altSiteTenant = appContext.user.tenant.azureTenantId === site.tenantId ? undefined : site.tenantId;
          log.add('altSiteTenant: ' + (altSiteTenant ?? ''));
        }

        //get authorization
        const graphInterface = await appContext.getGraphInterface(graphSharepointManageRequest.scopes, altSiteTenant);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);

        //create the document libraries and folder structures
        log.add('Processing document libraries and folder structures: graphGetDrivesForSite');
        const existingDrives = await graphGetDrivesForSite(graphInterface.client, site.id);

        for (let idx = 0; idx < contents.length; idx++) {
          const content = contents[idx];
          if (content.sourceEntityType === EntityTypes.NotSet && !content.contentParentId) {
            //these are the document library root elements
            //check if the document library exists, otherwise create it
            //next, get the child elements recursively and create all folders (they don't exist because of pre-checks)
            log.add('Process content id: ' + content.contentId.toString());
            if (!content.data1 || !content.data2)
              throw new AppError('Package is corrupt. Data1 or Data2 of document library is empty');
            let existingDrive = existingDrives.find((d) => d.name?.toLowerCase() === content.name.toLowerCase());
            if (!existingDrive) {
              log.add('graphCreateDrive: ' + content.name);
              existingDrive = await graphCreateDrive(graphInterface.client, site.id, content.name);
            }
            if (!existingDrive || !existingDrive.id) throw new AppError('Could not create a new SharePoint drive');
            log.add('graphGetDriveRoot: ' + (existingDrive.id ?? ''));
            const rootItem = await graphGetDriveRoot(graphInterface.client, existingDrive.id);
            if (!rootItem) throw new AppError('Could not get root of drive: ' + existingDrive.id);
            log.add('setNewFolders: ' + content.data1 + content.data2 + ' = ' + (rootItem.name ?? ''));
            newFolders[content.data1 + content.data2] = rootItem; //data1 contains the drive-Id, data2 contains the driveItem-Id of the root item
            const childs = contents.filter(
              (c) => c.sourceEntityType === EntityTypes.NotSet && c.contentParentId === content.contentId,
            );
            log.add('createFolderStructure: ' + childs.length.toString());
            await createFolderStructure(graphInterface.client, rootItem, childs, contents, newFolders, log);
          }
        }

        //create the custom lists
        log.add('Create the custom lists');
        const existingLists = await graphGetAllListsForSite(graphInterface.client, site.id);

        for (let idx = 0; idx < contents.length; idx++) {
          const content = contents[idx];
          if (content.sourceEntityType === EntityTypes.List) {
            log.add('Process content id: ' + content.contentId.toString());
            const list = pack.sharePointLists.find((l) => l.listId.toString() === content.sourceEntityId);
            if (list?.listType === ResourceListType.CustomList) {
              if (!content.data1 || !content.data2)
                throw new AppError('Package corrupt. Data1 or Data2 is empty for custom list');
              const importedCustomList = getListFromImport(pack, Number.parseInt(content.sourceEntityId), appContext);
              //check if list already exists
              const newList: IList = JSON.parse(content.data1);
              let existingList = existingLists.find((l) => l.name?.toLowerCase() === newList.name?.toLowerCase());
              if (!existingList) {
                //create the list
                log.add('graphCreateList: ' + content.data1);
                log.add('column def: ' + content.data2);
                const newColumns: ColumnDefinition[] = JSON.parse(content.data2);
                existingList = await graphCreateList(graphInterface.client, site.id, newList, newColumns, false);
                existingList.columns = newColumns;
                log.add('newCustomLists: ' + content.contentId + ' = ' + (existingList.name ?? ''));
              } else {
                if (!existingList.id) throw new AppError('Package is corrupt. Existing custom list has no id');
                const customListColumns = await graphGetListColumns(graphInterface.client, site.id, existingList.id);
                existingList.columns = customListColumns;
              }
              //add list to newCustomLists
              newCustomLists[content.contentId] = existingList;
              //update the resourcelist
              if (!existingList.webUrl) throw new AppError('Package is corrupt. New custom list has no web url');
              importedCustomList.spSiteId = site.id;
              importedCustomList.spListId = existingList.id;
              importedCustomList.webURL = existingList.webUrl;
              importedCustomList.altTenantId = altSiteTenant;
              log.add('apiUpdateList: ' + importedCustomList.listId.toString());
              await apiUpdateList(accessToken, importedCustomList);
              list.spListId = importedCustomList.spListId; //set this to indicate success
            }
          }
        }

        //now update the ResourceLists
        log.add('Update all lists in SharePoint and update the ResourceLists');

        for (let idx = 0; idx < contents.length; idx++) {
          const content = contents[idx];
          if (content.sourceEntityType === EntityTypes.List) {
            log.add('Process content id: ' + content.contentId.toString());
            const list = pack.sharePointLists.find((l) => l.listId.toString() === content.sourceEntityId);
            if (!list)
              throw new AppError(
                'Package is corrupt. The list to create in SharePoint was not found in sharePointLists',
              );
            switch (list.listType) {
              case ResourceListType.SitePageLibrary:
                const importedPageList = getListFromImport(pack, Number.parseInt(content.sourceEntityId), appContext);
                importedPageList.spSiteId = site.id;
                importedPageList.webURL = site.webUrl;
                importedPageList.altTenantId = altSiteTenant;
                log.add('apiUpdateList: ' + importedPageList.listId.toString());
                await apiUpdateList(accessToken, importedPageList);
                list.spSiteId = importedPageList.spSiteId; //set this to indicate success
                break;
              case ResourceListType.DocumentLibrary:
                const importedLibrary = getListFromImport(pack, Number.parseInt(content.sourceEntityId), appContext);
                if (!content.data1 || !content.data2)
                  throw new AppError('Package is corrupt. Data1 or Data2 of document library list is empty');
                const newFolder = newFolders[content.data1 + content.data2];
                if (!newFolder)
                  throw new AppError('Package is corrupt. Could not find folder in newly created drives table');
                if (!newFolder.parentReference?.driveId || !newFolder.webUrl)
                  throw new AppError('Package is corrupt. New folder has no parent id or web url');
                importedLibrary.spDriveId = newFolder.parentReference.driveId;
                importedLibrary.spDriveItemId = newFolder.id;
                importedLibrary.webURL = newFolder.webUrl;
                importedLibrary.altTenantId = altSiteTenant;
                log.add('apiUpdateList: ' + importedLibrary.listId.toString());
                await apiUpdateList(accessToken, importedLibrary);
                list.spDriveId = importedLibrary.spDriveId; //set this to indicate success
                break;
              case ResourceListType.CustomList:
              //already done
            }
          }
        }

        //get the full current page list of the site
        //we need this to check whether there is an existing page, if so we do an update instead of create
        const existingPages = await graphGetPagesForSite(graphInterface.client, site.id, false);

        //now create all items (list-items, files and pages) and update the ResourceLinks
        log.add('Create all items and update the ResourceLinks');
        const transformPageURL = createPageTransforms(pack, site, log);

        for (let idx = 0; idx < contents.length; idx++) {
          const content = contents[idx];

          if (content.sourceEntityType === EntityTypes.Link) {
            log.add('Process content id: ' + content.contentId.toString());
            const link = pack.sharePointLinks.find((l) => l.linkId.toString() === content.sourceEntityId);
            if (!link) throw new AppError('Package is corrupt. Could not find link in sharePointLinks');
            const list = pack.sharePointLists.find((l) => l.listId === link?.listId);
            if (!list) throw new AppError('Package is corrupt. Could not find list in sharePointLists');

            switch (list.listType) {
              case ResourceListType.SitePageLibrary:
                const importedPageLink = await getLinkFromImport(
                  pack,
                  Number.parseInt(content.sourceEntityId),
                  accessToken,
                  appContext,
                );
                if (!content.data1) throw new AppError('Package corrupt. Data1 is empty for site page');
                let page: ISitePage = JSON.parse(content.data1);
                page = transformPageLinks(pack, page, transformPageURL, appContext, log);
                //check if page exists
                const existingPage = existingPages.find((p) => p.name.toLowerCase() === page.name.toLowerCase());
                let newPage: ISitePage;
                if (existingPage) {
                  //link to existing page
                  if (!existingPage.id) throw new AppError('Existing page has no id');
                  if (!existingPage.webUrl) throw new AppError('Existing page has no web url');
                  importedPageLink.pageId = existingPage.id;
                  importedPageLink.linkURL = existingPage.webUrl;
                } else {
                  //create page
                  log.add('graphCreateSitePage: ' + JSON.stringify(page));
                  newPage = await graphCreateSitePage(graphInterface.client, site.id, page);
                  if (!newPage.id) throw new AppError('Package is corrupt. Newly created page has no id');
                  if (!newPage.webUrl) throw new AppError('Package is corrupt. Newly created page has no web url');
                  importedPageLink.pageId = newPage.id;
                  importedPageLink.linkURL = newPage.webUrl;
                }
                //publish page
                log.add('graphPublishSitePage: ' + (importedPageLink.pageId ?? ''));
                try {
                  await graphPublishSitePage(graphInterface.client, site.id, importedPageLink.pageId);
                } catch (err) {
                  log.add('Error publishing page: ' + JSON.stringify(err));
                }
                log.add('apiUpdateLink: ' + importedPageLink.linkId.toString());
                await apiUpdateLink(importedPageLink, true, accessToken, appContext.globalDataCache);
                link.pageId = importedPageLink.pageId; //set this to indicate success
                break;
              case ResourceListType.DocumentLibrary:
                const importedFileLink = await getLinkFromImport(
                  pack,
                  Number.parseInt(content.sourceEntityId),
                  accessToken,
                  appContext,
                );
                //get the file data and the parent drive item to upload to
                log.add('apiDownloadPackageContentBlob: ' + content.contentId.toString());
                content.blob = await apiDownloadPackageContentBlob(pack.packageId, content.contentId, accessToken);
                const parentFileContent = contents.find((c) => c.contentId === content.contentParentId);
                if (!parentFileContent) throw new AppError('Package corrupt. Parent folder not found');
                if (!parentFileContent.data1 || !parentFileContent.data2)
                  throw new AppError('Package corrupt. Data1 or Data2 is empty for parent folder');
                const parentDriveItem = newFolders[parentFileContent.data1 + parentFileContent.data2];
                if (!parentDriveItem.parentReference?.driveId || !parentDriveItem.id)
                  throw new AppError(
                    'Package corrupt. id or parent id is empty for newly created folder in newFolders',
                  );
                //try to upload the file
                const file = new File([content.blob], content.name);
                log.add('graphUploadFileToFolder: ' + content.name);
                let newDriveItem: DriveItem | undefined;
                try {
                  newDriveItem = await graphUploadFileToFolder(
                    graphInterface.client,
                    file,
                    parentDriveItem.parentReference?.driveId,
                    parentDriveItem.id,
                    content.name,
                    undefined,
                    undefined,
                    'fail',
                  );
                } catch (err) {
                  const appError = AppError.fromGraphError(err);
                  log.add('graphUploadFileToFolder failed: ' + JSON.stringify(appError));
                  if (appError.code === 'nameAlreadyExists') {
                    //get existing file
                    const existingFiles = await graphGetChildrenForDriveItem(
                      graphInterface.client,
                      parentDriveItem.parentReference.driveId,
                      parentDriveItem.id,
                    );
                    newDriveItem = existingFiles.find((f) => f.name?.toLowerCase() === content.name.toLowerCase());
                    if (!newDriveItem)
                      throw new AppError('File upload failed but existing file not found: ' + content.name);
                  } else {
                    throw appError;
                  }
                }
                //update the link
                if (!newDriveItem?.id || !newDriveItem.webUrl)
                  throw new AppError('Id or web url is empty for uploaded file');
                importedFileLink.driveItemId = newDriveItem.id;
                importedFileLink.linkURL = newDriveItem.webUrl;
                log.add('apiUpdateLink: ' + importedFileLink.linkId.toString());
                await apiUpdateLink(importedFileLink, true, accessToken, appContext.globalDataCache);
                link.driveItemId = importedFileLink.driveItemId; //set this to indicate success
                break;
              case ResourceListType.CustomList:
                const importedCustomListItemLink = await getLinkFromImport(
                  pack,
                  Number.parseInt(content.sourceEntityId),
                  accessToken,
                  appContext,
                );
                if (!content.data1) throw new AppError('Package corrupt. Data1 is empty for custom list item');
                const listitem: IListItem = JSON.parse(content.data1);
                const parentListContent = contents.find((c) => c.contentId === content.contentParentId);
                if (!parentListContent) throw new AppError('Package corrupt. Parent content list not found');
                const list = newCustomLists[parentListContent.contentId];
                if (!list) throw new AppError('Package corrupt. Could not find list in newCustomLists');
                if (!list.id || !list.columns)
                  throw new AppError('Package corrupt. Id or columns of custom list are empty');
                //Check if list item exists
                const existingItems = await graphGetListItems(graphInterface.client, site.id, list.id);
                let existingItem = existingItems.find(
                  (i) => getLinkNameFromListItem(i)?.toLowerCase() === getLinkNameFromListItem(listitem)?.toLowerCase(),
                );
                if (!existingItem) {
                  log.add('graphCreateListItem: ' + (listitem.id ?? '') + ' name: ' + (listitem.name ?? ''));
                  try {
                    existingItem = await graphCreateListItem(
                      graphInterface.client,
                      site.id,
                      list.id,
                      listitem,
                      list.columns,
                    );
                  } catch (err) {
                    log.add('graphCreateListItem failed: ' + JSON.stringify(err));
                    log.level = DBProcesLoggerLogLevel.Error;
                    //set to error but don't specify log.error. this will force the log to upload but user does not get an error
                    //uploading a list item is not crucial in the process and no reason to abort.
                  }
                }
                //update the link
                if (existingItem) {
                  if (!existingItem.webUrl) throw new AppError('Newly created list item has no web url');
                  importedCustomListItemLink.listItemId = existingItem.id;
                  importedCustomListItemLink.linkURL = existingItem.webUrl;
                  log.add('apiUpdateLink: ' + importedCustomListItemLink.linkId.toString());
                  await apiUpdateLink(importedCustomListItemLink, true, accessToken, appContext.globalDataCache);
                  link.listItemId = importedCustomListItemLink.listItemId; //set this to indicate success
                }
                break;
            }
          }
        }
      }
      log.add('Finish');

      return log;
    } else {
      throw new AppError('Package installation proces returned an invalid package state');
    }
  } catch (err) {
    const error = err as AppError;
    log.add(error.code);
    log.add(error.message);
    log.add(error.debug);
    log.add(error.stack);
    log.appError = error;
    log.level = DBProcesLoggerLogLevel.Error;

    return log;
  }
};

const createPageTransforms = (pack: Package, site: ISite, log: LogDTO): ITransformPageURL[] => {
  // get all unique SharePoint sites from links URL's
  // https://{org}.sharepoint.com/sites/{site} or /sites/{site} for relative URL's
  // 1. for each source, calc the target
  // 2. de-dup sources
  // 3. find targets for sources
  log.add('Create Page Transforms for site');
  log.add(JSON.stringify(site));

  const transformPageURL: ITransformPageURL[] = [];
  transformPageURL.splice(0, transformPageURL.length);
  if (!site.webUrl) throw new AppError('site.webUrl is undefined');

  const spStr = '.sharepoint.com';
  const spRelSiteStr = '/sites/';
  const spSiteStr = spStr + spRelSiteStr;
  const httpsStr = 'https://';

  let uniqueSources: string[] = [];
  const sourceTargets: Record<string, string> = {};
  const sourceRel: Record<string, boolean> = {};
  const links = pack.sharePointLinks;

  let webUrl = site.webUrl;
  //remove trailing slash, if any (Graph current does not return a trailing /)
  if (webUrl.length > 1 && webUrl?.endsWith('/')) {
    webUrl = webUrl.substring(0, webUrl.length - 2);
  }

  for (let idx = 0; idx < links.length; idx++) {
    log.add('processing link: ' + links[idx].linkId.toString());

    let url = links[idx].linkURL;
    url = decodeURIFromHtml(url);
    const isRel = url.startsWith(spRelSiteStr);
    if (!url.toLowerCase().startsWith(httpsStr) && !isRel) continue;

    let siteIdxStart = 0;
    if (!isRel) {
      siteIdxStart = url.indexOf(spSiteStr);
    }

    let siteIdxEnd = 0;
    if (siteIdxStart >= 0) {
      //this is a SharePoint site link
      //from here, find the next slash. package content must be stored in a toplevel site
      siteIdxStart += isRel ? spRelSiteStr.length : spSiteStr.length;
      siteIdxEnd = url.indexOf('/', siteIdxStart);
      if (siteIdxEnd >= 0) {
        const source = url.substring(0, siteIdxEnd) + '/';
        const target = webUrl + '/';

        //add to indexes
        uniqueSources.push(source);
        sourceTargets[source] = target;
        sourceRel[source] = isRel;

        log.add('source: ' + source);
        log.add('target: ' + target);
      }
    }
  }

  uniqueSources = [...new Set(uniqueSources)];

  for (let idx = 0; idx < uniqueSources.length; idx++) {
    const tpu: ITransformPageURL = {
      source: encodeURI(uniqueSources[idx]),
      target: encodeURI(sourceTargets[uniqueSources[idx]]),
      isRel: sourceRel[uniqueSources[idx]],
    };
    transformPageURL.push(tpu);
  }

  return transformPageURL;
};

const transformPageLinks = (
  pack: Package,
  page: ISitePage,
  transformPageURL: ITransformPageURL[],
  appContext: IAppContext,
  log: LogDTO,
): ISitePage => {
  const validParts = getWebParts(page);

  //log and return when no webparts are found
  if (validParts.length === 0) {
    log.add('Page has no webparts found for ' + page.name);

    return page;
  }

  log.add('Transforming ' + validParts.length.toString() + ' page links for ' + page.name);

  for (let idx = 0; idx < validParts.length; idx++) {
    const webPart = validParts[idx];

    //get the html
    let html = webPart.innerHtml;

    log.add('Old html');
    log.add(html);

    //1. Transform tenant Id's
    let tidReplacement: string = '';
    if (appContext.user.login.isOrgUnit === true) {
      tidReplacement = `tid=${appContext.user.tenant.azureTenantId}&ouid=${appContext.user.login.tenantId}`;
    } else {
      tidReplacement = `tid=${appContext.user.tenant.azureTenantId}`;
      //remove OU's
      html = html.replaceAll(new RegExp('((&amp;)|(&))(ouid=.{36})', 'gi'), '');
    }
    html = html.replaceAll(new RegExp('(tid=.{36})', 'gi'), tidReplacement);
    log.add("Tenant id's replaced");

    //2a. Transform SharePoint absolute URL's
    const transformPageURLAbs = transformPageURL.filter((t) => t.isRel === false);
    for (let idx = 0; idx < transformPageURLAbs.length; idx++) {
      //get the URL's from the created index
      const source = transformPageURLAbs[idx].source;
      const target = transformPageURLAbs[idx].target;
      html = html.replaceAll(source, target);
      //create also a relative URL from an absolute URL and replace it as well
      const sourceRel = getRelativeURL(source);
      const targetRel = getRelativeURL(target);
      html = html.replaceAll(sourceRel, targetRel);
    }

    //2b. Transform SharePoint relative URL's. This must be done after replacing the absolute URL's because the relative URL's are part of the absolute URL's
    const transformPageURLRel = transformPageURL.filter((t) => t.isRel === true);
    for (let idx = 0; idx < transformPageURLRel.length; idx++) {
      //get the URL's from the created index
      const source = transformPageURLRel[idx].source;
      const target = transformPageURLRel[idx].target;
      html = html.replaceAll(source, target);
    }

    log.add("SharePoint url's replaced");

    //3. Transform Content Id's
    html = replaceLinksToEntity(pack.imports, html, EntityTypes.Requirement, 'theme');
    html = replaceLinksToEntity(pack.imports, html, EntityTypes.Risk, 'risk');
    html = replaceLinksToEntity(pack.imports, html, EntityTypes.Control, 'control');
    html = replaceLinksToEntity(pack.imports, html, EntityTypes.Process, 'process');
    html = replaceLinksToEntity(pack.imports, html, EntityTypes.Objective, 'objective');
    html = replaceLinksToEntity(pack.imports, html, EntityTypes.Task, 'tasks');
    html = replaceLinksToEntity(pack.imports, html, EntityTypes.Asset, 'asset');

    log.add("Entity url's replaced");

    //update the html
    webPart.innerHtml = html;

    log.add('New html');
    log.add(html);
  }

  return page;
};

const createFolderStructure = async (
  client: Client,
  item: DriveItem,
  childs: PackageContent[],
  contents: PackageContent[],
  newFolders: Record<string, DriveItem>,
  log: LogDTO,
) => {
  if (!item.id || !item.parentReference?.driveId)
    throw new AppError('Package corrupt: item has not parent drive id or id');

  for (let idx = 0; idx < childs.length; idx++) {
    const child = childs[idx];
    log.add('graphGetChildrenForDriveItem: ' + child.name);
    const existingFolders = await graphGetChildrenForDriveItem(client, item.parentReference.driveId, item.id);
    let newFolder = existingFolders.find((f) => f.name?.toLowerCase() === child.name.toLowerCase());
    if (!newFolder) {
      log.add('graphCreateNewFolderInDrive: ' + child.name);
      newFolder = await graphCreateNewFolderInDrive(client, item.parentReference?.driveId, item.id, child.name);
    }
    if (!child.data1) throw new AppError('Package is corrupt. Data1 of document library child is empty');
    log.add('set newFolders: ' + child.data1 + child.data2 + ' = ' + (newFolder.name ?? ''));
    newFolders[child.data1 + child.data2] = newFolder;
    const subChilds = contents.filter(
      (c) => c.sourceEntityType === EntityTypes.NotSet && c.contentParentId === child.contentId,
    );
    log.add('createFolderStructure: ' + subChilds.length.toString());
    await createFolderStructure(client, newFolder, subChilds, contents, newFolders, log);
  }
};
