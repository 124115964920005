import { HoverCard, HoverCardType, IPlainCardProps, Stack } from '@fluentui/react';
import KanbanCardTaskGenericWithState from 'components/Kanban/Cards/KanbanCardTaskGenericWithState';
import Task from 'models/tasks/task';

interface ITaskRelationLinkWithHoverCardProps {
  children: JSX.Element;
  task: Task;
  onTaskClick?: (task: Task) => void;
}

export const TaskRelationLinkWithHoverCard = (props: ITaskRelationLinkWithHoverCardProps) => {

  const onRenderCompactCardSingle = (item: Task): JSX.Element => {
    return (
      <Stack styles={{ root: { width: 210, borderRadius: '3px', border: '1px solid lightgrey', margin: 3 } }}>
        <KanbanCardTaskGenericWithState
          key={item.taskId.toString()}
          task={item}
          onClick={() => props.onTaskClick ? props.onTaskClick(item) : undefined}
        />
      </Stack>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderCompactCardSingle,
    renderData: props.task,
  };

  //
  // Main render
  //
  return (
    <HoverCard
      cardOpenDelay={300}
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
      instantOpenOnClick={false}
    >
      {props.children}
    </HoverCard>
  );
};
