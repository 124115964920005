import {
  Callout,
  Checkbox,
  DirectionalHint,
  IconButton,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Text,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import Accordion from 'components/Accordion/Accordion';
import { globalStackStylesHeight100PaddingSmall, globalStackTokensGapSmall } from 'globalStyles';
import { GlobalFilterGroupKeys } from './GlobalFilterGroupKeys';
import Norm from 'models/norm';
import { useHistory } from 'react-router-dom';
import { LearnMore } from 'components/Notification/Info';
import { globalKB_howToWorkWithGlobalSearchAndFilter } from 'globalConstants';

interface IGlobalFilterProps {}

export const GlobalFilter = (props: IGlobalFilterProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const history = useHistory();
  const [globalFilter, setGlobalFilter] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [norms, setNorms] = useState<Norm[]>([]);

  //
  // Effects
  //
  useEffect(() => {
    if (
      appContext.isAuthenticated === true &&
      appContext.user.login.tenantLicensed === true &&
      appContext.user.login.userLicensed == true
    ) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.isAuthenticated, appContext.user.id]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      loadFilterFromStorage();
      const norms = await appContext.globalDataCache.norms.getItems();
      setNorms(norms);
    } catch (error) {
      appContext.setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveFilterToStorage = (filter: string[]) => {
    setLocalStorageData(appContext, LocalStorageKeys.GlobalFilter, filter.join(','));
  };

  const loadFilterFromStorage = () => {
    const filterString = getLocalStorageData(appContext, LocalStorageKeys.GlobalFilter);
    if (filterString) {
      const filter = filterString.split(',');
      setGlobalFilter(filter);
      appContext.setGlobalFilter(filter);
    }
  };

  const toggleCallout = (open: boolean) => {
    setIsOpen(open);
  };

  const handleFilterClear = () => {
    updateSelectedFilter([]);
    toggleCallout(false);
  };

  const handleSelectChange = (key: string) => {
    let items: string[] = [...globalFilter];

    if (!globalFilter.includes(key)) {
      items.push(key);
    } else {
      items = items.filter((i: string) => i !== key);
    }

    updateSelectedFilter(items);
  };

  const updateSelectedFilter = (filter: string[]) => {
    setGlobalFilter(filter);
    saveFilterToStorage(filter);
    appContext.setGlobalFilter(filter);
    reloadPage();
  };

  const reloadPage = () => {
    history.push('/reload');
    history.goBack();
  };

  const getSelectedFilterCount = (type: GlobalFilterGroupKeys) => {
    if (globalFilter.length > 0) {
      return globalFilter.filter((f) => f.startsWith(type))?.length;
    } else {
      return 0;
    }
  };

  //
  // Main render
  //
  if (!appContext.isAuthenticated || !appContext.user.login.userLicensed) {
    return null;
  }
  
  if (isLoading || norms.length < 2) {
    return null;
  }

  return (
    <Stack horizontal verticalAlign="center">
      <IconButton
        iconProps={{ iconName: globalFilter.length > 0 ? 'FilterSettings' : 'Filter' }}
        className="calloutGlobalFilterButton"
        onClick={() => toggleCallout(!isOpen)}
        styles={{ root: { color: globalFilter.length > 0 ? 'green' : undefined } }}
      />
      <Callout
        alignTargetEdge={true}
        gapSpace={-3}
        target=".calloutGlobalFilterButton"
        directionalHint={DirectionalHint.bottomLeftEdge}
        hidden={!isOpen}
        onDismiss={() => {
          toggleCallout(false);
        }}
        styles={{ root: { height: 300 } }}
      >
        <Stack styles={globalStackStylesHeight100PaddingSmall} tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Stack horizontal horizontalAlign={'space-between'} styles={globalStackStylesHeight100PaddingSmall}>
              <Text variant={'mediumPlus'}>{t('translation:GlobalFilter.Header')}</Text>
              <Link onClick={handleFilterClear} underline>
                {t('translation:GlobalFilter.Clear')}
              </Link>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { height: 400, width: 300, position: 'relative' } }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <Accordion
                title={t('translation:GlobalFilter.Standards', {
                  count: getSelectedFilterCount(GlobalFilterGroupKeys.standard),
                })}
                defaultActive={true}
              >
                <Stack tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100PaddingSmall}>
                  {norms.map((norm) => (
                    <Checkbox
                      key={norm.normId}
                      label={norm.name}
                      checked={globalFilter.includes(GlobalFilterGroupKeys.standard + norm.normId.toString())}
                      onChange={() => handleSelectChange(GlobalFilterGroupKeys.standard + norm.normId.toString())}
                    />
                  ))}
                </Stack>
              </Accordion>
            </ScrollablePane>
          </Stack.Item>
          <Stack.Item>
            <LearnMore learnMoreLink={globalKB_howToWorkWithGlobalSearchAndFilter} />
          </Stack.Item>
        </Stack>
      </Callout>
    </Stack>
  );
};
