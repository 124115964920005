import { ActionButton, IContextualMenuProps, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import TaskTypeFormElement from 'models/tasks/taskTypeFormElement';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall, newIcon } from 'globalStyles';
import KPI from 'models/kpi/kpi';
import KPIPicker from 'components/Pickers/KPIPicker';
import KPIData from 'models/kpi/kpiData';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import SingleFormElement from './SingleFormElement';
import Task from 'models/tasks/task';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';

interface ITaskTypeFormRendererProps {
  task: Task;
  links: ResourceLink[];
  formElements: TaskTypeFormElement[];
  isLoading: boolean;
  allowEdit: boolean;
  readonly: boolean;
  compact: boolean;
  onAddKPI?: (kpi: KPI) => void;
  onAddAttachment?: () => void;
  onAddContext?: () => void;
  onRemove?: (field: TaskTypeFormElement) => void;
  onEdit?: (field: TaskTypeFormElement) => void;
  onMoveUp?: (field: TaskTypeFormElement) => void;
  onMoveDown?: (field: TaskTypeFormElement) => void;
  onUpdateValue: (kpi: KPI, data: KPIData) => void;
  onUpdateKPI: (kpi: KPI) => void;
  onSetKPI: (kpi: KPI) => void;
  onUpdateTaskForForm: (task: Task) => void;
  addLinks: (links: ResourceLink[]) => void;
  setLinks: (links: ResourceLink[]) => void;
  removeLink: (links: ResourceLink) => void;
  onUpdateField?: (field: TaskTypeFormElement) => void;
  onAddNewKPI?: () => void;
}

const TaskTypeFormRenderer = (props: ITaskTypeFormRendererProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'forms']);
  const [showKPIPicker, setShowKPIPicker] = useState<boolean>(false);

  const getAddNewMenuProps = (): IContextualMenuProps => {
    //system type forms cannot contain context or attachment fields
    //because these forms are not used in the context of a task
    let isSystemType = false;
    if (appContext.globalDataCache.taskTypes.get(props.task.taskTypeId).systemTaskType) {
      isSystemType = true;
    }

    const addNewMenuProps: IContextualMenuProps = {
      items: [
        {
          className: 'redlab-usetiful-form-add-kpi',
          key: 'newKPI',
          text: t('forms:Dialogs.EditForm.AddNew.NewKPI'),
          title: t('forms:Dialogs.EditForm.AddNew.NewKPIInfo'),
          onClick: () => {
            setShowKPIPicker(true);
          },
        },
        {
          className: 'redlab-usetiful-form-add-attachment',
          key: 'NewAttachment',
          disabled: isSystemType,
          text: t('forms:Dialogs.EditForm.AddNew.NewAttachment'),
          title: isSystemType
            ? t('forms:Dialogs.EditForm.AddNew.NewAttachmentInfoDisabled')
            : t('forms:Dialogs.EditForm.AddNew.NewAttachmentInfo'),
          onClick: () => {
            onAddAttachmentField();
          },
        },
        {
          className: 'redlab-usetiful-form-add-context',
          key: 'newContext',
          disabled: isSystemType,
          text: t('forms:Dialogs.EditForm.AddNew.NewContext'),
          title: isSystemType
            ? t('forms:Dialogs.EditForm.AddNew.NewContextInfoDisabled')
            : t('forms:Dialogs.EditForm.AddNew.NewContextInfo'),
          onClick: () => {
            onAddContextField();
          },
        },
      ],
      directionalHintFixed: true,
      beakWidth: 0,
    };

    return addNewMenuProps;
  };

  const onAddKPI = (kpi: KPI) => {
    if (props.onAddKPI) {
      props.onAddKPI(kpi);
    }
  };

  const onAddAttachmentField = () => {
    if (props.onAddAttachment) {
      props.onAddAttachment();
    }
  };

  const onAddContextField = () => {
    if (props.onAddContext) {
      props.onAddContext();
    }
  };

  //
  // Main render
  //
  if (props.isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Stack.Item grow>
          <Spinner size={SpinnerSize.large} />
        </Stack.Item>
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <ScrollableStackItem minHeight={100}>
        <Stack verticalFill>
          {props.formElements.map((elm) => {
            return (
              <Stack.Item
                key={elm.taskTypeId.toString() + '-' + elm.fieldType.toString() + '-' + (elm.kpiId?.toString() ?? '')}
              >
                <SingleFormElement
                  field={elm}
                  task={props.task}
                  links={props.links}
                  compact={props.compact}
                  readonly={props.readonly}
                  onUpdateValue={props.onUpdateValue}
                  onUpdateKPI={props.onUpdateKPI}
                  onSetKPI={props.onSetKPI}
                  onUpdateTaskForForm={props.onUpdateTaskForForm}
                  addLinks={props.addLinks}
                  setLinks={props.setLinks}
                  removeLink={props.removeLink}
                  onUpdateField={props.onUpdateField}
                  allowEdit={props.allowEdit}
                  onEdit={props.onEdit}
                  onRemove={props.onRemove}
                  onMoveUp={props.onMoveUp}
                  onMoveDown={props.onMoveDown}
                />
              </Stack.Item>
            );
          })}
          {props.formElements.length === 0 && (
            <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { paddingTop: 20 } }}>
              <Text>{t('forms:Dialogs.EditForm.Empty')}</Text>
            </Stack>
          )}
          {props.allowEdit && (
            <ActionButton
              className="redlab-usetiful-form-add"
              id={'kpi-picker'}
              iconProps={newIcon}
              text={t('forms:Dialogs.EditForm.AddNew.Main')}
              menuProps={getAddNewMenuProps()}
              styles={{ root: { paddingTop: 10 } }}
            />
          )}
        </Stack>
      </ScrollableStackItem>
      {showKPIPicker && (
        <KPIPicker
          isOpen={showKPIPicker}
          target={`#kpi-picker`}
          onDismiss={() => setShowKPIPicker(false)}
          onSelect={(kpi) => {
            setShowKPIPicker(false);
            onAddKPI(kpi);
          }}
          existingKPIs={props.formElements.filter((e) => e.kpiId).map((e) => e.kpiId as number)}
          onAddNewKPI={() => {
            setShowKPIPicker(false);
            if (props.onAddNewKPI) props.onAddNewKPI();
          }}
        />
      )}
    </Stack>
  );
};

export default TaskTypeFormRenderer;
