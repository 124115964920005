import {
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuProps,
  Link,
  Spinner,
  Stack,
  TooltipHost,
  getTheme,
  Text,
  IButtonStyles,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import {
  deleteIcon,
  eventIcon,
  formIcon,
  globalStackTokensGapSmall,
  moreIcon,
  newIcon,
  taskIcon,
  templateIcon,
} from 'globalStyles';
import Task, { TaskTypes } from 'models/tasks/task';
import React, { useMemo, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FeatureTypes, hasUserFeature, hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { sortOnString } from 'utils/sorting';
import { canUpdateTaskField, TaskFieldTypes } from './TaskAuthHelper';
import { toast } from 'react-toastify';

interface ITaskTopTasksBarProps {
  orgTask: Task;
  task: Task;
  templateTypes?: TaskTypes[];
  navigateExternal?: boolean;
  compact?: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canSave: boolean;
  onClickFinish?: () => void;
  onClickForm?: (taskTypeId: number) => void;
  onClickFollowUp?: (template: Task) => void;
  onClickTemplate?: (template: Task) => void;
  onClickOpenMaster?: (taskId: number | undefined) => void;
  onClickRemove?: (taskId: number) => void;
  onCreateNewTaskTemplate?: (taskId: number) => void;
  onSaveAsNewTask?: (taskId: number) => void;
  onClickOpenEvent?: (taskId: number | undefined) => void;
  onRunTemplateWorkflows?: (task: Task) => void;
  onConvertChecklist?: () => void;
}

const TaskTopTasksBar = (props: ITaskTopTasksBarProps) => {
  const { t } = useTranslation(['translation', 'task']);
  const appContext = useContext(AppContext);
  const [templates, setTemplates] = useState<Task[]>([]);
  const [templatesLoading, setTemplatesLoading] = useState<boolean>(false);
  const [isManager] = useState<boolean>(hasUserFeatureGenericManager(appContext));
  const theme = getTheme();
  const history = useHistory();

  const topTaskButtonStyles: IButtonStyles = {
    root: {
      color: appContext.useDarkMode ? theme.palette.themeLight : theme.palette.themeSecondary,
      height: props.compact || appContext.isMobileView ? 32 : 40,
      borderRadius: 6,
      borderColor: theme.palette.themeSecondary,
    },
    rootHovered: {
      background: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
    },
    rootExpanded: {
      background: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
    },
    rootPressed: {
      background: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
    },
  };

  const getTemplates: IContextualMenuProps = useMemo(() => {
    const output: IContextualMenuProps = {
      items: [],
      directionalHint: DirectionalHint.bottomLeftEdge,
      directionalHintFixed: true,
      calloutProps: {
        calloutMaxHeight: 400,
      },
    };

    let templatesToShow = templates;
    if (props.templateTypes && props.templateTypes.length > 0) {
      templatesToShow = templates.filter((t) => props.templateTypes?.some((tt) => tt === t.taskType));
    }

    if (templatesToShow && templatesToShow.length > 0) {
      output.items = templatesToShow.map((_itm: Task) => {
        return {
          key: `${_itm.taskId}`,
          text: `${_itm.name}`,
          iconProps: _itm.taskType === TaskTypes.Template ? taskIcon : eventIcon,
          onClick: () => {
            if (props.onClickTemplate) props.onClickTemplate(_itm);
          },
        };
      });
    } else {
      output.items.push({
        key: 'no-items',
        onRenderContent: () => {
          if (templatesLoading) {
            return (
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <Spinner />
                <Text>{t('task:TopTasks.TemplatesLoading')}</Text>
              </Stack>
            );
          } else {
            if (isManager && !props.navigateExternal) {
              return (
                <Link
                  styles={{ root: { margin: 5 } }}
                  onClick={() => {
                    navigateToManageTemplates();
                  }}
                >
                  {t('task:TopTasks.NoTemplateItems')}
                </Link>
              );
            } else {
              return <Text styles={{ root: { margin: 5 } }}>{t('task:TopTasks.NoTemplateItemsUser')}</Text>;
            }
          }
        },
      });
    }

    return output;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, templatesLoading, props.templateTypes]);

  const getFollowUpActions: IContextualMenuProps = useMemo(() => {
    const output: IContextualMenuProps = {
      items: [],
      directionalHint: DirectionalHint.bottomLeftEdge,
      directionalHintFixed: true,
      calloutProps: {
        calloutMaxHeight: 400,
      },
    };

    if (templates && templates.length > 0) {
      output.items = templates.map((_itm: Task) => {
        return {
          key: `${_itm.taskId}`,
          text: `${_itm.name}`,
          iconProps: _itm.taskType === TaskTypes.Template ? taskIcon : eventIcon,
          onClick: () => {
            if (!props.onClickFollowUp) return;

            if (!props.orgTask.isEqual(props.task)) {
              toast.warning(t('task:TopTasks.FollowUpSaveWarning'));

              return;
            }

            props.onClickFollowUp(_itm);
          },
        };
      });
    } else {
      output.items.push({
        key: 'no-items',
        onRenderContent: () => {
          if (templatesLoading) {
            return (
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <Spinner />
                <Text>{t('task:TopTasks.TemplatesLoading')}</Text>
              </Stack>
            );
          } else {
            if (isManager && !props.navigateExternal) {
              return (
                <Link
                  styles={{ root: { margin: 5 } }}
                  onClick={() => {
                    navigateToManageTemplates();
                  }}
                >
                  {t('task:TopTasks.NoTemplateItems')}
                </Link>
              );
            } else {
              return <Text styles={{ root: { margin: 5 } }}>{t('task:TopTasks.NoTemplateItemsUser')}</Text>;
            }
          }
        },
      });
    }

    return output;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, templatesLoading]);

  const loadTemplates = async () => {
    try {
      if (templatesLoading) return;
      setTemplatesLoading(true);

      let _templateTasks = await appContext.globalDataCache.templates.getItems();
      _templateTasks = _templateTasks.filter((t) => !t.isCompleted() && !t.systemTaskType);

      if (!hasUserFeature(appContext, FeatureTypes.Events)) {
        _templateTasks = _templateTasks.filter((t) => t.taskType !== TaskTypes.EventTemplate);
      }

      _templateTasks.sort((a, b) => sortOnString(a.name, b.name));
      setTemplates(_templateTasks);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setTemplatesLoading(false);
    }
  };

  const navigateToManageTemplates = () => {
    history.push('/tasks/templates');
  };

  const navigateToManageForms = () => {
    history.push('/organization/forms');
  };

  const getForms = (): IContextualMenuItem[] => {
    let types = appContext.globalDataCache.taskTypes.items;
    let forms: IContextualMenuItem[] = [];

    if (
      !(
        props.task.isSeries() &&
        (props.task.taskType === TaskTypes.Event || props.task.taskType === TaskTypes.EventTemplate)
      )
    ) {
      //system forms can only be selected for events series
      types = types.filter((t) => !t.systemTaskType);
    }

    if (types.length > 0) {
      forms = types.map((t) => {
        return {
          key: t.taskTypeId.toString(),
          iconProps: { iconName: 'EntitlementPolicy' },
          data: t.taskTypeId,
          canCheck: true,
          checked: props.task.taskTypeId === t.taskTypeId,
          text: t.name,
          onClick: () => {
            if (props.onClickForm) props.onClickForm(t.taskTypeId);
          },
        };
      });
    } else {
      forms.push({
        key: 'no-items',
        onRenderContent: () => {
          if (templatesLoading) {
            return <Spinner />;
          } else {
            if (isManager && !props.navigateExternal) {
              return (
                <Link
                  styles={{ root: { padding: 5 } }}
                  onClick={() => {
                    navigateToManageForms();
                  }}
                >
                  {t('task:TopTasks.NoFormItems')}
                </Link>
              );
            } else {
              return <Text styles={{ root: { padding: 5 } }}>{t('translation:General.Notifications.NoItems')}</Text>;
            }
          }
        },
      });
    }

    forms.push({
      key: 'div1',
      itemType: ContextualMenuItemType.Divider,
    });

    forms.push({
      key: 'remove',
      disabled: !props.task.taskTypeId,
      iconProps: deleteIcon,
      canCheck: false,
      text: t('task:TopTasks.Remove'),
      onClick: () => {
        if (props.onClickForm) props.onClickForm(0);
      },
    });

    return forms;
  };

  const getMoreMenuOptions = (): IContextualMenuProps => {
    const menu: IContextualMenuProps = { items: [] };

    if (props.onSaveAsNewTask) {
      menu.items.push({
        className: 'redlab-usetiful-task-topbar-more-saveasnew',
        key: 'EditAsNewTask',
        text: t('task:EditAsNewTask'),
        iconProps: newIcon,
        disabled:
          !isManager ||
          props.task.taskId === -1 ||
          props.task.isInstance() ||
          props.task.taskType === TaskTypes.Monitoring ||
          props.task.taskType === TaskTypes.Template ||
          props.task.taskType === TaskTypes.EventTemplate,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem,
        ) => {
          if (props.onSaveAsNewTask) props.onSaveAsNewTask(props.task.taskId);
        },
      });
    }

    if (props.onCreateNewTaskTemplate && props.task.taskId !== -1) {
      menu.items.push({
        className: 'redlab-usetiful-task-topbar-more-saveastemplate',
        key: 'saveAsTemplate',
        text: t('task:Template.Save'),
        iconProps: templateIcon,
        disabled: !isManager,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem,
        ) => {
          if (props.onCreateNewTaskTemplate) props.onCreateNewTaskTemplate(props.task.taskId);
        },
      });
    }

    if (props.onRunTemplateWorkflows && props.task.taskId !== -1) {
      menu.items.push({
        className: 'redlab-usetiful-task-topbar-more-runtemplateworkflows',
        key: 'runTemplateWorkflows',
        text: t('task:TopTasks.RunTemplateWorkflows'),
        iconProps: { iconName: 'Flow' },
        disabled: !isManager,
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem,
        ) => {
          if (props.onRunTemplateWorkflows) props.onRunTemplateWorkflows(props.task);
        },
      });
    }

    //Convert checklist to form
    if (
      !props.task.taskTypeId &&
      props.onConvertChecklist &&
      hasUserFeature(appContext, FeatureTypes.KPIsAndForms) &&
      isManager &&
      canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.ChecklistEdit, appContext)
    ) {
      menu.items.push({
        className: 'redlab-usetiful-task-topbar-more-convertchecklist',
        key: 'convertchecklist',
        text: t('task:TopTasks.ConvertChecklist'),
        iconProps: { iconName: 'ChangeEntitlements' },
        disabled: props.task.checkList.items.length === 0 || !props.canSave,
        onClick: () => {
          if (props.onConvertChecklist) props.onConvertChecklist();
        },
      });
    }

    //instances can only be removed for monitoring types
    //- subtasks of events & event templates can only be removed from the subtask list of the event
    //- instances of normal recurring tasks can never be removed (only by changing the recurrence pattern)
    if (
      props.onClickRemove &&
      props.task.taskId !== -1 &&
      (!props.task.isInstance() || props.task.taskType === TaskTypes.Monitoring)
    ) {
      if (menu.items.length > 0) {
        menu.items.push({
          key: 'div1',
          text: '-',
          itemType: ContextualMenuItemType.Divider,
        });
      }
      menu.items.push({
        className: 'redlab-usetiful-task-topbar-more-remove',
        key: 'remove',
        text: t('translation:General.Button.Remove'),
        iconProps: deleteIcon,
        disabled: !props.canDelete,
        onClick: () => {
          if (props.onClickRemove) props.onClickRemove(props.task.taskId);
        },
      });
    }

    return menu;
  };

  const getMoreOptions = (): JSX.Element | null => {
    const menu = getMoreMenuOptions();
    if (menu.items.length > 0 && menu.items.some((i) => i.disabled === false)) {
      return (
        <DefaultButton
          className="redlab-usetiful-task-topbar-more"
          styles={topTaskButtonStyles}
          iconProps={moreIcon}
          menuProps={menu}
        />
      );
    } else {
      return null;
    }
  };

  //
  // Main render
  //
  return (
    <Stack horizontal wrap tokens={globalStackTokensGapSmall}>
      {props.onClickTemplate && props.task.taskId === -1 && (
        <Stack.Item>
          <DefaultButton
            className="redlab-usetiful-task-topbar-template"
            styles={topTaskButtonStyles}
            iconProps={templateIcon}
            text={t('task:TopTasks.Template')}
            onMenuClick={() => loadTemplates()}
            menuProps={getTemplates}
          />
        </Stack.Item>
      )}
      {isManager && props.onClickOpenMaster && props.task.isInstance() && (
        <Stack.Item>
          <TooltipHost content={t('task:Master.OpenLink')}>
            <DefaultButton
              className="redlab-usetiful-task-topbar-openmaster"
              styles={topTaskButtonStyles}
              iconProps={{ iconName: 'Info' }}
              text={t('task:TopTasks.OpenMaster')}
              onClick={() => {
                if (props.onClickOpenMaster) props.onClickOpenMaster(props.task.taskMasterId);
              }}
            />
          </TooltipHost>
        </Stack.Item>
      )}
      {isManager && props.onClickOpenEvent && props.task.isInstance() && props.task.taskType === TaskTypes.Event && (
        <Stack.Item>
          <TooltipHost content={t('task:TopTasks.OpenEventInfo')}>
            <DefaultButton
              className="redlab-usetiful-task-topbar-openevent"
              styles={topTaskButtonStyles}
              iconProps={{ iconName: 'Info' }}
              text={t('task:TopTasks.OpenEvent')}
              onClick={() => {
                if (props.onClickOpenEvent) props.onClickOpenEvent(props.task.taskMasterId);
              }}
            />
          </TooltipHost>
        </Stack.Item>
      )}
      {props.canUpdate && props.onClickForm && hasUserFeature(appContext, FeatureTypes.KPIsAndForms) && (
        <Stack.Item>
          <DefaultButton
            className="redlab-usetiful-task-topbar-form"
            styles={topTaskButtonStyles}
            iconProps={formIcon}
            text={t('task:TopTasks.Form')}
            menuProps={{ items: getForms() }}
          />
        </Stack.Item>
      )}
      {props.onClickFollowUp && props.task.taskId !== -1 && (
        <Stack.Item>
          <DefaultButton
            className="redlab-usetiful-task-topbar-followup"
            styles={topTaskButtonStyles}
            iconProps={{ iconName: 'SetAction' }}
            text={t('task:TopTasks.FollowUp')}
            onMenuClick={() => loadTemplates()}
            menuProps={getFollowUpActions}
          />
        </Stack.Item>
      )}
      {props.onClickFinish &&
        props.task.taskId !== -1 &&
        !props.task.completed &&
        (!props.task.isSeries() || props.task.taskType === TaskTypes.Event) &&
        canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Status, appContext) && (
          <Stack.Item>
            <DefaultButton
              className="redlab-usetiful-task-topbar-finish"
              styles={topTaskButtonStyles}
              disabled={!props.canSave}
              iconProps={{ iconName: 'FavoriteStarFill' }}
              text={t('task:TopTasks.Finish')}
              onClick={props.onClickFinish}
            />
          </Stack.Item>
        )}
      {getMoreOptions()}
    </Stack>
  );
};

export default TaskTopTasksBar;
