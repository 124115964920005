import { IAppContext } from 'App/AppContext';
import { EntityTypes } from 'models/entity';
import Package from 'models/package';
import ResourceLink from 'models/resourceLink';
import ResourceList from 'models/resourceList';
import AppError from 'utils/appError';
import { getURLLastPart, getURLWithoutLastPart } from 'utils/url';
import { apiGetLink } from 'services/Api/linkService';
import { SitePageHyperLink, SitePageHyperLinkTypes } from 'models/sharePoint/sharePointHyperLink';
import { graphGetCanvasValidWebParts, graphPageHasValidWebParts } from 'services/Graph/graphServicePage';
import { ISitePage } from 'services/Graph/SharepointInterfaces';
import { globalAppPortalUrl, globalRegExExtractRelSpUrlFromHtml, globalRegExExtractUrlFromHtml } from 'globalConstants';
import { apiGetControl } from 'services/Api/controlService';
import { apiGetObjective } from 'services/Api/objectiveService';
import { apiGetProcess } from 'services/Api/processService';
import { apiGetRisk } from 'services/Api/riskService';
import { apiGetSingleTask } from 'services/Api/taskService';
import { apiGetTheme } from 'services/Api/themeService';
import { apiRequest } from 'services/Auth/authConfig';
import { newGuidNil, isValidGuid } from 'utils/guid';
import { TFunction } from 'i18next';
import { LinkConversionTableItemDTO } from 'models/dto/linkConversionDTO';

export const replaceLinksToEntity = (
  imports: LinkConversionTableItemDTO[],
  html: string,
  type: EntityTypes,
  typeUrlPart: string,
): string => {
  const appUrl = 'https://portal.isoplanner.app';
  let typeUrl = appUrl + `/${typeUrlPart}/`;
  typeUrl = typeUrl.replaceAll('.', '\\.');
  typeUrl = typeUrl.replaceAll('/', '\\/');
  const themeRegExp = new RegExp(`(${typeUrl})[0-9]+`, 'gi');
  const matches = [...html.matchAll(themeRegExp)];
  let newHtml = html;

  for (let idx = matches.length - 1; idx >= 0; idx--) {
    //backwards so we don't mess up indexes
    const match = matches[idx];
    const url = match[0];
    const id = getURLLastPart(url);
    if (id) {
      const imp = getPackageImportOptional(imports, type, id);
      if (imp && match.index !== undefined) {
        const newUrl = getURLWithoutLastPart(url) + '/' + imp.targetEntityId.toString();
        newHtml = newHtml.substring(0, match.index) + newUrl + newHtml.substring(match.index + url.length);
      }
    }
  }

  return newHtml;
};

export const getPackageImport = (
  imports: LinkConversionTableItemDTO[],
  type: EntityTypes,
  sourceEntityId: string,
): LinkConversionTableItemDTO => {
  const result = getPackageImportOptional(imports, type, sourceEntityId);
  if (!result)
    throw new AppError(
      'Package is corrupt. cannot find import record -> type:' + type.toString() + ' id:' + sourceEntityId.toString(),
    );

  return result;
};

export const getPackageImportOptional = (
  imports: LinkConversionTableItemDTO[],
  type: EntityTypes,
  sourceEntityId: string,
): LinkConversionTableItemDTO | undefined => {
  return imports.find((i) => i.sourceEntityType === type && i.sourceEntityId === sourceEntityId);
};

export const getListFromImport = (pack: Package, id: number, appContext: IAppContext): ResourceList => {
  const imp = getPackageImport(pack.imports, EntityTypes.List, id.toString());
  const list = appContext.globalDataCache.lists.get(Number.parseInt(imp.targetEntityId));
  if (list.listId === -1) throw new AppError('Could not find list based on import table');

  return list;
};

export const getLinkFromImport = async (
  pack: Package,
  id: number,
  accessToken: string,
  appContext: IAppContext,
): Promise<ResourceLink> => {
  const imp = getPackageImport(pack.imports, EntityTypes.Link, id.toString());
  const link = await apiGetLink(Number.parseInt(imp.targetEntityId), accessToken, appContext.globalDataCache);
  if (!link) throw new AppError('Could not find newly created link based on import table');

  return link;
};

export const extractPageUrls = async (
  page: ISitePage,
  allSitePages: ISitePage[],
  t: TFunction<string[]>,
  appContext: IAppContext,
): Promise<SitePageHyperLink[]> => {
  const pagesWithHyperlinks: SitePageHyperLink[] = [];
  const validParts = graphGetCanvasValidWebParts(page);

  for (let idx = 0; idx < validParts.length; idx++) {
    const webPart = validParts[idx];
    const urls = extractUrls(webPart.innerHtml);

    for (let urlNr = 0; urlNr < urls.length; urlNr++) {
      const url = urls[urlNr];
      const pageWithHyperlinks = new SitePageHyperLink();
      pageWithHyperlinks.page = page;
      pageWithHyperlinks.url = url;
      pageWithHyperlinks.type = getUrlType(url);
      pageWithHyperlinks.error = await getUrlError(pageWithHyperlinks.type, url, allSitePages, t, appContext);
      pageWithHyperlinks.valid = pageWithHyperlinks.error === undefined;
      pageWithHyperlinks.notSupported = !graphPageHasValidWebParts(page);
      pagesWithHyperlinks.push(pageWithHyperlinks);
    }
  }

  return pagesWithHyperlinks;
};

export const extractUrls = (html: string): string[] => {
  if (!html) return [];

  const matches = [
    ...html.matchAll(globalRegExExtractUrlFromHtml),
    ...html.matchAll(globalRegExExtractRelSpUrlFromHtml),
  ];
  const urls: string[] = [];

  for (let idx = 0; idx < matches.length; idx++) {
    const match = matches[idx];
    let url = match[0];
    url = url.substring(1, url.length - 1);
    urls.push(url);
  }

  return [...new Set(urls)];
};

export const getUrlType = (url: string): SitePageHyperLinkTypes => {
  const urlLower = url.toLowerCase();
  if (urlLower.includes(globalAppPortalUrl)) {
    return SitePageHyperLinkTypes.App;
  }
  if (urlLower.includes('.sharepoint.com') || urlLower.startsWith('/sites/')) {
    return SitePageHyperLinkTypes.SharePoint;
  }

  return SitePageHyperLinkTypes.External;
};

const getUrlError = async (
  type: SitePageHyperLinkTypes,
  url: string,
  allSitePages: ISitePage[],
  t: TFunction<string[]>,
  appContext: IAppContext,
): Promise<string | undefined> => {
  const urlLower = url.toLowerCase();
  switch (type) {
    case SitePageHyperLinkTypes.App:
      //validate the tenant guid
      const tidStr = 'tid=';
      const tidIdx = urlLower.indexOf(tidStr);
      if (tidIdx >= 0) {
        const startOfGuid = tidIdx + tidStr.length;
        const tid = urlLower.substring(startOfGuid, startOfGuid + newGuidNil().length);
        if (isValidGuid(tid)) {
          if (tid !== appContext.user.tenant.azureTenantId) {
            return t('library:PageValidatorModal.Errors.OtherTenant', {
              tenantId: appContext.user.tenant.azureTenantId,
            });
          }
        } else {
          return t('library:PageValidatorModal.Errors.InvalidGuid');
        }
      } else {
        return t('library:PageValidatorModal.Errors.NoTenant');
      }

      //validate the org unit guid
      const ouStr = 'ouid=';
      const ouIdx = urlLower.indexOf(ouStr);
      if (ouIdx >= 0) {
        const startOfGuid = ouIdx + ouStr.length;
        const ouid = urlLower.substring(startOfGuid, startOfGuid + newGuidNil().length);
        if (isValidGuid(ouid)) {
          if (ouid !== appContext.user.login.tenantId) {
            return t('library:PageValidatorModal.Errors.OtherOrgUnit', { tenantId: appContext.user.login.tenantId });
          }
        } else {
          return t('library:PageValidatorModal.Errors.InvalidGuid');
        }
      } else if (appContext.user.login.isOrgUnit === true) {
        return t('library:PageValidatorModal.Errors.NoOrgUnit');
      }

      //validate entity id
      let id: number | undefined = undefined;
      id = extractIdFromAppUrl(urlLower, '/theme/');
      if (Number.isNaN(id)) {
        return t('library:PageValidatorModal.Errors.InvalidRequirement');
      } else if (id) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const theme = await apiGetTheme(id, false, false, accessToken, appContext.globalDataCache);
        if (!theme || theme.themeId === 0) {
          return t('library:PageValidatorModal.Errors.InvalidRequirement');
        }
      }

      id = extractIdFromAppUrl(urlLower, '/control/');
      if (Number.isNaN(id)) {
        return t('library:PageValidatorModal.Errors.InvalidControl');
      } else if (id) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const control = await apiGetControl(id, false, false, accessToken, appContext.globalDataCache);
        if (!control || control.controlId === 0) {
          return t('library:PageValidatorModal.Errors.InvalidControl');
        }
      }

      id = extractIdFromAppUrl(urlLower, '/risk/');
      if (Number.isNaN(id)) {
        return t('library:PageValidatorModal.Errors.InvalidRisk');
      } else if (id) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const riskCol = await apiGetRisk(id, accessToken, appContext.globalDataCache);
        if (!riskCol || riskCol.risks.length === 0) {
          return t('library:PageValidatorModal.Errors.InvalidRisk');
        }
      }

      id = extractIdFromAppUrl(urlLower, '/process/');
      if (Number.isNaN(id)) {
        return t('library:PageValidatorModal.Errors.InvalidProcess');
      } else if (id) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const process = await apiGetProcess(id, accessToken, appContext.globalDataCache);
        if (!process || process.processId === 0) {
          return t('library:PageValidatorModal.Errors.InvalidProcess');
        }
      }

      id = extractIdFromAppUrl(urlLower, '/objective/');
      if (Number.isNaN(id)) {
        return t('library:PageValidatorModal.Errors.InvalidObjective');
      } else if (id) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const objective = await apiGetObjective(id, accessToken, appContext.globalDataCache);
        if (!objective || objective.objectiveId === 0) {
          return t('library:PageValidatorModal.Errors.InvalidObjective');
        }
      }

      id = extractIdFromAppUrl(urlLower, '/tasks/');
      if (Number.isNaN(id)) {
        //for tasks, the /tasks/ url part is not always followed by a task id.
        //e.g. /tasks/alltasks
        //do not return an error for this
      } else if (id) {
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const taskCol = await apiGetSingleTask(id, false, accessToken, appContext.globalDataCache);
        if (!taskCol || taskCol.tasks.length === 0) {
          return t('library:PageValidatorModal.Errors.InvalidTask');
        }
      }

      break;
    case SitePageHyperLinkTypes.External:
      //no extra checks
      break;
    case SitePageHyperLinkTypes.SharePoint:
      const sitePageIdx = urlLower.indexOf('/sitepages/');
      if (sitePageIdx >= 0) {
        //this is a page-to-page link
        let sitePageUrl = urlLower;
        //remove the query and/or hashtag
        let queryIdx = sitePageUrl.indexOf('?');
        if (queryIdx === -1) queryIdx = sitePageUrl.length + 1;
        let hashIdx = sitePageUrl.indexOf('#');
        if (hashIdx === -1) hashIdx = sitePageUrl.length + 1;
        const removeFromIdx = Math.min(queryIdx, hashIdx);
        if (removeFromIdx <= sitePageUrl.length) {
          sitePageUrl = sitePageUrl.substring(0, removeFromIdx);
        }
        //find the url in the list with all SharePoint pages
        const page = allSitePages.find((p) => p.webUrl.toLowerCase() === sitePageUrl);
        if (!page) {
          return t('library:PageValidatorModal.Errors.NotFound');
        }
        //check every url by GET request
        const isValidUrl = await checkUrl(urlLower);
        if (!isValidUrl) return t('library:PageValidatorModal.Errors.Unreachable');
      }

      break;
  }

  //every url should have max 1 question mark and max 1 hashtag
  const hashTagCount = (urlLower.match(new RegExp('#', 'g')) || []).length;
  if (hashTagCount > 1) {
    return t('library:PageValidatorModal.Errors.TooManyHashtags');
  }
  const questionMarkCount = (urlLower.match(new RegExp('\\?', 'g')) || []).length;
  if (questionMarkCount > 1) {
    return t('library:PageValidatorModal.Errors.TooManyQuestionMarks');
  }

  return undefined;
};

const extractIdFromAppUrl = (url: string, str: string): number | undefined => {
  let idx = 0;
  let id = '';

  idx = url.indexOf(str);
  if (idx >= 0) {
    try {
      let nextSlashIdx = url.indexOf('/', idx + str.length);
      if (nextSlashIdx === -1) nextSlashIdx = url.indexOf('?', idx + str.length);
      if (nextSlashIdx === -1) nextSlashIdx = url.length;
      id = url.substring(idx + str.length, nextSlashIdx);

      return Number(id);
    } catch {
      return NaN;
    }
  }

  return undefined;
};

const checkUrl = async (url: string): Promise<boolean> => {
  try {
    await fetch(url, { mode: 'no-cors' }); //

    return true;
  } catch {
    return false;
  }
};
