import { useCallback, useContext, useEffect, useState } from 'react';
import Task, { TaskTypes } from 'models/tasks/task';
import { TFunction } from 'i18next';
import { CommandBar, ICommandBarItemProps, SelectionMode, Stack, Text } from '@fluentui/react';
import KanbanBoardTasks from 'components/Kanban/KanbanBoardTasks';
import TaskList from './TaskList';
import { TaskScheduleDetailsList } from './TaskScheduleDetailsList';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import Assignee from 'models/assignee';
import { addDateTimeYears, getAuditStartForSetting, getCurrentAuditYear, getDateTimeDiffMinute } from 'utils/datetime';
import { deleteIcon, editIcon, globalStackStylesPaddingScene, globalStackTokensGapMedium } from 'globalStyles';
import AppContext from 'App/AppContext';
import { TaskViewFilters } from '../Filter/TaskViewFilters';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PeriodFilter from 'components/Filter/PeriodFilter';
import { AuditYearStart } from 'models/setting';
import SingleTask from '../SingleTask';
import { IKanbanSortProps, getKanbanSortOrder, updateTaskKanban } from 'components/Kanban/KanbanBoardSortHelper';
import {
  getCommandBarItemsAddTaskEvent,
  getCommandBarItemsAuditYear,
  getCommandBarItemsGroupBy,
  getCommandBarItemsScheduleLevel,
  getCommandBarItemsSwitchView,
} from './TaskViewButtons';
import { getCommandBarItemsReportContextMenu } from 'components/Reports/ReportContextMenu';
import ReportDefinition, { ReportContext, ReportType } from 'models/reports/ReportDefinition';
import ReportRequestDTO from 'models/dto/Reports/ReportRequestDTO';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import { DialogOk } from 'components/Dialogs/DialogOk';
import BulkActionsModal, {
  BulkActionType,
  IBulkAction,
  IBulkActionAssignee,
  IBulkActionOwner,
  IBulkActionState,
  IBulkActionStateTags,
  IBulkActionTag,
  ICheckboxState,
} from 'components/Dialogs/BulkActionsModal';
import ReportRequest from 'components/Reports/ReportRequest';
import { apiRequest } from 'services/Auth/authConfig';
import { apiRemoveTask, apiUpdateTasks } from 'services/Api/taskService';
import { apiAddTagToTask, apiRemoveTagFromTask } from 'services/Api/tagService';
import { getOutlookErrorMsg } from 'services/Api/apiErrors';
import AppError from 'utils/appError';
import { getUniqueTagIds } from 'utils/tagHelpers';
import { sortOnTag } from 'utils/sorting';
import Logger from 'services/Logging/logService';
import { mapToTasks } from 'mappings/taskMapping';
import { ReportRequest16DTO } from 'models/dto/Reports/ReportRequests_16_DTO';
import { ReportRequest3DTO } from 'models/dto/Reports/ReportRequests_3_DTO';
import {
  hasUserDataPermission,
  hasUserFeatureGenericManager,
  hasUserRolePermission,
} from 'services/Auth/featurePermissions';
import OverlayLoader from 'components/Loading/OverlayLoader';
import { getRandomId } from 'utils/string';
import { usePrevious } from 'globalHooks';
import { GroupMember } from 'models/groupMembers';
import { TaskState } from 'models/tasks/taskHelperClasses';
import { QuickFilterContext } from 'components/Filter/QuickFilter';
import { Owner } from 'models/owner';
import { canDeleteTasks, canUpdateTaskField, canUpdateTasks, TaskFieldTypes } from '../TaskAuthHelper';
import { PermissionTypes } from 'models/auth/rolePermission';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';
import { isValidGuid } from 'utils/guid';
import { memberFilterCompletedKey } from '../Filter/TaskViewMemberFilter';

export enum TaskViewType {
  Board = 0,
  List = 1,
  Schedule = 2,
}

export enum TaskViewScheduleLevel {
  //value is used to divide number of months to get quarters / months
  Year = 12,
  Quarter = 3,
  Month = 1,
}

//
// Task view
//
interface ITasksViewBoardProps {
  taskStates?: TaskState[];
  allowColumnReorder: boolean;
  onGetNewTaskBoard?: (task: Task) => Task;
}

interface ITasksViewListProps {
  hideColumns?: string[];
  selectionMode?: SelectionMode;
  cacheKeySort: LocalStorageKeys;
  onChangeSelection?: (tasks: Task[]) => void;
  onGetNewTaskList?: (template: Task | undefined, newTask: Task) => Task;
}

interface ITasksViewScheduleProps {
  hideContext?: boolean;
  allowEditSeries?: boolean;
  selectionMode?: SelectionMode;
  onChangeSelection?: (tasks: Task[]) => void;
  onTaskEditRecurringPattern?: (task: Task) => void;
  onTaskRecurringPatternUpdated?: (oldTask: Task, newTask: Task) => void;
  onCreateSchedule?: () => void;
  onUpdateTaskSeries?: (series: Task[]) => void;
}

interface ITasksViewProps {
  tasks: Task[];
  isLoading: boolean;
  isUpdating: boolean;
  boardProps: ITasksViewBoardProps;
  listProps: ITasksViewListProps;
  scheduleProps: ITasksViewScheduleProps;
  filter?: string[];
  hidePeriodSelector?: boolean;
  hideQuickFilter?: boolean;
  hideViewType?: boolean;
  showGroupMemberFilter?: boolean;
  reloadKey?: string;
  quickFilterContext?: QuickFilterContext;
  filterStorageKey: LocalStorageKeys;
  navigateExternal?: boolean;
  showRefresh?: boolean;
  onTaskClick?: (task: Task) => void;
  onUpdateFilter?: (filter: string[]) => void;
  onLoad: (
    viewType: TaskViewType,
    filter: string[],
    memberFilter: string[],
    start: Date | undefined,
    end: Date | undefined,
  ) => void;
  onUpdateTasks: (tasks: Task[]) => void;
}

const TasksView = (props: ITasksViewProps) => {
  const { t } = useTranslation(['translation', 'tasks', 'reports', 'tags']);
  const appContext = useContext(AppContext);
  const history = useHistory();
  const currentUserId = appContext.user.id;
  const [tasksDisplay, setTasksDisplay] = useState<Task[]>([]);
  const [kanbanGroupByField, setKanbanGroupByField] = useState<'state' | 'assignee'>('state');
  const [scheduleLevel, setScheduleLevel] = useState<TaskViewScheduleLevel>(TaskViewScheduleLevel.Month);
  const auditDateSetting: string = appContext.globalDataCache.settings.get(AuditYearStart);
  const [auditYear, setAuditYear] = useState<number>(getCurrentAuditYear(auditDateSetting));
  const [showColumnSelector, setShowColumnSelector] = useState<boolean>(false);
  const getUserViewType = (): TaskViewType => {
    if (props.hideViewType || appContext.isMobileView) return TaskViewType.List;
    const val = getLocalStorageData(appContext, LocalStorageKeys.TaskViewType);

    return Number.parseInt(val || TaskViewType.List.toString());
  };
  const [viewType, setViewType] = useState<TaskViewType>(getUserViewType());
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [periodStart, setPeriodStart] = useState<Date | undefined>(undefined);
  const [periodEnd, setPeriodEnd] = useState<Date | undefined>(undefined);
  const prevPeriodStart = usePrevious(periodStart);
  const [selectedReport, setSelectedReport] = useState<ReportDefinition | undefined>(undefined);
  const [reportRequest, setReportRequest] = useState<ReportRequestDTO | undefined>(undefined);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState<boolean>(false);
  const [showDeleteTasks, setShowDeleteTasks] = useState<boolean>(false);
  const [showTaskDeleteError, setShowTaskDeleteError] = useState<number>(0);
  const [showTaskDeleteSucces, setShowTaskDeleteSucces] = useState<number>(0);
  const [showDeleteTasksResult, setShowDeleteTasksResult] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [seriesTasks, setSeriesTasks] = useState<Task[]>([]);
  const [selectionResetKey, setSelectionResetKey] = useState<string>(getRandomId());
  const [filter, setFilter] = useState<string[]>([]);
  const [memberFilter, setMemberFilter] = useState<string[]>([]);
  const [membershipLoadDone, setMembershipLoadDone] = useState<boolean>(false);
  const [membershipInitialized, setMembershipInitialized] = useState<boolean>(false);
  const [filterInitialized, setFilterInitialized] = useState<boolean>(false);
  const [periodFilterInitialized, setPeriodFilterInitialized] = useState<boolean>(false);
  const [isLoadingMemberships, setIsLoadingMemberships] = useState<boolean>(false);
  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);

  //
  // Effects
  //
  useEffect(() => {
    //set schedule view settings
    const yearFromStorageRaw = getLocalStorageData(appContext, LocalStorageKeys.AuditYear);
    const yearFromStorage = yearFromStorageRaw ? Number.parseInt(yearFromStorageRaw) : undefined;
    const year = yearFromStorage ?? auditYear;
    setAuditYear(year);
    const scheduleLevelFromStorageRaw = getLocalStorageData(appContext, LocalStorageKeys.ScheduleLevel);
    const scheduleLevelFromStorage = scheduleLevelFromStorageRaw
      ? Number.parseInt(scheduleLevelFromStorageRaw)
      : undefined;
    setScheduleLevel(scheduleLevelFromStorage ?? scheduleLevel);

    if (viewType === TaskViewType.Schedule) {
      loadDataSchedule(year);
    } else {
      //load is triggers through change of periodStart/End
    }

    loadMemberFilterFromStorage();

    if (props.hidePeriodSelector === true) {
      setPeriodFilterInitialized(true);
    }

    //clean-up
    return () => {
      setTasksDisplay([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Helper to determine when the period filter has been initialized
    if (!periodFilterInitialized && prevPeriodStart === undefined && periodStart !== undefined) {
      setPeriodFilterInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodStart]);

  useEffect(() => {
    //This trigger on rule 1), 3), 5) and 6)
    if (filterInitialized && membershipInitialized && periodFilterInitialized) {
      loadData(viewType, getFilter(), memberFilter, periodStart, periodEnd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.reloadKey,
    membershipInitialized,
    periodFilterInitialized,
    filterInitialized,
    periodStart,
    periodEnd,
    memberFilter,
  ]);

  useEffect(() => {
    const newBaseReportRequest = ReportRequestDTO.getNewRequest(appContext);

    switch (selectedReport?.id) {
      case ReportType.OperationalPlanning:
        const newReport3Request = new ReportRequest3DTO(newBaseReportRequest, t as TFunction<string[]>);
        newReport3Request.reportDefinition = selectedReport;
        tasksDisplay.forEach((task) => (task.recurringPatternSummary = task.recurringPattern.getShortSummary()));
        newReport3Request.series = mapToTasks(seriesTasks);
        newReport3Request.tasks = mapToTasks(tasksDisplay);
        setReportRequest(newReport3Request);
        break;
      case ReportType.Tasks:
        const newReport16Request = new ReportRequest16DTO(newBaseReportRequest, t as TFunction<string[]>);
        newReport16Request.reportDefinition = selectedReport;
        newReport16Request.tasks = mapToTasks(tasksDisplay);
        setReportRequest(newReport16Request);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  //
  // Load
  //
  const loadData = useCallback(
    //Trigger the load in the following conditions:
    //1. After initialization
    //   - when period filter has loaded AND;
    //   - when groupmember filter has loaded AND;
    //   - when the normal filter has loaded
    //2. When completed/shared filter is changed and parent indicates that reload is needed for this
    //3. When period filter is shown & changed
    //4. When view type is changed
    //5. When member filter is changed
    //6. When the reloadKey is changed by the parent control
    (
      viewType: TaskViewType,
      filter: string[],
      memberFilter: string[],
      start: Date | undefined,
      end: Date | undefined,
    ) => {
      props.onLoad(viewType, filter, memberFilter, start, end);
    },
    [props],
  );

  const isLoading = useCallback((): boolean => {
    return props.isLoading || isLoadingMemberships;
  }, [isLoadingMemberships, props.isLoading]);

  //
  // Filter
  //
  const getFilter = useCallback((): string[] => {
    return props.filter ?? filter;
  }, [filter, props.filter]);

  const toggleFilterDialog = () => {
    setShowFilterDialog(!showFilterDialog);
  };

  //
  // Member filter
  //
  const loadMemberFilterFromStorage = async () => {
    try {
      // Format:
      // - list of group ids (guid)
      // - list of schema ids (number)
      // - optional: current user id anywhere in the list
      //
      setMembershipInitialized(false);
      const filterString = getLocalStorageData(appContext, LocalStorageKeys.MemberFilterTasks);
      let existingGroups: string[] = [];

      if (filterString) {
        const ids = filterString.split(',');

        if (ids.length > 0) {
          let groupids: string[] = [...ids];
          //filter out the current user
          if (ids.includes(currentUserId)) {
            groupids = groupids.filter((g) => g !== currentUserId);
          }
          //filter out non-guids (schemas)
          groupids = groupids.filter((g) => isValidGuid(g));

          //add the groups if needed
          let membershipInfo: GroupMember[] = [];

          if (groupids.length > 0) {
            //load the group membership
            membershipInfo = await loadGroupMembership();
            const groups = membershipInfo.map((m) => m.groupId);
            //filter out non-existent groups
            existingGroups = groupids.filter((id) => groups.includes(id));
          }

          //add the current user back
          if (ids.includes(currentUserId)) {
            existingGroups.push(currentUserId);
          }

          //add completed filter back
          if (ids.includes(memberFilterCompletedKey)) {
            existingGroups.push(memberFilterCompletedKey);
          }

          //add schemas back that exist in the cache
          existingGroups.push(
            ...ids.filter((g) => !isNaN(Number(g)) && appContext.globalDataCache.authSchemas.has(Number(g))),
          );
        }
      }

      //when all groups are unselected, add the current user
      if (existingGroups.filter((g) => isValidGuid(g)).length === 0) {
        existingGroups.push(currentUserId);
      }

      setMemberFilter(existingGroups);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setMembershipInitialized(true);
    }
  };

  const loadGroupMembership = async (): Promise<GroupMember[]> => {
    if (isLoadingMemberships || membershipLoadDone) {
      return [];
    }

    let membershipInfo: GroupMember[] | undefined;

    try {
      setIsLoadingMemberships(true);

      //this sets the indication isCurrentUserMember on the groups in the cache
      membershipInfo = await appContext.globalDataCache.groups.getCurrentUserMembers();
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingMemberships(false);
      setMembershipLoadDone(true);
    }

    return membershipInfo ?? [];
  };

  //
  // Helpers
  //
  const onUpdateFilter = (filter: string[]) => {
    if (!filterInitialized) {
      setFilterInitialized(true);
    }
    if (props.onUpdateFilter) {
      //filter state is managed by parent component
      props.onUpdateFilter(filter);
    } else {
      //filter state is managed by this component
      setFilter(filter);
    }
  };

  const onNewTaskBoard = (statusIndex: number, taskList: Task[], statusObj: TaskState | Assignee) => {
    let newTask = getNewTaskBoard(statusIndex, taskList, statusObj);
    if (props.boardProps.onGetNewTaskBoard) {
      newTask = props.boardProps.onGetNewTaskBoard(newTask);
      newTask.addEntities(newTask.RelatedEntities);
    }

    setSelectedTask(newTask);
  };

  const getNewTaskBoard = (statusIndex: number, taskList: Task[], statusObj: TaskState | Assignee): Task => {
    const newTask = new Task();
    newTask.taskStates = appContext.globalDataCache.taskStates.items;
    if (statusObj instanceof TaskState) {
      newTask.taskStateId = newTask.taskStates[statusIndex].taskStateId;
    } else {
      newTask.taskStateId = newTask.taskStates[0].taskStateId;
      newTask.userId = statusObj.id;
    }
    newTask.userId = appContext.user.id;
    newTask.user = appContext.user;
    newTask.creatorId = appContext.user.id;
    newTask.creator = appContext.user;
    newTask.ownerId = appContext.user.id;
    newTask.owner = appContext.user;
    newTask.name = t('translation:Task.Name.Default');

    const sortProps: IKanbanSortProps<Task> = {
      appContext: appContext,
      itemList: taskList,
      sourceIndex: -1, //add new item in this list
      targetIndex: 0, //add at the top of the list
      updateItem: updateTaskKanban,
    };

    newTask.sortOrder = getKanbanSortOrder(sortProps);

    return newTask;
  };

  //
  // Commandbar items
  //
  const getCommandBarItems = useCallback((): ICommandBarItemProps[] => {
    const navigateToTemplates = () => {
      const url = '/tasks/templates';
      history.push(url);
    };

    const onNewTaskList = (template?: Task) => {
      let newTask = getNewTask(template);
      if (props.listProps.onGetNewTaskList) {
        newTask = props.listProps.onGetNewTaskList(template, newTask);
        newTask.addEntities(newTask.RelatedEntities);
      }

      setSelectedTask(newTask);
    };

    const getNewTask = (template?: Task): Task => {
      let newTask: Task;
      if (template) {
        newTask = new Task().applyTemplate(template, appContext);
        newTask.taskStateId = newTask.getFirstState();
      } else {
        newTask = new Task();
        newTask.taskType = TaskTypes.Normal;
        newTask.taskStates = appContext.globalDataCache.taskStates.items;
        newTask.taskStateId = newTask.taskStates[0].taskStateId;
        newTask.name = t('translation:Task.Name.Default');
        newTask.ownerId = appContext.user.id;
        newTask.owner = appContext.user.clone();
      }

      //default creator to current user
      newTask.creatorId = appContext.user.id;
      newTask.creator = appContext.user.clone();

      return newTask;
    };

    const items: ICommandBarItemProps[] = [];

    items.push(
      getCommandBarItemsAddTaskEvent(
        {
          className: 'redlab-usetiful-mytasks-add',
          onAdd: onNewTaskList,
          onNavigateToTemplates: navigateToTemplates,
        },
        appContext,
        t,
        props.navigateExternal,
      ),
    );

    items.push({
      key: 'edit',
      text: t('translation:General.Button.EditBulk'),
      iconProps: editIcon,
      disabled: selectedTasks.length === 0 || !canUpdateTasks(selectedTasks, appContext),
      onClick: () => setIsBulkModalOpen(true),
    });

    if (hasUserFeatureGenericManager(appContext)) {
      items.push({
        key: 'delete',
        text: t('tasks:TabAllTasks.Commands.Delete'),
        iconProps: deleteIcon,
        disabled: selectedTasks.length === 0 || !canDeleteTasks(selectedTasks, appContext),
        onClick: () => setShowDeleteTasks(true),
      });
    }

    if (props.showRefresh) {
      items.push({
        key: 'refresh',
        disabled: props.isLoading,
        text: t('translation:General.Button.Refresh'),
        iconProps: { iconName: 'Refresh' },
        onClick: () => {
          loadData(viewType, filter, memberFilter, periodStart, periodEnd);
        },
      });
    }

    items.push(
      getCommandBarItemsReportContextMenu(
        viewType === TaskViewType.Schedule ? ReportContext.Schedule : ReportContext.Tasks,
        {
          disabled: isLoading() || tasksDisplay.length === 0,
          onSelect: (report) => setSelectedReport(report),
        },
        t as TFunction<string[]>,
      ),
    );

    return items;
  }, [
    appContext,
    filter,
    history,
    isLoading,
    loadData,
    memberFilter,
    periodEnd,
    periodStart,
    props.isLoading,
    props.listProps,
    props.navigateExternal,
    props.showRefresh,
    selectedTasks,
    t,
    tasksDisplay.length,
    viewType,
  ]);

  const loadDataSchedule = useCallback(
    (year: number) => {
      const startDate = getAuditStartForSetting(year, auditDateSetting);
      const endDate = addDateTimeYears(startDate, 1);
      loadData(viewType, getFilter(), memberFilter, startDate, endDate);
    },
    [auditDateSetting, getFilter, loadData, memberFilter, viewType],
  );

  const onSwitchView = useCallback(
    //This triggers on rule 4)
    (viewType: TaskViewType) => {
      setViewType(viewType);
      setLocalStorageData(appContext, LocalStorageKeys.TaskViewType, viewType.toString());
      setSelectedTasks([]);
      if (viewType === TaskViewType.Schedule) {
        loadDataSchedule(auditYear);
      } else {
        loadData(viewType, getFilter(), memberFilter, periodStart, periodEnd);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auditYear, getFilter, loadData, loadDataSchedule, memberFilter, periodEnd, periodStart],
  );

  const onSelectYear = useCallback(
    (year: number) => {
      setLocalStorageData(appContext, LocalStorageKeys.AuditYear, year.toString());
      setAuditYear(year);
      loadDataSchedule(year);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadDataSchedule],
  );

  const updatePeriod = (start: Date, end: Date) => {
    if (
      !periodStart ||
      !periodEnd ||
      getDateTimeDiffMinute(start, periodStart) !== 0 ||
      getDateTimeDiffMinute(end, periodEnd) !== 0
    ) {
      setPeriodStart(start);
      setPeriodEnd(end);
    }
  };

  const getCommandBarFarItems = useCallback((): ICommandBarItemProps[] => {
    const getViewColumnSelectorButton = () => {
      return {
        key: 'columns',
        disabled: viewType !== TaskViewType.List,
        text: t('translation:General.Button.SelectColumns'),
        iconProps: { iconName: 'ColumnOptions' },
        onClick: () => setShowColumnSelector(true),
      };
    };

    const items: ICommandBarItemProps[] = [];

    if (!props.hideViewType) {
      items.push(
        getCommandBarItemsSwitchView(
          viewType,
          { disabled: isLoading() || appContext.isMobileView, onSwitchView: onSwitchView },
          t,
          [getViewColumnSelectorButton()],
        ),
      );
    } else {
      items.push({
        className: 'redlab-usetiful-taskview-switchview',
        key: 'switchView',
        disabled: isLoading() || appContext.isMobileView,
        text: t('tasks:TaskView.Commands.View.Main'),
        iconProps: { iconName: 'View' },
        subMenuProps: { items: [getViewColumnSelectorButton()] },
      });
    }

    if (viewType === TaskViewType.Board) {
      items.push(getCommandBarItemsGroupBy(kanbanGroupByField, { disabled: isLoading(), onGroupBy: onGroupBy }, t));
    }

    if (viewType === TaskViewType.Schedule) {
      items.push(
        getCommandBarItemsScheduleLevel(scheduleLevel, { disabled: isLoading(), onSelectLevel: onSelectLevel }, t),
      );

      items.push(
        getCommandBarItemsAuditYear(
          auditYear,
          {
            disabled: isLoading(),
            onSelectYear: onSelectYear,
            auditDateSetting: auditDateSetting,
          },
          t as TFunction<string[]>,
        ),
      );
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appContext.isMobileView,
    auditDateSetting,
    auditYear,
    isLoading,
    kanbanGroupByField,
    onSelectYear,
    onSwitchView,
    props.hideViewType,
    scheduleLevel,
    viewType,
  ]);

  const onGroupBy = (field: 'state' | 'assignee') => {
    setKanbanGroupByField(field);
  };

  const onSelectLevel = (level: TaskViewScheduleLevel) => {
    setLocalStorageData(appContext, LocalStorageKeys.ScheduleLevel, level.toString());
    setScheduleLevel(level);
  };

  //
  // Task operations
  //
  const handleClose = () => {
    setSelectedTask(undefined);
  };

  const handleSave = (updatedTask: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      let cloneTaskList: Task[] = [];
      if (isNew) {
        cloneTaskList = [updatedTask, ...props.tasks];
        if (updatedTask.taskMasterId !== 0 && viewType === TaskViewType.Schedule) {
          //we also need to add the master task before the instance is shown in planning view so refresh
          loadData(viewType, getFilter(), memberFilter, periodStart, periodEnd);
        }
      } else {
        if (selectedTask && updatedTask.taskId === selectedTask.taskMasterId) {
          //SingleTask returned the series because the series was changed instead of the instance
          //in this case, refresh the data so we get an updated instance back from the API
          loadData(viewType, getFilter(), memberFilter, periodStart, periodEnd);
        } else {
          //replace the task in the array with the updated task
          props.tasks.forEach((task: Task) =>
            cloneTaskList.push(task.taskId === updatedTask.taskId ? updatedTask : task),
          );
          //when there is a new task created (case when back-end produces a new task in a recurring series) remove the selected task and add the new one
          if (selectedTask?.taskId !== updatedTask.taskId) {
            cloneTaskList = cloneTaskList.filter((task) => task.taskId !== selectedTask?.taskId);
            cloneTaskList = [updatedTask, ...cloneTaskList];
          }
        }
      }

      props.onUpdateTasks(cloneTaskList);
    }
  };

  const handleRemove = (removedTask: Task) => {
    const cloneTasksList: Task[] = props.tasks.filter(
      (task: Task) => task.taskId !== removedTask.taskId && task.taskMasterId !== removedTask.taskId,
    );
    props.onUpdateTasks(cloneTasksList);
  };

  const handleUpdate = (updatedTask: Task) => {
    //update the task in state
    const newTaskList = tasksDisplay.map((t) => (t.taskId === updatedTask.taskId ? updatedTask : t));
    setTasksDisplay(newTaskList);
  };

  const removeTask = (tasks: Task[], removedTask: Task): Task[] => {
    const cloneTasksList: Task[] = tasks.filter(
      (task: Task) => task.taskId !== removedTask.taskId && task.taskMasterId !== removedTask.taskId,
    );

    return cloneTasksList;
  };

  const onTaskClick = (task: Task) => {
    if (props.onTaskClick) {
      props.onTaskClick(task);
    } else {
      setSelectedTask(task);
    }
  };

  const onChangeSelection = (tasks: Task[]) => {
    setSelectedTasks(tasks);

    switch (viewType) {
      case TaskViewType.List:
        if (props.listProps.onChangeSelection) props.listProps.onChangeSelection(tasks);
        break;
      case TaskViewType.Schedule:
        if (props.scheduleProps.onChangeSelection) props.scheduleProps.onChangeSelection(tasks);
        break;
    }
  };

  //
  // Bulk operations
  //
  const onRemoveBulkTasks = async () => {
    if (isUpdating) {
      return;
    }

    let errCount: number = 0;
    let okCount: number = 0;
    let cloneTasksList: Task[] = [...props.tasks];

    try {
      setIsUpdating(true);
      appContext.showContentLoader();
      setShowDeleteTasks(false);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      for (let idx = 0; idx < selectedTasks.length; idx++) {
        const task = selectedTasks[idx];
        if (task.isInstance() && task.taskType === TaskTypes.Normal) {
          //normal task instances can only be removed by removing the series
          errCount++;
        } else {
          await apiRemoveTask(task, accessToken);
          cloneTasksList = removeTask(cloneTasksList, task);
          okCount++;
        }
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      props.onUpdateTasks(cloneTasksList);

      appContext.hideContentLoader();
      setIsUpdating(false);

      const newId = getRandomId();
      setSelectionResetKey(newId);

      setShowTaskDeleteError(errCount);
      setShowTaskDeleteSucces(okCount);
      setShowDeleteTasksResult(true);
    }
  };

  const executeBulkActions = async (actions: IBulkAction[]) => {
    try {
      setIsUpdating(true);
      setIsBulkModalOpen(false);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      for (let idx = 0; idx < actions.length; idx++) {
        const action = actions[idx];
        switch (action.actionType) {
          case BulkActionType.Tag:
            const actionTag = action as IBulkActionTag;

            for (let idx1 = 0; idx1 < actionTag.tagsToAdd.length; idx1++) {
              const tag = actionTag.tagsToAdd[idx1];
              for (let taskIdx = 0; taskIdx < selectedTasks.length; taskIdx++) {
                const task = selectedTasks[taskIdx];
                const canUpdate = hasUserDataPermission(
                  appContext,
                  [task.authSchemaId],
                  AuthSchemaLineOperation.Update,
                );
                if (canUpdateTaskField(task, canUpdate, TaskFieldTypes.Tags, appContext)) {
                  if (!task.tagIds) task.tagIds = [];
                  if (!task.tagIds.includes(tag.tagId)) {
                    task.tagIds?.push(tag.tagId);
                    await apiAddTagToTask(tag, task.taskId, accessToken);
                    handleUpdate(task);
                  }
                }
              }
            }

            for (let idx1 = 0; idx1 < actionTag.tagsToRemove.length; idx1++) {
              const tag = actionTag.tagsToRemove[idx1];
              for (let taskIdx = 0; taskIdx < selectedTasks.length; taskIdx++) {
                const task = selectedTasks[taskIdx];
                const canUpdate = hasUserDataPermission(
                  appContext,
                  [task.authSchemaId],
                  AuthSchemaLineOperation.Update,
                );
                if (canUpdateTaskField(task, canUpdate, TaskFieldTypes.Tags, appContext)) {
                  task.tagIds = task.tagIds?.filter((id) => id !== tag.tagId);
                  await apiRemoveTagFromTask(tag.tagId, task.taskId, accessToken);
                  handleUpdate(task);
                }
              }
            }
            break;
          case BulkActionType.Owner:
            const actionOwner = action as IBulkActionOwner;
            const tasksToAssignOwner: Task[] = [];

            for (let taskIdx = 0; taskIdx < selectedTasks.length; taskIdx++) {
              const task = selectedTasks[taskIdx];

              if (actionOwner.newOwner && task.ownerId !== actionOwner.newOwner?.id) {
                const canUpdate = hasUserDataPermission(
                  appContext,
                  [task.authSchemaId],
                  AuthSchemaLineOperation.Update,
                );
                if (canUpdateTaskField(task, canUpdate, TaskFieldTypes.Owner, appContext)) {
                  Owner.applyOwner(task, actionOwner.newOwner);
                  task.owner = actionOwner.newOwner.item;
                  tasksToAssignOwner.push(task);
                }
              }
            }

            await apiUpdateTasks(tasksToAssignOwner, false, accessToken, appContext.globalDataCache);
            tasksToAssignOwner.forEach((task) => handleUpdate(task));
            break;
          case BulkActionType.Assignee:
            const actionAssignee = action as IBulkActionAssignee;
            const tasksToUpdate: Task[] = [];

            for (let taskIdx = 0; taskIdx < selectedTasks.length; taskIdx++) {
              const task = selectedTasks[taskIdx];

              if (task.taskType === TaskTypes.Event && task.isSeries()) {
                //events cannot be assigned
                continue;
              }

              if (
                task.userId !== actionAssignee.newAssigneeId ||
                task.authSchemaId !== actionAssignee.newAuthSchemaId
              ) {
                const canUpdate = hasUserDataPermission(
                  appContext,
                  [task.authSchemaId],
                  AuthSchemaLineOperation.Update,
                );
                if (canUpdateTaskField(task, canUpdate, TaskFieldTypes.Assignee, appContext)) {
                  task.userId = actionAssignee.newAssigneeId;
                  task.authSchemaId = actionAssignee.newAuthSchemaId;
                  tasksToUpdate.push(task);
                }
              }
            }

            try {
              await apiUpdateTasks(tasksToUpdate, false, accessToken, appContext.globalDataCache);
              tasksToUpdate.forEach((task) => handleUpdate(task));
            } catch (err) {
              const outlookError = getOutlookErrorMsg(err as AppError, t);
              if (outlookError) {
                Logger.debug('BulkActionType.Assignee: ' + outlookError, err);
              }
            }

            break;
        }
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const getAllowedBulkActions = (): BulkActionType[] => {
    if (hasUserFeatureGenericManager(appContext)) {
      return [BulkActionType.Tag, BulkActionType.Assignee, BulkActionType.Owner];
    } else {
      return [BulkActionType.Tag];
    }
  };

  const getBulkActionState = async (actionType: BulkActionType): Promise<IBulkActionState | undefined> => {
    switch (actionType) {
      case BulkActionType.Tag:
        const ids = getUniqueTagIds(selectedTasks);
        const tags = appContext.globalDataCache.tags.getItemsForId(ids);
        tags.sort(sortOnTag);

        for (let idx = 0; idx < tags.length; idx++) {
          const tag = tags[idx];
          tag.usedCount = selectedTasks.filter((r) => r.tagIds?.some((id) => id === tag.tagId)).length;
        }

        const newState: IBulkActionStateTags = {
          tags: tags,
          checkboxState: tags.map((tag) => {
            return tag.usedCount === 0
              ? ICheckboxState.unchecked
              : tag.usedCount === selectedTasks.length
              ? ICheckboxState.checked
              : ICheckboxState.indetermined;
          }),
        };

        return newState;
      default:
        return undefined;
    }
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill>
      {isUpdating && <OverlayLoader text={t('translation:General.Notifications.Saving')} />}
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Stack horizontal wrap styles={globalStackStylesPaddingScene}>
            <Stack.Item grow>
              <CommandBar
                styles={{ root: { paddingLeft: 0 } }}
                items={getCommandBarItems()}
                farItems={getCommandBarFarItems()}
              ></CommandBar>
            </Stack.Item>
            {!props.hidePeriodSelector && viewType !== TaskViewType.Schedule && (
              <Stack.Item>
                <PeriodFilter onUpdatePeriod={updatePeriod} storageKey={LocalStorageKeys.TaskPeriodSelector} />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
        <Stack.Item styles={globalStackStylesPaddingScene}>
          <TaskViewFilters
            selectedFilter={getFilter()}
            tasks={props.tasks}
            onUpdateSelectedFilter={onUpdateFilter} //this happens also after first initialization of the filter
            onUpdateTasksDisplay={setTasksDisplay}
            selectedTasks={selectedTasks}
            onLoad={(filter) => {
              //This triggers on rule 2)
              loadData(viewType, filter, memberFilter, periodStart, periodEnd);
            }}
            onLoadWithMembers={(newMemberFilter) => {
              setMemberFilter(newMemberFilter);
            }}
            showGroupMemberFilter={props.showGroupMemberFilter}
            memberFilter={memberFilter}
            isLoadingMembership={isLoadingMemberships}
            onLoadMembership={loadGroupMembership}
            onUpdateMemberFilter={setMemberFilter}
            hideQuickFilter={props.hideQuickFilter}
            toggleFilterDialog={props.onUpdateFilter ? undefined : toggleFilterDialog}
            maxQuickFilters={props.onUpdateFilter ? 5 : 3}
            showFilterDialog={showFilterDialog}
            filterStorageKey={props.filterStorageKey}
            quickFilterContext={props.quickFilterContext}
          />
        </Stack.Item>
        {viewType === TaskViewType.Board && (
          <Stack.Item grow>
            <KanbanBoardTasks
              statusField={kanbanGroupByField}
              taskStates={props.boardProps.taskStates}
              tasks={tasksDisplay}
              allowColumnReorder={props.boardProps.allowColumnReorder}
              isLoading={isLoading()}
              onTaskClick={onTaskClick}
              onNewTask={onNewTaskBoard}
              allowNew={hasUserRolePermission(appContext, PermissionTypes.CreateTask)}
            />
          </Stack.Item>
        )}
        {viewType === TaskViewType.List && (
          <Stack.Item grow>
            <TaskList
              isLoading={isLoading()}
              hideColumns={props.listProps.hideColumns}
              tasks={tasksDisplay}
              filter={getFilter()}
              onTaskClick={onTaskClick}
              updateFilter={onUpdateFilter}
              cacheKeySort={props.listProps.cacheKeySort}
              selectionMode={props.listProps.selectionMode}
              onChangeSelection={onChangeSelection}
              showColumnSelector={showColumnSelector}
              hideColumnSelector={() => setShowColumnSelector(false)}
              selectionResetKey={selectionResetKey}
            />
          </Stack.Item>
        )}
        {viewType === TaskViewType.Schedule && (
          <Stack.Item grow>
            <TaskScheduleDetailsList
              level={scheduleLevel}
              year={auditYear}
              tasks={props.tasks}
              tasksDisplay={tasksDisplay}
              isUpdating={props.isUpdating}
              isLoading={isLoading()}
              onTaskClick={onTaskClick}
              onTaskEditRecurringPattern={props.scheduleProps.onTaskEditRecurringPattern}
              onTaskRecurringPatternUpdated={props.scheduleProps.onTaskRecurringPatternUpdated}
              hideContext={props.scheduleProps.hideContext}
              allowEditSeries={props.scheduleProps.allowEditSeries}
              filter={getFilter()}
              updateFilter={onUpdateFilter}
              selectionMode={props.scheduleProps.selectionMode}
              onChangeSelection={onChangeSelection}
              cacheKeySort={LocalStorageKeys.TaskScheduleSort}
              onUpdateTaskSeries={setSeriesTasks}
            />
          </Stack.Item>
        )}
        {selectedTask && (
          <SingleTask
            isOpen={selectedTask !== undefined}
            close={handleClose}
            task={selectedTask}
            onSave={handleSave}
            onRemove={handleRemove}
            onUpdate={handleUpdate}
            navigateExternal={props.navigateExternal}
          />
        )}
        <DialogConfirmDelete
          hidden={!showDeleteTasks}
          onYes={onRemoveBulkTasks}
          onNo={() => setShowDeleteTasks(false)}
          title={t('tasks:TabAllTasks.Bulk.Remove.Title')}
          subText={t('tasks:TabAllTasks.Bulk.Remove.SubText')}
        />
        <DialogOk
          hidden={!showDeleteTasksResult}
          onOk={() => setShowDeleteTasksResult(false)}
          title={t('tasks:TabAllTasks.Bulk.Remove.ResultTitle')}
          subText={t('tasks:TabAllTasks.Bulk.Remove.SuccesSubText', { count: showTaskDeleteSucces })}
          optionalJSX={
            showTaskDeleteError > 0 ? (
              <Text>{t('tasks:TabAllTasks.Bulk.Remove.ErrorSubText', { count: showTaskDeleteError })}</Text>
            ) : undefined
          }
        />
        {isBulkModalOpen && (
          <BulkActionsModal
            title={t('tasks:TabAllTasks.Bulk.Edit.Title')}
            isOpen={isBulkModalOpen}
            onClose={() => setIsBulkModalOpen(false)}
            actionTypes={getAllowedBulkActions()}
            itemCount={selectedTasks.length}
            noAuthCount={
              selectedTasks.filter(
                (s) =>
                  s.userId !== appContext.user.id &&
                  !hasUserDataPermission(appContext, [s.authSchemaId], AuthSchemaLineOperation.Update),
              ).length
            }
            onExecute={executeBulkActions}
            onGetState={getBulkActionState}
            disableRemoveOwner={true}
            disallowOwnerRoles={true}
          />
        )}
        <ReportRequest
          isOpen={reportRequest !== undefined}
          onClose={() => setReportRequest(undefined)}
          reportRequest={reportRequest}
        />
      </Stack>
    </Stack>
  );
};

export default TasksView;
