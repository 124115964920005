import { IPivotItemProps, Pivot, PivotItem, Text } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { getCurrentURLWithoutLastPart, GetCurrentURL } from 'utils/url';
import { globalPaddingScene, globalTextStylesBold } from 'globalStyles';

export interface IScenePivotItemProps {
  className?: string;
  key: string;
  text: string;
  url: string;
}

export interface IScenePivotProps {
  items: IScenePivotItemProps[];
  selectedKey: string | undefined;
  onSelectPivot?: (item: IScenePivotItemProps) => void;
}

export const getCurrentPivotKeyFromURL = (pivotItems: IScenePivotItemProps[]): string => {
  const url = GetCurrentURL(true);

  const pi = pivotItems.find((i) => i.url === url);
  if (pi) {
    return pi.key;
  } else {
    return '';
  }
};

const ScenePivot = (props: IScenePivotProps) => {
  const history = useHistory();

  const handleLinkClick = (item: PivotItem | undefined) => {
    if (item && item.props.itemKey) {
      const pivot = props.items.find((i) => i.key === item.props.itemKey);
      if (pivot) {
        if (props.onSelectPivot) props.onSelectPivot(pivot);
        const url: string = getCurrentURLWithoutLastPart() + pivot.url;
        history.push(url);
      }
    }
  };

  //remove the bold view when no pivot item is selected
  const renderItem = (
    itemProps?: IPivotItemProps,
    defaultRender?: (itemProps?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null => {
    return (
      <Text variant="medium" styles={itemProps?.itemKey === props.selectedKey ? globalTextStylesBold : undefined}>
        {itemProps?.headerText}
      </Text>
    );
  };

  //remove the blue underline when no pivot item is selected
  const getPivotStyles = () => {
    return !props.selectedKey
      ? {
          root: {
            paddingLeft: globalPaddingScene,
          },
          linkIsSelected: {
            selectors: {
              '&:before': { height: 0 },
            },
          },
        }
      : {
          root: {
            paddingLeft: globalPaddingScene,
          },
        };
  };

  return (
    <Pivot
      onLinkClick={handleLinkClick}
      headersOnly={true}
      selectedKey={props.selectedKey}
      styles={getPivotStyles()}
      overflowBehavior="menu"
    >
      {props.items.map((pivot) => {
        return (
          <PivotItem
            className={pivot.className}
            key={pivot.key}
            headerText={pivot.text}
            itemKey={pivot.key}
            onRenderItemLink={renderItem}
          />
        );
      })}
    </Pivot>
  );
};

export default ScenePivot;
