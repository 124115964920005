import { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { TFunction } from 'i18next';
import ReportDefinition, { ReportContext } from 'models/reports/ReportDefinition';
import getReportDefinitions from 'models/reports/ReportDefinitions';
import { ReportOutputFormat, ReportStorageBehavior } from 'models/dto/Reports/ReportRequestDTO';

//
// Context menu button
//
interface IReportContextMenuProps {
  onSelect: (report: ReportDefinition) => void;
  disabled: boolean | undefined;
}

const getReportsByContext = (context: ReportContext | ReportContext[], t: TFunction<string[]>): ReportDefinition[] => {
  const reports = getReportDefinitions(t as TFunction<string[]>);

  if (typeof context === 'object') {
    return reports.filter((r) => r.context.some((rc) => context.some((c) => c === rc)));
  } else {
    return reports.filter((r) => r.context.some((rc) => rc === context));
  }
};

export const getCommandBarItemsReportContextMenu = (
  context: ReportContext | ReportContext[],
  props: IReportContextMenuProps,
  t: TFunction<string[]>,
): IContextualMenuItem => {
  const reports = getReportsByContext(context, t);

  const outputFormatMenuProps: IContextualMenuProps = {
    items: reports.map((report) => {
      return {
        key: report.id.toString(),
        text: report.name,
        iconProps: { iconName: 'ReportDocument' },
        onClick: () => props.onSelect(report),
      };
    }),
  };

  return {
    key: 'report-context',
    disabled: props.disabled,
    text: t('reports:ContextMenu.Label'),
    iconProps: { iconName: 'ReportLibrary' },
    subMenuProps: outputFormatMenuProps,
  };
};

//
// Format button
//
interface IReportCommandBarOutputFormatProps {
  onSelect: (format: ReportOutputFormat) => void;
  disabled: boolean | undefined;
}

export const getCommandBarItemsReportOutputFormat = (
  sharePointEnabled: boolean,
  allowExcelExport: boolean,
  outputFormat: ReportOutputFormat,
  props: IReportCommandBarOutputFormatProps,
  t: TFunction<string[]>,
): IContextualMenuItem => {
  const getOutputFormatMenuProps = (sharePointEnabled: boolean): IContextualMenuProps => {
    const menuItems: IContextualMenuProps = {
      items: [
        {
          key: 'word',
          checked: outputFormat === ReportOutputFormat.Word,
          text: t('adminReports:Buttons.OutputFormat.Word'),
          iconProps: { iconName: 'WordDocument' },
          onClick: () => props.onSelect(ReportOutputFormat.Word),
        },
      ],
    };

    if (allowExcelExport) {
      menuItems.items.push({
        key: 'excel',
        checked: outputFormat === ReportOutputFormat.Excel,
        text: t('adminReports:Buttons.OutputFormat.Excel'),
        iconProps: { iconName: 'ExcelLogo' },
        onClick: () => props.onSelect(ReportOutputFormat.Excel),
      });
    }

    if (sharePointEnabled) {
      menuItems.items.push({
        key: 'pdf',
        checked: outputFormat === ReportOutputFormat.PDF,
        text: t('adminReports:Buttons.OutputFormat.PDF'),
        iconProps: { iconName: 'PDF' },
        onClick: () => props.onSelect(ReportOutputFormat.PDF),
      });
    }

    return menuItems;
  };

  const getMainText = (): string => {
    let subText: string = '';
    switch (outputFormat) {
      case ReportOutputFormat.Word:
        subText = t('adminReports:Buttons.OutputFormat.Word');
        break;
      case ReportOutputFormat.PDF:
        subText = t('adminReports:Buttons.OutputFormat.PDF');
        break;
      case ReportOutputFormat.Excel:
        subText = t('adminReports:Buttons.OutputFormat.Excel');
        break;
    }

    return t('adminReports:Buttons.OutputFormat.Label') + ' : ' + subText;
  };

  return {
    key: 'outputFormat',
    disabled: props.disabled,
    text: getMainText(),
    iconProps: { iconName: 'DownloadDocument' },
    subMenuProps: getOutputFormatMenuProps(sharePointEnabled),
  };
};

//
// Storage behavior button
//
interface IReportCommandBarStorageBehaviorProps {
  onSelect: (storageBehavior: ReportStorageBehavior) => void;
  disabled: boolean | undefined;
}

export const getCommandBarItemsReportStorageBehavior = (
  storageBehavior: ReportStorageBehavior,
  props: IReportCommandBarStorageBehaviorProps,
  t: TFunction<string[]>,
): IContextualMenuItem => {
  const storageBehaviorMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'download',
        checked: storageBehavior === ReportStorageBehavior.Download,
        text: t('adminReports:Buttons.StorageBehavior.Download'),
        iconProps: { iconName: 'Download' },
        onClick: () => props.onSelect(ReportStorageBehavior.Download),
      },
      {
        key: 'sharepoint',
        checked: storageBehavior === ReportStorageBehavior.SharePoint,
        text: t('adminReports:Buttons.StorageBehavior.SharePoint'),
        iconProps: { iconName: 'SharepointLogo' },
        onClick: () => props.onSelect(ReportStorageBehavior.SharePoint),
      },
    ],
  };

  const getMainText = (): string => {
    let subText: string = '';
    switch (storageBehavior) {
      case ReportStorageBehavior.Download:
        subText = t('adminReports:Buttons.StorageBehavior.Download');
        break;
      case ReportStorageBehavior.SharePoint:
        subText = t('adminReports:Buttons.StorageBehavior.SharePoint');
        break;
    }

    return t('adminReports:Buttons.StorageBehavior.Label') + ' : ' + subText;
  };

  return {
    key: 'storageBehavior',
    disabled: props.disabled,
    text: getMainText(),
    iconProps: { iconName: 'Export' },
    subMenuProps: storageBehaviorMenuProps,
  };
};
