import { useContext, useEffect, useState } from 'react';
import {
  CommandBar,
  DefaultButton,
  ICommandBarItemProps,
  Label,
  Link,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from '@fluentui/react';
import ReportRequestDTO, { ReportOutputFormat, ReportStorageBehavior } from 'models/dto/Reports/ReportRequestDTO';
import { globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import GenericModal from 'components/Dialogs/GenericModal';
import { onRenderLabelWithInfo } from 'globalFunctions';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { navigateToExternalUrl } from 'utils/url';
import {
  ReportAllowUserSettingOverride,
  ReportStorageList,
  ReportTemplateLink,
  SharepointReports,
} from 'models/setting';
import OverlayLoader from 'components/Loading/OverlayLoader';
import { apiCheckSharePointReportWriteAccess, apiGetReport } from 'services/Api/reportService';
import ResourceList from 'models/resourceList';
import { globalAppName } from 'globalConstants';
import { toLocaleDateNumeric } from 'utils/datetime';
import AppError from 'utils/appError';
import { getCommandBarItemsReportStorageBehavior, getCommandBarItemsReportOutputFormat } from './ReportContextMenu';
import { useHistory } from 'react-router-dom';
import WarningMessage from 'components/Notification/WarningMessage';
import { ReportType } from 'models/reports/ReportDefinition';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { apiGetLink } from 'services/Api/linkService';
import ReportOptions6 from './Options/ReportOptions6';

export interface IReportRequestOptions {
  reportRequest?: ReportRequestDTO;
  onUpdate: (reportRequest: ReportRequestDTO, isValid: boolean) => void;
}

interface IReportRequestProps {
  isOpen: boolean;
  isLoading?: boolean;
  reportRequest?: ReportRequestDTO;
  onClose: () => void;
  onGoToSettings?: () => void;
}

const ReportRequest = (props: IReportRequestProps) => {
  const { t } = useTranslation(['translation', 'adminReports']);
  const appContext = useContext(AppContext);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOptionsValid, setIsOptionsValid] = useState<boolean>(true);
  const [hasWriteAccess, setHasWriteAccess] = useState<boolean>(false);
  const [hasValidTemplate, setHasValidTemplate] = useState<boolean>(false);
  const [spReportsEnabled, setSpReportsEnabled] = useState<boolean>(false);
  const [currentReportRequest, setCurrentReportRequest] = useState<ReportRequestDTO | undefined>(props.reportRequest);
  const [reportList, setReportList] = useState<ResourceList | undefined>(undefined);
  const [reportUrl, setReportUrl] = useState<string | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    if (props.isOpen && props.reportRequest) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, props.reportRequest]);

  const loadData = async () => {
    try {
      setReportUrl(undefined);
      setIsLoading(true);

      //get the setting if SharePoint integration is enabled
      const spReportsEnabled = appContext.globalDataCache.settings.get(SharepointReports) as boolean;
      setSpReportsEnabled(spReportsEnabled);

      //get the setting for the default report list
      const listId = appContext.globalDataCache.settings.get(ReportStorageList) as number;
      let writeAccess: boolean = false;
      let validTemplate: boolean = false;

      if (spReportsEnabled && listId) {
        //check list
        await appContext.globalDataCache.lists.getItems();
        const list = appContext.globalDataCache.lists.get(listId);
        if (list && list.listId && list.spDriveId) {
          setReportList(list);
          //check whether the user is allowed to write to the SharePoint location. If not give warning and set default output to Word/Download
          let accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const err = await apiCheckSharePointReportWriteAccess(accessToken);
          if (!err) writeAccess = true;
          setHasWriteAccess(writeAccess);
          //check template link
          const linkId = appContext.globalDataCache.settings.get(ReportTemplateLink) as number;
          accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const link = await apiGetLink(linkId, accessToken, appContext.globalDataCache);
          if (link && link.listId === list.listId) {
            validTemplate = true;
            setHasValidTemplate(true);
          }
        } else {
          setReportList(undefined);
        }
      }

      if (props.reportRequest) {
        const newReportRequest = props.reportRequest.clone();

        //when the user has no write access, set the report settings to download/word
        if (!spReportsEnabled || !writeAccess || !validTemplate) {
          newReportRequest.storageBehavior = ReportStorageBehavior.Download;
          newReportRequest.outputFormat = ReportOutputFormat.Word;
        }

        //when the report does not support excel export, set the output format to word
        if (
          newReportRequest.outputFormat === ReportOutputFormat.Excel &&
          newReportRequest.reportDefinition?.disableExcelExport === true
        ) {
          newReportRequest.outputFormat = ReportOutputFormat.Word;
        }

        setCurrentReportRequest(newReportRequest);
      } else {
        setCurrentReportRequest(props.reportRequest);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const startReport = async () => {
    try {
      if (!currentReportRequest) return;
      setIsReportLoading(true);
      currentReportRequest.fileName = createFileName(currentReportRequest);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const report = await apiGetReport(currentReportRequest, accessToken);
      showReport(currentReportRequest, report);
    } catch (err) {
      const reportErr = err as AppError;
      const msg = getReportErrorMsg(reportErr);
      if (msg) {
        appContext.showNotification(msg, true);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsReportLoading(false);
    }
  };

  const getReportErrorMsg = (err: AppError): string | undefined => {
    if (!err || !err.debug) return undefined;

    if (err.debug.indexOf('accessDenied') >= 0) {
      return t('translation:Errors.AccessDenied.Message');
    } else if (
      err.debug.indexOf('itemNotFound') >= 0 ||
      err.debug.indexOf('templateLinkNotFound') >= 0 ||
      err.debug.indexOf('templateListNotFound') >= 0
    ) {
      return t('adminReports:Errors.TemplateNotFound');
    }

    return undefined;
  };

  const showReport = (reportDto: ReportRequestDTO, report: Blob | string | undefined) => {
    if (typeof report === 'string') {
      if (!report) {
        props.onClose();
        appContext.showNotification(t('translation:Errors.EmptyReport.Message'), true);
      } else {
        setReportUrl(report);
      }
    } else {
      if (!report || report.size === 0) {
        appContext.showNotification(t('translation:Errors.EmptyReport.Message'), true);
      } else {
        downloadBlob(report, reportDto.fileName ?? createFileName(reportDto));
      }
      props.onClose();
    }
  };

  const createFileName = (reportDto: ReportRequestDTO): string => {
    let ext: string;
    switch (reportDto.outputFormat) {
      case ReportOutputFormat.Word:
        ext = '.docx';
        break;
      case ReportOutputFormat.PDF:
        ext = '.pdf';
        break;
      case ReportOutputFormat.Excel:
        ext = '.xlsx';
        break;
    }

    let baseName = t('adminReports:BaseFileName', { appName: globalAppName });
    baseName += reportDto.reportDefinition?.name + '-';
    baseName += toLocaleDateNumeric(new Date());

    return baseName + ext;
  };

  const downloadBlob = (blob: Blob, fileName: string) => {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  };

  const getCommandBarFormatItems = (): ICommandBarItemProps[] => {
    if (!currentReportRequest) return [];
    const items: ICommandBarItemProps[] = [];
    const sharePointEnabled = getSharePointEnabled();

    items.push(
      getCommandBarItemsReportOutputFormat(
        sharePointEnabled,
        !(currentReportRequest.reportDefinition?.disableExcelExport ?? false),
        currentReportRequest.outputFormat,
        {
          disabled: !canChangeSettings(),
          onSelect: (outputFormat) => {
            const newReportRequest = currentReportRequest.clone();
            newReportRequest.outputFormat = outputFormat;
            setCurrentReportRequest(newReportRequest);
            setReportUrl(undefined);
          },
        },
        t as TFunction<string[]>,
      ),
    );

    if (sharePointEnabled) {
      items.push(
        getCommandBarItemsReportStorageBehavior(
          currentReportRequest.storageBehavior,
          {
            disabled: !canChangeSettings(),
            onSelect: (storageBehavior) => {
              const newReportRequest = currentReportRequest.clone();
              newReportRequest.storageBehavior = storageBehavior;
              setCurrentReportRequest(newReportRequest);
              setReportUrl(undefined);
            },
          },
          t as TFunction<string[]>,
        ),
      );
    }

    return items;
  };

  const getSharePointEnabled = (): boolean => {
    return (
      spReportsEnabled &&
      reportList !== undefined &&
      hasWriteAccess &&
      hasValidTemplate
    );
  };

  const canChangeSettings = (): boolean => {
    return appContext.globalDataCache.settings.get(ReportAllowUserSettingOverride) as boolean;
  };

  const openReportSettings = () => {
    if (props.onGoToSettings) {
      props.onGoToSettings();
    } else {
      history.push('/admin/reports#settings');
    }
  };

  const getFormatSettings = (): JSX.Element | null => {
    if (isLoading) return null;

    return (
      <Stack>
        <Label>{t('adminReports:Dialogs.Request.Settings')}</Label>
        <CommandBar styles={{ root: { padding: 0 } }} items={getCommandBarFormatItems()}></CommandBar>
      </Stack>
    );
  };

  const getLocationInfo = () => {
    if (isLoading || currentReportRequest?.storageBehavior !== ReportStorageBehavior.SharePoint || !getSharePointEnabled()) {
      return null;
    }

    return (
      <Stack>
        {onRenderLabelWithInfo(
          t('adminReports:Dialogs.Request.Location.Label'),
          t('adminReports:Dialogs.Request.Location.Info'),
        )}
        <Stack>
          {reportList && (
            <Link onClick={() => navigateToExternalUrl(reportList.webURL ?? '', '', '')}>{reportList?.name}</Link>
          )}
          {!reportList && hasUserFeatureGenericManager(appContext) && (
            <Link onClick={() => openReportSettings()}>{t('adminReports:Dialogs.Request.Location.NotSetRole')}</Link>
          )}
          {!reportList && !hasUserFeatureGenericManager(appContext) && (
            <Text>{t('adminReports:Dialogs.Request.Location.NotSet')}</Text>
          )}
        </Stack>
      </Stack>
    );
  };

  const getWriteAccessWarning = (): JSX.Element | null => {
    if (isLoading) return null;
    if (!spReportsEnabled || !reportList) return null;
    if (hasWriteAccess && hasValidTemplate) return null;

    return (
      <Stack>
        <WarningMessage message={t('adminReports:Dialogs.Request.NoWriteAccess')}>
          <Stack>
            <Link onClick={() => navigateToExternalUrl(reportList.webURL ?? '', '', '')}>{reportList?.name}</Link>
          </Stack>
        </WarningMessage>
      </Stack>
    );
  };

  const onUpdateOptions = (reportRequest: ReportRequestDTO, isValid: boolean) => {
    setCurrentReportRequest(reportRequest);
    setIsOptionsValid(isValid);
  };

  const getReportSpecificOptions = (): JSX.Element | null => {
    if (isLoading || props.isLoading) return null;
    switch (currentReportRequest?.reportDefinition?.id) {
      case ReportType.SOA_Controls:
        return <ReportOptions6 reportRequest={currentReportRequest} onUpdate={onUpdateOptions} />;
      default:
        return null;
    }
  };

  //
  // Main render
  //
  return (
    <GenericModal
      isOpen={props.isOpen}
      title={props.reportRequest?.reportDefinition?.name ?? ''}
      onClose={props.onClose}
      verticalGap={globalStackTokensGapSmall}
      width={'50vw'}
      minHeight={430}
      height={(props.reportRequest?.reportDefinition?.optionsHeight ?? 0) + 430}
      maxHeight={'90vh'}
      maxWidth={500}
      hideFooter={true}
    >
      {isReportLoading && <OverlayLoader text={t('adminReports:Dialogs.Request.ReportLoading')} />}
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text variant="small">{props.reportRequest?.reportDefinition?.description}</Text>
        </Stack.Item>
        <Separator />
        <Stack.Item grow>
          <Stack tokens={globalStackTokensGapSmall}>
            {isLoading && <Spinner size={SpinnerSize.large} />}
            {getReportSpecificOptions()}
            {getFormatSettings()}
            {getLocationInfo()}
            {getWriteAccessWarning()}
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Separator />
          <Stack horizontal horizontalAlign="end" tokens={globalStackTokensGapSmall}>
            {!reportUrl && (
              <PrimaryButton
                disabled={isReportLoading || isLoading || props.isLoading || !isOptionsValid}
                onClick={async () => {
                  await startReport();
                }}
                text={t('translation:General.Button.Create')}
                onRenderIcon={
                  !props.isLoading
                    ? undefined
                    : (props, defaultRender) => {
                        return <Spinner />;
                      }
                }
              />
            )}
            {reportUrl && !isReportLoading && !isLoading && (
              <Link underline onClick={() => navigateToExternalUrl(reportUrl, '', '')}>
                {t('adminReports:Dialogs.Request.OpenReportUrl')}
              </Link>
            )}
            <DefaultButton
              disabled={isReportLoading || isLoading}
              onClick={() => {
                props.onClose();
              }}
              text={t('translation:General.Button.Cancel')}
            ></DefaultButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </GenericModal>
  );
};

export default ReportRequest;
