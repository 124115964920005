import { TFunction } from 'i18next';
import ReportDefinition, { ReportContext } from './ReportDefinition';

const getReportDefinitions = (t: TFunction<string[]>): ReportDefinition[] => {
  const reports: ReportDefinition[] = [];
  let report: ReportDefinition;

  //Note: can't use a for-loop to add: translations must be fixed strings for testing

  report = new ReportDefinition(1, t('reports:Report1.Name'));
  report.description = t('reports:Report1.Description');
  report.icon = 'report-1.png';
  report.context = [ReportContext.Risks];
  reports.push(report);

  report = new ReportDefinition(2, t('reports:Report2.Name'));
  report.description = t('reports:Report2.Description');
  report.icon = 'report-2.png';
  report.context = [ReportContext.Risks];
  reports.push(report);

  report = new ReportDefinition(3, t('reports:Report3.Name'));
  report.description = t('reports:Report3.Description');
  report.icon = 'report-3.png';
  report.context = [ReportContext.Schedule];
  report.contextUrl = '/schedule/overview#report3';
  reports.push(report);

  report = new ReportDefinition(6, t('reports:Report6.Name'));
  report.optionsHeight = 100;
  report.disableExcelExport = true;
  report.description = t('reports:Report6.Description');
  report.icon = 'report-6.png';
  report.context = [ReportContext.Controls];
  reports.push(report);

  report = new ReportDefinition(8, t('reports:Report8.Name'));
  report.description = t('reports:Report8.Description');
  report.icon = 'report-8.png';
  report.context = [ReportContext.Controls];
  reports.push(report);

  report = new ReportDefinition(9, t('reports:Report9.Name'));
  report.description = t('reports:Report9.Description');
  report.icon = 'report-9.png';
  report.context = [ReportContext.Processes];
  reports.push(report);

  report = new ReportDefinition(10, t('reports:Report10.Name'));
  report.description = t('reports:Report10.Description');
  report.icon = 'report-10.png';
  report.context = [ReportContext.Assets];
  reports.push(report);

  report = new ReportDefinition(11, t('reports:Report11.Name'));
  report.description = t('reports:Report11.Description');
  report.icon = 'report-11.png';
  report.context = [ReportContext.Requirements];
  reports.push(report);

  report = new ReportDefinition(12, t('reports:Report12.Name'));
  report.description = t('reports:Report12.Description');
  report.icon = 'report-12.png';
  report.context = [ReportContext.Processes];
  reports.push(report);

  report = new ReportDefinition(13, t('reports:Report13.Name'));
  report.description = t('reports:Report13.Description');
  report.icon = 'report-13.png';
  report.context = [ReportContext.Objectives];
  reports.push(report);

  report = new ReportDefinition(14, t('reports:Report14.Name'));
  report.description = t('reports:Report14.Description');
  report.icon = 'report-14.png';
  report.context = [ReportContext.Assets];
  reports.push(report);

  report = new ReportDefinition(15, t('reports:Report15.Name'));
  report.description = t('reports:Report15.Description');
  report.icon = 'report-15.png';
  report.context = [ReportContext.Library];
  report.contextUrl = '/library/links#report15';
  reports.push(report);

  report = new ReportDefinition(16, t('reports:Report16.Name'));
  report.description = t('reports:Report16.Description');
  report.icon = 'report-16.png';
  report.context = [ReportContext.Tasks];
  reports.push(report);

  report = new ReportDefinition(17, t('reports:Report17.Name'));
  report.description = t('reports:Report17.Description');
  report.icon = 'report-17.png';
  report.context = [ReportContext.Risks];
  reports.push(report);

  report = new ReportDefinition(18, t('reports:Report18.Name'));
  report.description = t('reports:Report18.Description');
  report.icon = 'report-18.png';
  report.context = [ReportContext.Controls];
  reports.push(report);

  return reports;
};

export default getReportDefinitions;
