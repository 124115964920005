import { Fragment, useContext, useEffect, useState } from 'react';
import NormalTask from './TaskTypes/NormalTask';
import TemplateTask from './TaskTypes/TemplateTask';
import Task, { TaskTypes } from 'models/tasks/task';
import AppError from 'utils/appError';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';
import { apiCreateLink, apiGetLinksForTask } from 'services/Api/linkService';
import { apiRequest } from 'services/Auth/authConfig';
import {
  apiAddTask,
  apiRemoveTask,
  apiRemoveTaskEvent,
  apiUpdateTask,
  apiGetTaskRescheduleRange,
  apiGetWebLinkForEvent,
  apiUpdateTaskCompleted,
  apiGetTaskInstances,
  apiGetSingleTask,
  apiConvertTaskChecklistToForm,
  apiGetTaskRelations,
} from 'services/Api/taskService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DateRange from 'models/dateRange';
import Tag from 'models/tag';
import { apiCreateTag } from 'services/Api/tagService';
import { addDateTimeDays, addDateTimeMinutes, getDateTimeDiffMinute } from 'utils/datetime';
import { getOutlookErrorMsg } from 'services/Api/apiErrors';
import { navigateToExternalUrl } from 'utils/url';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import MonitoringTask from './TaskTypes/MonitoringTask';
import EventTask from './TaskTypes/EventTask';
import EventTemplateTask from './TaskTypes/EventTemplateTask';
import { TaskRelationship, TaskTask } from 'models/tasks/taskTask';
import KPIData from 'models/kpi/kpiData';
import { EntityTypes } from 'models/entity';
import TaskTypeFormElement from 'models/tasks/taskTypeFormElement';
import { newGuid } from 'utils/guid';
import { DialogOk } from 'components/Dialogs/DialogOk';
import ApprovalTask from './TaskTypes/SystemTaskTypes/ApprovalTask';
import { SystemTaskTypes } from 'models/tasks/taskType';
import ApprovalTemplateTask from './TaskTypes/SystemTaskTypes/ApprovalTemplateTask';
import RecurringPattern from 'models/recurringPattern';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import OverlayLoader from 'components/Loading/OverlayLoader';
import { hasTaskDeletePermission, hasTaskUpdatePermission } from './TaskAuthHelper';
import { getLocalStorageData, LocalStorageKeys } from 'utils/localstorage';
import { LearnMore } from 'components/Notification/Info';
import { globalKB_howToWorkWithForms } from 'globalConstants';
import { Stack, Text } from '@fluentui/react';
import { globalStackTokensGapSmall } from 'globalStyles';

interface ISingleTaskProps {
  isOpen: boolean; //Wherever possible, use stateVariable of type Task | undefined, and pass editTask!==undefined in isOpen
  year?: number; //When specified, updating a series task of type monitoring, the schedule (assignment and event) is also updated for instances in this audit year
  task: Task;
  disallowSave?: boolean; //when true, this component will not save the task to the database and only return the new/updated Task model
  disallowSchedule?: boolean;
  windowLevel?: number;
  navigateExternal?: boolean;
  formMode?: boolean;
  close: () => void;
  onSave: (task: Task, isNew: boolean, isCancelled: boolean) => void; //Should only do the action of saving to state and not closing the modal as well
  onBeforeSave?: (oldTask: Task, newTask: Task) => JSX.Element | null; //Can cancel the save action by returning false. Not called on cancellation or on new tasks
  onRemove: (task: Task) => void; //Should only do the action of removing and not closing the modal as well
  onUpdate: (task: Task) => void; //Should update the task in state, called for changes to the task which are immediately saved (not waiting for user to click Save)
}

export interface ISingleTaskTypeProps {
  onUpdateTask: (task: Task) => void; //called from the component per task type to update the task in the state here
  onUpdateOrgTask: (task: Task) => void; //called from the component per task type to update the task in the state here
  copyFromTemplate: (task: Task) => void;
  createFromTemplate: (task: Task) => void;
  onSave: (task: Task, schedule: boolean) => void;
  onRemove: () => void;
  onCancel: () => void;
  onCreateNewTaskTemplate: () => void;
  setIsActionPending: (actionPending: boolean) => void;
  createInOutlook: () => Promise<void>;
  removeFromOutlook: () => Promise<void>;
  loadRescheduleRange: () => Promise<void>;
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  addLinkToTaskState: (links: ResourceLink[], validateForm: boolean) => void;
  setLinkToTaskState: (links: ResourceLink[], validateForm: boolean) => void;
  removeLinkFromTaskState: (link: ResourceLink, validateForm: boolean) => void;
  onUpdateTaskForForm: (task: Task, validateForm: boolean) => void;
  navigateToEventURL: (task: Task) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  onUpdateKPIData: (data: KPIData) => void;
  onSetKPIData: (data: KPIData | KPIData[], instances?: Task[]) => void;
  onValidateForm: (errors: TaskTypeFormElement[]) => void;
  onBeforeSaveCallback: (callback: BeforeSaveCallback) => void;
  initData: (newTask: Task) => void;
  initPivot: () => void;
  reload: () => void;
  setCompactView: (newValue: boolean) => void;
  setFormMode: (newValue: boolean) => void;
  setSelectedPivot: (pivot: string) => void;
  onConvertChecklist?: () => void;
  isOpen: boolean;
  task: Task;
  orgTask: Task;
  disallowSchedule?: boolean;
  disallowSave?: boolean;
  rescheduleDateRange: DateRange | undefined;
  tags: Tag[];
  links: ResourceLink[];
  isActionPending: boolean;
  isLoading: boolean;
  windowLevel?: number;
  showFormErrors: string | undefined;
  selectedPivot: string | undefined;
  hasFormFeature: boolean;
  hasPAFeature: boolean;
  navigateExternal?: boolean;
  formMode?: boolean;
  compactView: boolean | undefined;
  canUpdate: boolean;
  canDelete: boolean;
}

export type BeforeSaveCallback = (task: Task) => Task | undefined;

const SingleTask = (props: ISingleTaskProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'task']);

  //state for the task is managed here to allow to switch to another task type
  const [task, setTask] = useState<Task>(props.task.clone());
  const [orgTask, setOrgTask] = useState<Task>(props.task.clone());
  const [isInitializing, setIsInitializing] = useState<boolean>(false); //this is set when this module is initializing
  const [isActionPending, setIsActionPending] = useState<boolean>(false); //this is set for smaller async calls
  const [isLoading, setIsLoading] = useState<boolean>(false); //this is set for loading/removing causes the 'progress overlay'
  const [rescheduleDateRange, setRescheduleDateRange] = useState<DateRange | undefined>(undefined);
  const [taskTags, setTaskTags] = useState<Tag[]>([]);
  const [taskLinks, setTaskLinks] = useState<ResourceLink[]>([]);
  const [beforeSaveJSX, setBeforeSaveJSX] = useState<JSX.Element | undefined>(undefined);
  const [showBeforeSaveDialog, setShowBeforeSaveDialog] = useState<boolean>(false);
  const [saveWithSchedule, setSaveWithSchedule] = useState<boolean>(false);
  const [followUpTask, setFollowUpTask] = useState<Task | undefined>(undefined);
  const [taskCopy, setTaskCopy] = useState<Task | undefined>(undefined);
  const [showFormErrors, setShowFormErrors] = useState<string | undefined>(undefined);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<TaskTypeFormElement[]>([]);
  const [showFormErrorDialog, setShowFormErrorDialog] = useState<boolean>(false);
  const [selectedPivot, setSelectedPivot] = useState<string | undefined>(undefined); //this state is synced with the forms
  const [beforeSaveCallback, setBeforeSaveCallback] = useState<BeforeSaveCallback | undefined>(undefined);
  const [hasFormFeature] = useState<boolean>(hasUserFeature(appContext, FeatureTypes.TaskForm));
  const [hasPAFeature] = useState<boolean>(hasUserFeature(appContext, FeatureTypes.Automation));
  const [canUpdate] = useState<boolean>(hasTaskUpdatePermission(props.task, appContext));
  const [canDelete] = useState<boolean>(hasTaskDeletePermission(props.task, appContext));
  const [compactView, setCompactView] = useState<boolean | undefined>(undefined);
  const [formMode, setFormMode] = useState<boolean | undefined>(props.formMode);
  const [showConvertChecklistDialog, setShowConvertChecklistDialog] = useState<boolean>(false);

  //
  // Effects
  //
  useEffect(() => {
    if (props.isOpen) {
      initData(props.task);
      const calcCompactView = initCompactView();
      initPivot(calcCompactView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task, props.isOpen]);

  useEffect(() => {
    if (beforeSaveJSX) {
      setShowBeforeSaveDialog(true);
    } else {
      setShowBeforeSaveDialog(false);
    }
  }, [beforeSaveJSX]);

  useEffect(() => {
    if (appContext.isMobileView) {
      setCompactView(true);
    }
  }, [appContext.isMobileView]);

  useEffect(() => {
    if (compactView !== undefined) {
      if (!compactView) {
        initPivot(compactView);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compactView]);

  //
  // Initialization
  //
  const initData = async (taskToInit: Task) => {
    try {
      setIsInitializing(true);
      //load tags
      await appContext.globalDataCache.tags.getItems();
      setTaskTags(appContext.globalDataCache.tags.getItemsForId(taskToInit.tagIds));
      //load lists
      await appContext.globalDataCache.lists.getItems();
      //load subtasks
      const taskWithSubTasks = await loadSubTasks(taskToInit);
      // load task relations
      const taskWithTaskRelations = await loadTaskRelations(taskWithSubTasks);

      //clone to state
      setOrgTask(taskWithTaskRelations.clone());
      setTask(taskWithTaskRelations.clone());
      //reset additional props
      setRescheduleDateRange(undefined);
      setFormErrors([]);
      setShowFormErrors(undefined);
      setIsLoading(false);
      setIsActionPending(false);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsInitializing(false);
    }
  };

  const initCompactView = (): boolean => {
    let calcCompactView = false;

    //first, read from storage
    const compactViewFromStorage = getLocalStorageData(appContext, LocalStorageKeys.TaskCompactView);
    if (compactViewFromStorage) {
      calcCompactView = compactViewFromStorage === '1';
    }

    //disable for series
    if (props.task.isSeries()) {
      calcCompactView = false;
    }

    //disable for new tasks
    if (props.task.taskId === -1) {
      calcCompactView = false;
    }

    //enable in mobile view
    if (appContext.isMobileView) {
      calcCompactView = true;
    }

    setCompactView(calcCompactView);

    return calcCompactView;
  };

  const initPivot = (newCompactView?: boolean | undefined) => {
    newCompactView ??= compactView;
    if (hasFormFeature && task.taskTypeId && task.taskTypeId > 0) {
      setSelectedPivot('TaskType');
    } else if (task.checkList.items.length > 0) {
      setSelectedPivot('Checklist');
    } else if (newCompactView === true) {
      setSelectedPivot('TaskDetails');
      setFormMode(false);
    } else {
      setSelectedPivot('Checklist');
    }
  };

  const loadSubTasks = async (task: Task): Promise<Task> => {
    try {
      //load subtasks for tasktypes that can hold subtasks
      //subtasks are never returned by the back-end
      if (
        task.taskId !== -1 &&
        task.isSeries() &&
        (task.taskType === TaskTypes.Event || task.taskType === TaskTypes.EventTemplate)
      ) {
        //load for existing tasks
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const subTasks = await apiGetTaskInstances(task.taskId, accessToken, appContext.globalDataCache);
        task.instances = subTasks;
        //provide a new Guid for each subtasks for unique identification
        task.instances.forEach((instance) => (instance.id = newGuid()));
      } else if (
        task.taskId === -1 &&
        task.isSeries() &&
        (task.taskType === TaskTypes.Event || task.taskType === TaskTypes.EventTemplate) &&
        task.templateId
      ) {
        //load for new tasks
        //the task must have the template id set
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const subTasks = await apiGetTaskInstances(task.templateId, accessToken, appContext.globalDataCache);
        task.instances = subTasks.map((subTask) => {
          const newSubTask = new Task().applyTemplate(subTask, appContext);
          newSubTask.id = newGuid();
          newSubTask.taskMasterId = task.taskId;
          newSubTask.taskStateId = subTask.taskStateId;
          newSubTask.creator = appContext.user; //def creator is current user
          newSubTask.creatorId = appContext.user.id; //def creator is current user

          return newSubTask;
        });
      }

      return task;
    } catch (err) {
      appContext.setError(err);

      return task;
    }
  };

  const loadTaskRelations = async (task: Task): Promise<Task> => {
    if (task.taskId === -1) return task;

    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const result = await apiGetTaskRelations(task.taskId, accessToken, appContext.globalDataCache);
      task.relations = result ? result : task.relations;
    } catch (err) {
      appContext.setError(err);
    }

    return task;
  };

  const reload = async (): Promise<void> => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const newTask = await apiGetSingleTask(task.taskId, false, accessToken, appContext.globalDataCache);
      if (newTask && newTask.tasks.length === 1) {
        initData(newTask.tasks[0]);
      } else {
        throw new Error('Error reloading task');
      }
    } catch (err) {
      const appError = AppError.fromApiError(err);
      appContext.showNotification(appError.message, true);
    }
  };

  //
  // Notification
  //
  const showNotification = (msg: string, isError: boolean = false) => {
    if (isError) {
      toast.error(msg);
    } else {
      toast(msg);
    }
  };

  //
  // Link functions
  //
  const createNewLinksAndSaveToTask = async (task: Task): Promise<Task> => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const newLinks = task.resourceLinkIds ? task.resourceLinkIds.slice(0) : [];

      //first create all new links and save the newly created Id into the array
      for (let i = 0; i < taskLinks.length; i++) {
        const rt = taskLinks[i];
        if (rt.linkId < 0) {
          const createdLink = await createLink(rt, accessToken);
          if (createdLink) {
            newLinks.push(createdLink.linkId);
          } else {
            throw new AppError('createNewLinksAndSaveToTask: Could not create link', 'couldNotCreateLink');
          }
        }
      }

      //save the modified link id's to the main task model
      task.resourceLinkIds = newLinks.filter((id) => id > 0);

      //Now do the same for new links created as attachments on KPI data
      task = await createNewLinksAndSaveToTaskKPIData(task);

      return task;
    } catch (err) {
      if (err instanceof AppError && err.code === 'couldNotCreateLink') {
        appContext.showNotification(t('library:LinkComponents.ErrorMessageAdd'));
      } else {
        appContext.setError(err);
      }

      return task;
    }
  };

  const createNewLinksAndSaveToTaskKPIData = async (task: Task): Promise<Task> => {
    try {
      if (!task.kpiData || task.kpiData.length === 0) return task;

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      //create all new links and save the newly created Id into the array
      for (let i = 0; i < task.kpiData.length; i++) {
        const data = task.kpiData[i];
        const newLinks = data.contexts.filter((c) => c.entityType === EntityTypes.KPILink && c.entityId < 0);
        for (let j = 0; j < newLinks.length; j++) {
          const newLink = newLinks[j];
          const attachment = data.kpi?.attachments?.find((a) => a.linkId === newLink.entityId);
          if (!attachment || attachment.linkId > 0)
            throw new AppError('createNewLinksAndSaveToTaskKPIData: Attachment not found for link data context');
          const createdLink = await createLink(attachment, accessToken);
          if (createdLink) {
            newLink.entityId = createdLink.linkId;
            attachment.linkId = createdLink.linkId;
          } else {
            throw new AppError('createNewLinksAndSaveToTaskKPIData: Could not create link');
          }
        }
      }

      return task;
    } catch (err) {
      appContext.setError(err);

      return task;
    }
  };

  const addLinkToTaskState = async (items: ResourceLink[], validateForm: boolean) => {
    const newTaskLinks = taskLinks.slice(0);
    newTaskLinks.push(...items);
    setTaskLinks(newTaskLinks);
    const newTask = task.clone();
    newTask.resourceLinkIds = newTaskLinks.map((t) => t.linkId);
    setTask(newTask);
    if (showFormErrors && validateForm) {
      setShowFormErrors(newGuid());
    }
  };

  const setLinkToTaskState = async (items: ResourceLink[], validateForm: boolean) => {
    setTaskLinks(items);
    const newTask = task.clone();
    newTask.resourceLinkIds = items.map((t) => t.linkId);
    setTask(newTask);
    if (showFormErrors && validateForm) {
      setShowFormErrors(newGuid());
    }
  };

  const removeLinkFromTaskState = async (item: ResourceLink, validateForm: boolean) => {
    const newTaskLinks = taskLinks.slice(0).filter((_tag) => _tag.linkId !== item.linkId);
    setTaskLinks(newTaskLinks);
    const newTask = task.clone();
    newTask.resourceLinkIds = newTaskLinks.map((t) => t.linkId);
    setTask(newTask);
    if (showFormErrors && validateForm) {
      setShowFormErrors(newGuid());
    }
  };

  const createLink = async (item: ResourceLink, accessToken: string): Promise<ResourceLink | undefined> => {
    try {
      const newLink = await apiCreateLink(item, accessToken, appContext.globalDataCache);

      return newLink;
    } catch {
      return undefined;
    }
  };

  //
  // Tag functions
  //
  const createNewTagsAndSaveToTask = async (task: Task): Promise<Task> => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let newTaskTags = [...taskTags];

      //first create all new tags and save the newly created Id into the array
      for (let i = 0; i < newTaskTags.length; i++) {
        const rt = newTaskTags[i];
        if (rt.tagId < 0) {
          const createdTag = await createTag(rt, accessToken);
          if (createdTag) {
            newTaskTags.push(createdTag);
          }
        }
      }

      //filter the temp tags with Id < 0
      newTaskTags = newTaskTags.filter((t) => t.tagId > 0);

      //save the modified tags to the main task model
      task.tagIds = newTaskTags.map((t) => t.tagId);

      return task;
    } catch (err) {
      appContext.setError(err);

      return task;
    }
  };

  const addTagToTaskState = async (item: Tag) => {
    const newTaskTags = taskTags.slice(0);
    newTaskTags.push(item);
    setTaskTags(newTaskTags);
    const newTask = task.clone();
    newTask.tagIds = newTaskTags.map((t) => t.tagId);
    setTask(newTask);
  };

  const removeTagFromTaskState = async (item: Tag) => {
    const newTaskTags = taskTags.slice(0).filter((_tag) => _tag.tagId !== item.tagId);
    setTaskTags(newTaskTags);
    const newTask = task.clone();
    newTask.tagIds = newTaskTags.map((t) => t.tagId);
    setTask(newTask);
  };

  const createTag = async (item: Tag, accessToken: string): Promise<Tag | undefined> => {
    try {
      const newTag = await apiCreateTag(item, accessToken);
      appContext.globalDataCache.tags.add(newTag);

      return newTag;
    } catch (err) {
      appContext.setError(err);

      return undefined;
    }
  };

  //
  // Templates
  //
  const onCreateNewTaskTemplate = async () => {
    try {
      setIsActionPending(true);

      //clone the current task
      let newTemplate = task.clone();

      //set props
      newTemplate.taskMasterId = 0; //template is always a series
      newTemplate.taskId = 0; //to create a new task
      newTemplate.approved = undefined; //not approved
      newTemplate.completed = undefined; //not completed
      newTemplate.taskStateId = newTemplate.getFirstState(); //state is active
      newTemplate.eventId = undefined; //template cannot be scheduled
      newTemplate.hidden = false; //never hide a template
      newTemplate.recurringPattern = new RecurringPattern(); //reset to not active
      newTemplate.userId = undefined; //no user assignment
      newTemplate.user = undefined; //no user assignment
      newTemplate.owner = appContext.user; //def owner is current user
      newTemplate.ownerId = appContext.user.id; //def owner is current user
      newTemplate.creator = appContext.user; //def creator is current user
      newTemplate.creatorId = appContext.user.id; //def creator is current user
      newTemplate.templateId = undefined; //template itself is not a template
      newTemplate.webhookStatus = undefined; //no linked webhooks
      newTemplate.relations = undefined; //no relations

      if (task.taskType === TaskTypes.Event || task.taskType === TaskTypes.EventTemplate) {
        newTemplate.taskType = TaskTypes.EventTemplate;
      } else {
        newTemplate.taskType = TaskTypes.Template;
      }

      //create new tags and links
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      newTemplate = await createNewTagsAndSaveToTask(newTemplate);
      newTemplate = await createNewLinksAndSaveToTask(newTemplate);

      //add the task
      const templateTask = await apiAddTask(newTemplate, false, accessToken, appContext.globalDataCache);

      //add to global data cache
      appContext.globalDataCache.templates.add(templateTask);

      showNotification(t('task:Template.SuccessToast', { name: newTemplate.name }));
    } catch (err) {
      showNotification(t('task:Template.ErrorToast', { err: (err as AppError).message }), true);
    } finally {
      setIsActionPending(false);
    }
  };

  const createFromTemplate = async (template: Task) => {
    const newRelation: TaskTask = new TaskTask(task.taskId, -1, TaskRelationship.FollowUp);
    newRelation.taskFrom = task;

    const output = new Task().applyTemplate(template, appContext);
    output.taskStates = appContext.globalDataCache.taskStates.items;
    output.taskStateId = output.getFirstState();
    output.relations = [newRelation];
    output.creator = appContext.user; //def creator is current user
    output.creatorId = appContext.user.id; //def creator is current user

    openSecondTaskModal(output, true);
  };

  const copyFromTemplate = async (template: Task) => {
    const output = task.applyTemplate(template, appContext);
    output.creator = appContext.user; //def creator is current user
    output.creatorId = appContext.user.id; //def creator is current user

    const accessToken = await appContext.getAccessToken(apiRequest.scopes);

    //Load library items from the template
    const links = await apiGetLinksForTask(template.taskId, false, accessToken, appContext.globalDataCache);
    const newLinks: ResourceLink[] = [];

    for (const link of links) {
      newLinks.push(link);
    }

    setTaskLinks(newLinks);

    //set new tags from id's that were already copied from the template
    const newTaskTags = appContext.globalDataCache.tags.getItemsForId(output.tagIds);
    setTaskTags(newTaskTags);

    //update the task and switch to the new task type
    setTask(output);

    //switch to the form tab
    if (output.taskTypeId && output.taskTypeId > 0) {
      setSelectedPivot('TaskType');
    } else {
      setSelectedPivot('Checklist');
    }
  };

  const onConvertChecklist = async () => {
    try {
      setShowConvertChecklistDialog(false);

      //save the current task
      const savedTask = await onSave(task, false, true);
      if (!savedTask) return;

      setIsLoading(true);
      setIsActionPending(true);

      //convert on the back-end
      const trans_FormSuffix = t('task:CheckList.Conversion.FormSuffix');
      const trans_KPISuffix = t('task:CheckList.Conversion.KPISuffix');

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const result = await apiConvertTaskChecklistToForm(
        savedTask.taskId,
        trans_FormSuffix,
        trans_KPISuffix,
        accessToken,
      );

      if (result?.taskType) {
        //add new task type to global cache
        if (!appContext.globalDataCache.taskTypes.has(result.taskType.taskTypeId)) {
          appContext.globalDataCache.taskTypes.add(result.taskType);
        }
        //update original task
        const newTask = savedTask.clone();
        newTask.taskTypeId = result.taskType.taskTypeId;
        setOrgTask(newTask);
        //update current task
        setTask(newTask.clone());
        //update parent
        props.onSave(newTask, false, false);
      } else {
        appContext.showNotification(t('translation:General.Notifications.NotAvailable'), true);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
      setIsActionPending(false);
    }
  };

  //
  // Task functions
  //
  const loadRescheduleRange = async () => {
    try {
      setIsActionPending(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const range = await apiGetTaskRescheduleRange(task.taskId, accessToken);
      setRescheduleDateRange(range);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsActionPending(false);
    }
  };

  const onValidateForm = (errors: TaskTypeFormElement[]) => {
    setFormErrors(errors);

    //when there are no errors, call save again
    if (errors.length === 0 && saveInProgress) {
      onSave(task, saveWithSchedule);
    } else {
      if (task.isCompleted()) {
        //revert state
        const newTask = task.clone();
        newTask.taskStateId = props.task.taskStateId;
        newTask.completed = undefined;
        if (newTask.isCompleted()) {
          newTask.taskStateId = props.task.getFirstState();
        }
        setTask(newTask);
      }
      if (saveInProgress) {
        //show message to user that the form has errors and show the form
        setShowFormErrorDialog(true);
        setSelectedPivot('TaskType');
      }
    }

    setSaveInProgress(false);
  };

  const onSave = async (task: Task, schedule: boolean, dontClose: boolean = false): Promise<Task | undefined> => {
    try {
      setIsLoading(true);

      //call the onBeforeSave callback
      //specialized task handlers can modify the task before save
      //cancel on returning undefined
      if (beforeSaveCallback) {
        const newTask = beforeSaveCallback(task);
        if (newTask) {
          task = newTask;
        } else {
          return undefined;
        }
      }

      //when this task has a form, validate it
      if (task.isCompleted() && hasFormFeature && task.taskTypeId && (!showFormErrors || formErrors.length > 0)) {
        setSaveInProgress(true);
        setSaveWithSchedule(schedule);
        setShowFormErrors(newGuid()); //when this changes, onValidateForm callback is executed

        return undefined;
      }

      //first create any new tags and update the id's of the tags accordingly in the task
      let taskToSave = await createNewTagsAndSaveToTask(task);

      //then, create any new links and update the id's of the links accordingly in the task
      taskToSave = await createNewLinksAndSaveToTask(taskToSave);

      if (!props.disallowSave) {
        if (taskToSave.taskId === -1) {
          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const newTask = await apiAddTask(taskToSave, schedule, accessToken, appContext.globalDataCache, props.year);
          props.onSave(newTask, true, false);
          taskToSave = newTask;
        } else {
          if (props.onBeforeSave && !beforeSaveJSX) {
            const element = props.onBeforeSave(props.task, taskToSave);
            if (element) {
              //setting this will trigger the dialog to ask the user additional stuff
              //the dialog will call onSave again after confirmation or cancel the save operation
              setSaveWithSchedule(schedule);
              setBeforeSaveJSX(element);

              return undefined;
            }
          } else {
            setBeforeSaveJSX(undefined);
            setSaveWithSchedule(false);
          }

          const accessToken = await appContext.getAccessToken(apiRequest.scopes);
          const updatedTask = await apiUpdateTask(
            taskToSave,
            schedule,
            accessToken,
            appContext.globalDataCache,
            props.year,
          );

          if (updatedTask) {
            props.onSave(updatedTask, false, false);
            taskToSave = updatedTask;
          }
        }

        if (schedule) {
          showNotification(t('task:Outlook.OutlookScheduledToast'));
        }
      } else {
        //do not save to the back-end
        props.onSave(taskToSave, taskToSave.taskId === -1, false);
      }

      if (!dontClose) {
        props.close();
      }

      return taskToSave;
    } catch (err) {
      let toastShown: boolean = false;
      if (schedule || task.eventId) {
        const outlookError = getOutlookErrorMsg(err as AppError, t);
        if (outlookError) {
          showNotification(t('task:Outlook.OutlookFailureToast', { error: outlookError }), true);
          toastShown = true;
        }
      }

      if (!toastShown) appContext.setError(err);

      return task;
    } finally {
      setIsLoading(false);
    }
  };

  const onRemove = async () => {
    try {
      if (task.taskId !== -1) {
        setIsLoading(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        await apiRemoveTask(task, accessToken);
        props.onRemove(task);

        //set the original task, otherwise, when the same task is opened again, the cancelled changes are stil visible
        setTask(props.task);
        setTaskTags([]);
        setTaskLinks([]);
        setRescheduleDateRange(undefined);

        props.close();
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    props.onSave(props.task, task.taskId === -1, true);

    //set the original task, otherwise, when the same task is opened again, the cancelled changes are still visible
    setTask(props.task);
    setTaskTags([]);
    setTaskLinks([]);
    setRescheduleDateRange(undefined);

    props.close();
  };

  const createInOutlook = async (): Promise<void> => {
    try {
      await onSave(task, true);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const removeFromOutlook = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      if (task.taskType === TaskTypes.Event && task.isSeries() && task.instances) {
        //remove all schedules from subtasks
        for (let idx = 0; idx < task.instances.length; idx++) {
          const instance = task.instances[idx];
          await apiRemoveTaskEvent(instance, accessToken);
          instance.eventId = undefined;
        }

        setTask(task.clone());
      } else {
        //remove schedule from current task
        await apiRemoveTaskEvent(task, accessToken);

        const newTask = task.clone();
        newTask.eventId = undefined;
        setTask(newTask);

        const newOrgTask = orgTask.clone();
        newOrgTask.eventId = undefined;
        setOrgTask(newOrgTask);

        props.onUpdate(newTask);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCompletionDate = async (completionDate: Date) => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      //clone the original task with the start date that was not changed yet
      //the back-end checks whether the new completed date is not before the original start date
      const updatedOrgTask = orgTask.clone();
      updatedOrgTask.completed = completionDate;
      await apiUpdateTaskCompleted(updatedOrgTask, accessToken);
      setOrgTask(updatedOrgTask);
      //also update the current (changed) task
      const updatedTask = task.clone();
      updatedTask.completed = completionDate;
      setTask(updatedTask);
      props.onUpdate(updatedTask);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (updatedTask: Task): Promise<void> => {
    if (updatedTask.taskId !== -1 && updatedTask.taskId !== task.taskId) {
      //task has changed: load tags
      setTaskTags(appContext.globalDataCache.tags.getItemsForId(updatedTask.tagIds));
    }
    setTask(updatedTask);
  };

  const onSaveAsNewTask = async () => {
    const newBaseTask = task.clone();
    newBaseTask.taskId = -1; //new tasks have task Id set to -1
    newBaseTask.eventId = undefined; //new tasks are not created in Outlook yet
    newBaseTask.relations = undefined; //new tasks do not have relations

    if (newBaseTask.taskType === TaskTypes.Normal) {
      //new task must be loose from its parent: in other words: cannot create an instance of the same parent
      //this is because normal tasks that are an instance or part of a series that have a distinct pattern. Any exceptions would break that
      newBaseTask.taskMasterId = undefined;
    }

    newBaseTask.name = t('task:CopyOf') + newBaseTask.name;

    //the new startdate is the current date
    //apply the start time from the task being copied
    //recalculate end-time and deadline based on defaults
    const sd = newBaseTask.startDateTime;
    const cd = new Date();
    const duration = getDateTimeDiffMinute(newBaseTask.endDateTime, sd);
    newBaseTask.startDateTime = new Date(
      cd.getFullYear(),
      cd.getMonth(),
      cd.getDate(),
      sd.getHours(),
      sd.getMinutes(),
      0,
    );
    newBaseTask.endDateTime = addDateTimeMinutes(newBaseTask.startDateTime, duration);
    newBaseTask.deadline = addDateTimeDays(newBaseTask.endDateTime, 14);
    if (newBaseTask.recurringPattern) {
      newBaseTask.recurringPattern.startDate = newBaseTask.startDateTime;
    }

    //set state to 'first' state when the task being copied was completed
    newBaseTask.completed = undefined;
    if (newBaseTask.taskStateId === newBaseTask.getCompletedState()) {
      newBaseTask.taskStateId = newBaseTask.getFirstState();
    }

    //copy document links. Load them from the task being copied
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);
    const links = await apiGetLinksForTask(task.taskId, false, accessToken, appContext.globalDataCache);
    setTaskLinks(links);

    //update the task in state
    openSecondTaskModal(newBaseTask, false);
  };

  const navigateToEventURL = async (task: Task) => {
    try {
      setIsActionPending(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const webUrl = await apiGetWebLinkForEvent(task, accessToken);

      if (webUrl) {
        navigateToExternalUrl(webUrl, '', '');
      } else {
        showNotification(t('task:Outlook.OpenInOutlookError'));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsActionPending(false);
    }
  };

  const getDisallowSchedule = (): boolean => {
    return props.disallowSchedule || (task.taskType === TaskTypes.Monitoring && task.isSeries() && !props.year);
  };

  const onUpdateKPIData = (data: KPIData) => {
    const newTask = task.clone();
    if (!newTask.kpiData) newTask.kpiData = [];
    //a form can only contain unique KPI's so we can find on criterium kpiId only
    const dataIdx = newTask.kpiData.findIndex((k) => k.kpiId === data.kpiId);
    if (dataIdx >= 0) {
      newTask.kpiData[dataIdx] = data.clone();
    } else {
      newTask.kpiData.push(data.clone());
    }

    //update state
    setTask(newTask);

    if (showFormErrors) {
      setShowFormErrors(newGuid());
    }
  };

  const onSetKPIData = (data: KPIData | KPIData[], instances?: Task[]) => {
    //set the KPI data that is loaded for the form
    const newTask = task.clone();
    if (!newTask.kpiData) newTask.kpiData = [];

    if (Array.isArray(data)) {
      //add or update data records
      const dataToSet = data as KPIData[];
      for (let idx = 0; idx < dataToSet.length; idx++) {
        const item = dataToSet[idx];
        const dataIdx = newTask.kpiData.findIndex((k) => k.kpiId === item.kpiId);
        if (dataIdx >= 0) {
          newTask.kpiData[dataIdx] = item.clone();
        } else {
          newTask.kpiData.push(item.clone());
        }
      }
    } else {
      //a form can only contain unique KPI's so we can find on criterium kpiId only
      //add or update the single data record
      const dataIdx = newTask.kpiData.findIndex((k) => k.kpiId === data.kpiId);
      if (dataIdx >= 0) {
        newTask.kpiData[dataIdx] = data.clone();
      } else {
        newTask.kpiData.push(data.clone());
      }
    }

    //update instances
    if (instances && instances.length > 0) {
      if (!newTask.instances) newTask.instances = [];
      for (let idx = 0; idx < instances.length; idx++) {
        const newInstance = instances[idx];
        const currentInstanceIdx = newTask.instances?.findIndex((t) => t.id === newInstance.id);
        if (currentInstanceIdx !== undefined && currentInstanceIdx >= 0) {
          newTask.instances[currentInstanceIdx] = newInstance;
        }
      }
    }

    //update state
    setTask(newTask);

    //set the KPI data also on the original task so that these are the same
    const newOrgTask = orgTask.clone();
    newOrgTask.kpiData = newTask.kpiData.map((d) => d.clone());
    setOrgTask(newOrgTask);
  };

  const onUpdateTaskForForm = (task: Task, validateForm: boolean) => {
    setTask(task);
    if (showFormErrors && validateForm) {
      setShowFormErrors(newGuid());
    }
  };

  const onSaveSecondTaskModal = (savedTask: Task, isNew?: boolean, isCanceled?: boolean) => {
    if (taskCopy || task.taskId === -1 || isCanceled) {
      closeSecondTaskModal();

      return;
    }

    // Add new TaskTask to the task (and original task!) as a relation has been created and added to the database.
    // If we do not do this, it will get deleted on save.
    //
    const newTaskRelation: TaskTask = new TaskTask(props.task.taskId, savedTask.taskId, TaskRelationship.FollowUp);
    newTaskRelation.taskTo = savedTask;
    props.task.relations = props.task.relations ? [...props.task.relations, newTaskRelation] : [newTaskRelation];
    setTask(props.task.clone());
    setOrgTask(props.task.clone());

    closeSecondTaskModal();
  };

  // There is currently two ways of opening a second task modal:
  // - Follow-up task
  // - Copy task
  // Both require different behavior when saving the task or closing the modal.
  // So we need to keep track of which type of modal is open.
  const openSecondTaskModal = (task: Task, followUp: boolean) => {
    if (followUp) {
      setFollowUpTask(task);
    } else {
      setTaskCopy(task);
    }
  };

  // Only one additional task modal can be open at a time, so we can safely set both to undefined.
  const closeSecondTaskModal = () => {
    setFollowUpTask(undefined);
    setTaskCopy(undefined);
  };

  //
  // Render helpers
  //
  const getBeforeSaveDialog = (): JSX.Element => {
    return (
      <DialogYesNo
        hidden={!showBeforeSaveDialog}
        onNo={() => {
          setBeforeSaveJSX(undefined);
          setSaveWithSchedule(false);
          setShowBeforeSaveDialog(false);
        }}
        onYes={() => onSave(task, saveWithSchedule)}
        title={t('task:OnBeforeSaveDialog.Title')}
        subText={t('schedule:Dialogs.AskBeforeGenerateSchedule.SubTitle')}
      >
        {beforeSaveJSX}
      </DialogYesNo>
    );
  };

  const getAdditionalTaskModal = (): JSX.Element | null => {
    // Only one or neither should be set at all times.
    const task = followUpTask ?? taskCopy;
    if (!task) return null;

    return (
      <SingleTask
        isOpen={task !== undefined}
        close={closeSecondTaskModal}
        task={task}
        onSave={onSaveSecondTaskModal}
        onRemove={closeSecondTaskModal}
        onUpdate={closeSecondTaskModal}
        year={undefined}
        disallowSchedule={false}
        windowLevel={(props.windowLevel ?? 0) + 1}
        navigateExternal={props.navigateExternal}
      />
    );
  };

  const getShowFormErrorDialog = (): JSX.Element => {
    return (
      <DialogOk
        hidden={!showFormErrorDialog}
        onOk={() => {
          setShowFormErrorDialog(false);
        }}
        title={t('task:ShowFormErrorsDialog.Title')}
        subText={t('task:ShowFormErrorsDialog.SubText', {
          name: appContext.globalDataCache.taskTypes.get(task.taskTypeId).name,
          count: formErrors.length,
        })}
      />
    );
  };

  const getConvertChecklistDialog = (): JSX.Element | null => {
    if (!showConvertChecklistDialog) return null;

    return (
      <DialogYesNo
        hidden={!showConvertChecklistDialog}
        onYes={() => {
          onConvertChecklist();
        }}
        onNo={() => {
          setShowConvertChecklistDialog(false);
        }}
        title={t('task:ConvertChecklistDialog.Title')}
        subText={t('task:ConvertChecklistDialog.SubText')}
        optionalJSXGrow={true}
        optionalJSX={
          <Stack tokens={globalStackTokensGapSmall}>
            {t('task:ConvertChecklistDialog.Benefits')}
            <ul>
              <li>{t('task:ConvertChecklistDialog.Item1')}</li>
              <li>{t('task:ConvertChecklistDialog.Item2')}</li>
              <li>{t('task:ConvertChecklistDialog.Item3')}</li>
            </ul>
            <Stack.Item>
              <LearnMore learnMoreLink={globalKB_howToWorkWithForms} />
            </Stack.Item>
            <Stack.Item>
              <Text>{t('task:ConvertChecklistDialog.Proceed')}</Text>
            </Stack.Item>
          </Stack>
        }
      />
    );
  };

  const onSetBeforeSaveCallback = (callback: BeforeSaveCallback) => {
    //to store a function in state, wrap it in an argumentless function
    setBeforeSaveCallback(() => callback);
  };

  //
  // Main render
  //
  if (isInitializing) {
    return <OverlayLoader text={t('translation:General.Notifications.Loading')} />;
  }

  switch (task.taskType) {
    case TaskTypes.Normal:
      return (
        <Fragment>
          <NormalTask
            onSaveAsNewTask={onSaveAsNewTask}
            onChangeCompletionDate={updateCompletionDate}
            task={task}
            orgTask={orgTask}
            isOpen={props.isOpen}
            onSave={onSave}
            onRemove={onRemove}
            onCancel={onCancel}
            onCreateNewTaskTemplate={onCreateNewTaskTemplate}
            onUpdateTask={onUpdate}
            onUpdateOrgTask={setOrgTask}
            copyFromTemplate={copyFromTemplate}
            createFromTemplate={createFromTemplate}
            isActionPending={isActionPending}
            isLoading={isLoading}
            setIsActionPending={setIsActionPending}
            createInOutlook={createInOutlook}
            removeFromOutlook={removeFromOutlook}
            loadRescheduleRange={loadRescheduleRange}
            rescheduleDateRange={rescheduleDateRange}
            tags={taskTags}
            addTagToTaskState={addTagToTaskState}
            removeTagFromTaskState={removeTagFromTaskState}
            links={taskLinks}
            addLinkToTaskState={addLinkToTaskState}
            setLinkToTaskState={setLinkToTaskState}
            removeLinkFromTaskState={removeLinkFromTaskState}
            navigateToEventURL={navigateToEventURL}
            disallowSchedule={getDisallowSchedule()}
            disallowSave={props.disallowSave}
            onUpdateKPIData={onUpdateKPIData}
            onSetKPIData={onSetKPIData}
            onUpdateTaskForForm={onUpdateTaskForForm}
            windowLevel={props.windowLevel}
            showFormErrors={showFormErrors}
            onValidateForm={onValidateForm}
            selectedPivot={selectedPivot}
            onBeforeSaveCallback={onSetBeforeSaveCallback}
            hasFormFeature={hasFormFeature}
            initData={initData}
            reload={reload}
            navigateExternal={props.navigateExternal}
            hasPAFeature={hasPAFeature}
            canUpdate={canUpdate}
            canDelete={canDelete}
            compactView={compactView}
            initPivot={initPivot}
            setCompactView={setCompactView}
            formMode={formMode}
            setFormMode={setFormMode}
            setSelectedPivot={setSelectedPivot}
          ></NormalTask>
          {getAdditionalTaskModal()}
          {getShowFormErrorDialog()}
        </Fragment>
      );
    case TaskTypes.Event:
      switch (task.systemTaskType) {
        case SystemTaskTypes.Approval:
          return (
            <Fragment>
              <ApprovalTask
                onSaveAsNewTask={onSaveAsNewTask}
                onChangeCompletionDate={updateCompletionDate}
                task={task}
                orgTask={orgTask}
                isOpen={props.isOpen}
                onSave={onSave}
                onRemove={onRemove}
                onCancel={onCancel}
                onCreateNewTaskTemplate={onCreateNewTaskTemplate}
                onUpdateTask={onUpdate}
                onUpdateOrgTask={setOrgTask}
                copyFromTemplate={copyFromTemplate}
                createFromTemplate={createFromTemplate}
                isActionPending={isActionPending}
                isLoading={isLoading}
                setIsActionPending={setIsActionPending}
                createInOutlook={createInOutlook}
                removeFromOutlook={removeFromOutlook}
                loadRescheduleRange={loadRescheduleRange}
                rescheduleDateRange={rescheduleDateRange}
                tags={taskTags}
                addTagToTaskState={addTagToTaskState}
                removeTagFromTaskState={removeTagFromTaskState}
                links={taskLinks}
                addLinkToTaskState={addLinkToTaskState}
                setLinkToTaskState={setLinkToTaskState}
                removeLinkFromTaskState={removeLinkFromTaskState}
                navigateToEventURL={navigateToEventURL}
                disallowSchedule={getDisallowSchedule()}
                disallowSave={props.disallowSave}
                onUpdateKPIData={onUpdateKPIData}
                onSetKPIData={onSetKPIData}
                onUpdateTaskForForm={onUpdateTaskForForm}
                windowLevel={props.windowLevel}
                showFormErrors={showFormErrors}
                onValidateForm={onValidateForm}
                selectedPivot={selectedPivot}
                onBeforeSaveCallback={onSetBeforeSaveCallback}
                hasFormFeature={hasFormFeature}
                initData={initData}
                reload={reload}
                navigateExternal={props.navigateExternal}
                hasPAFeature={hasPAFeature}
                canUpdate={canUpdate}
                canDelete={canDelete}
                compactView={compactView}
                initPivot={initPivot}
                setCompactView={setCompactView}
                formMode={formMode}
                setFormMode={setFormMode}
                setSelectedPivot={setSelectedPivot}
              />
              {getAdditionalTaskModal()}
              {getShowFormErrorDialog()}
            </Fragment>
          );
        default:
          return (
            <Fragment>
              <EventTask
                onSaveAsNewTask={onSaveAsNewTask}
                onChangeCompletionDate={updateCompletionDate}
                task={task}
                orgTask={orgTask}
                isOpen={props.isOpen}
                onSave={onSave}
                onRemove={onRemove}
                onCancel={onCancel}
                onCreateNewTaskTemplate={onCreateNewTaskTemplate}
                onUpdateTask={onUpdate}
                onUpdateOrgTask={setOrgTask}
                copyFromTemplate={copyFromTemplate}
                createFromTemplate={createFromTemplate}
                isActionPending={isActionPending}
                isLoading={isLoading}
                setIsActionPending={setIsActionPending}
                createInOutlook={createInOutlook}
                removeFromOutlook={removeFromOutlook}
                loadRescheduleRange={loadRescheduleRange}
                rescheduleDateRange={rescheduleDateRange}
                tags={taskTags}
                addTagToTaskState={addTagToTaskState}
                removeTagFromTaskState={removeTagFromTaskState}
                links={taskLinks}
                addLinkToTaskState={addLinkToTaskState}
                setLinkToTaskState={setLinkToTaskState}
                removeLinkFromTaskState={removeLinkFromTaskState}
                navigateToEventURL={navigateToEventURL}
                disallowSchedule={getDisallowSchedule()}
                disallowSave={props.disallowSave}
                onUpdateKPIData={onUpdateKPIData}
                onSetKPIData={onSetKPIData}
                onUpdateTaskForForm={onUpdateTaskForForm}
                windowLevel={props.windowLevel}
                showFormErrors={showFormErrors}
                onValidateForm={onValidateForm}
                selectedPivot={selectedPivot}
                onBeforeSaveCallback={onSetBeforeSaveCallback}
                hasFormFeature={hasFormFeature}
                initData={initData}
                reload={reload}
                navigateExternal={props.navigateExternal}
                hasPAFeature={hasPAFeature}
                canUpdate={canUpdate}
                canDelete={canDelete}
                compactView={compactView}
                initPivot={initPivot}
                setCompactView={setCompactView}
                formMode={formMode}
                setFormMode={setFormMode}
                setSelectedPivot={setSelectedPivot}
              ></EventTask>
              {getAdditionalTaskModal()}
              {getShowFormErrorDialog()}
            </Fragment>
          );
      }
    case TaskTypes.EventTemplate:
      switch (task.systemTaskType) {
        case SystemTaskTypes.Approval:
          return (
            <Fragment>
              <ApprovalTemplateTask
                onSaveAsNewTask={onSaveAsNewTask}
                onChangeCompletionDate={updateCompletionDate}
                task={task}
                orgTask={orgTask}
                isOpen={props.isOpen}
                onSave={onSave}
                onRemove={onRemove}
                onCancel={onCancel}
                onCreateNewTaskTemplate={onCreateNewTaskTemplate}
                onUpdateTask={onUpdate}
                onUpdateOrgTask={setOrgTask}
                copyFromTemplate={copyFromTemplate}
                createFromTemplate={createFromTemplate}
                isActionPending={isActionPending}
                isLoading={isLoading}
                setIsActionPending={setIsActionPending}
                createInOutlook={createInOutlook}
                removeFromOutlook={removeFromOutlook}
                loadRescheduleRange={loadRescheduleRange}
                rescheduleDateRange={rescheduleDateRange}
                tags={taskTags}
                addTagToTaskState={addTagToTaskState}
                removeTagFromTaskState={removeTagFromTaskState}
                links={taskLinks}
                addLinkToTaskState={addLinkToTaskState}
                setLinkToTaskState={setLinkToTaskState}
                removeLinkFromTaskState={removeLinkFromTaskState}
                navigateToEventURL={navigateToEventURL}
                disallowSchedule={getDisallowSchedule()}
                disallowSave={props.disallowSave}
                onUpdateKPIData={onUpdateKPIData}
                onSetKPIData={onSetKPIData}
                onUpdateTaskForForm={onUpdateTaskForForm}
                windowLevel={props.windowLevel}
                showFormErrors={showFormErrors}
                onValidateForm={onValidateForm}
                selectedPivot={selectedPivot}
                onBeforeSaveCallback={onSetBeforeSaveCallback}
                hasFormFeature={hasFormFeature}
                initData={initData}
                reload={reload}
                navigateExternal={props.navigateExternal}
                hasPAFeature={hasPAFeature}
                canUpdate={canUpdate}
                canDelete={canDelete}
                compactView={compactView}
                initPivot={initPivot}
                setCompactView={setCompactView}
                formMode={formMode}
                setFormMode={setFormMode}
                setSelectedPivot={setSelectedPivot}
              />
              {getShowFormErrorDialog()}
            </Fragment>
          );
        default:
          return (
            <Fragment>
              <EventTemplateTask
                onSaveAsNewTask={onSaveAsNewTask}
                onChangeCompletionDate={updateCompletionDate}
                task={task}
                orgTask={orgTask}
                isOpen={props.isOpen}
                onSave={onSave}
                onRemove={onRemove}
                onCancel={onCancel}
                onCreateNewTaskTemplate={onCreateNewTaskTemplate}
                onUpdateTask={onUpdate}
                onUpdateOrgTask={setOrgTask}
                copyFromTemplate={copyFromTemplate}
                createFromTemplate={createFromTemplate}
                isActionPending={isActionPending}
                isLoading={isLoading}
                setIsActionPending={setIsActionPending}
                createInOutlook={createInOutlook}
                removeFromOutlook={removeFromOutlook}
                loadRescheduleRange={loadRescheduleRange}
                rescheduleDateRange={rescheduleDateRange}
                tags={taskTags}
                addTagToTaskState={addTagToTaskState}
                removeTagFromTaskState={removeTagFromTaskState}
                links={taskLinks}
                addLinkToTaskState={addLinkToTaskState}
                setLinkToTaskState={setLinkToTaskState}
                removeLinkFromTaskState={removeLinkFromTaskState}
                navigateToEventURL={navigateToEventURL}
                disallowSchedule={getDisallowSchedule()}
                disallowSave={props.disallowSave}
                onUpdateKPIData={onUpdateKPIData}
                onSetKPIData={onSetKPIData}
                onUpdateTaskForForm={onUpdateTaskForForm}
                windowLevel={props.windowLevel}
                showFormErrors={showFormErrors}
                onValidateForm={onValidateForm}
                selectedPivot={selectedPivot}
                onBeforeSaveCallback={onSetBeforeSaveCallback}
                hasFormFeature={hasFormFeature}
                initData={initData}
                reload={reload}
                navigateExternal={props.navigateExternal}
                hasPAFeature={hasPAFeature}
                canUpdate={canUpdate}
                canDelete={canDelete}
                compactView={compactView}
                initPivot={initPivot}
                setCompactView={setCompactView}
                formMode={formMode}
                setFormMode={setFormMode}
                setSelectedPivot={setSelectedPivot}
                onConvertChecklist={() => setShowConvertChecklistDialog(true)}
              ></EventTemplateTask>
              {getShowFormErrorDialog()}
              {getConvertChecklistDialog()}
            </Fragment>
          );
      }
    case TaskTypes.Template:
      return (
        <Fragment>
          <TemplateTask
            onSaveAsNewTask={onSaveAsNewTask}
            onChangeCompletionDate={updateCompletionDate}
            task={task}
            orgTask={orgTask}
            isOpen={props.isOpen}
            onSave={onSave}
            onRemove={onRemove}
            onCancel={onCancel}
            onCreateNewTaskTemplate={onCreateNewTaskTemplate}
            onUpdateTask={onUpdate}
            onUpdateOrgTask={setOrgTask}
            copyFromTemplate={copyFromTemplate}
            createFromTemplate={createFromTemplate}
            isActionPending={isActionPending}
            isLoading={isLoading}
            setIsActionPending={setIsActionPending}
            createInOutlook={createInOutlook}
            removeFromOutlook={removeFromOutlook}
            loadRescheduleRange={loadRescheduleRange}
            rescheduleDateRange={rescheduleDateRange}
            tags={taskTags}
            addTagToTaskState={addTagToTaskState}
            removeTagFromTaskState={removeTagFromTaskState}
            links={taskLinks}
            addLinkToTaskState={addLinkToTaskState}
            setLinkToTaskState={setLinkToTaskState}
            removeLinkFromTaskState={removeLinkFromTaskState}
            navigateToEventURL={navigateToEventURL}
            disallowSchedule={getDisallowSchedule()}
            disallowSave={props.disallowSave}
            onUpdateKPIData={onUpdateKPIData}
            onSetKPIData={onSetKPIData}
            onUpdateTaskForForm={onUpdateTaskForForm}
            windowLevel={props.windowLevel}
            showFormErrors={showFormErrors}
            onValidateForm={onValidateForm}
            selectedPivot={selectedPivot}
            onBeforeSaveCallback={onSetBeforeSaveCallback}
            hasFormFeature={hasFormFeature}
            initData={initData}
            reload={reload}
            navigateExternal={props.navigateExternal}
            hasPAFeature={hasPAFeature}
            canUpdate={canUpdate}
            canDelete={canDelete}
            compactView={compactView}
            initPivot={initPivot}
            setCompactView={setCompactView}
            formMode={formMode}
            setFormMode={setFormMode}
            setSelectedPivot={setSelectedPivot}
            onConvertChecklist={() => setShowConvertChecklistDialog(true)}
          ></TemplateTask>
          {getBeforeSaveDialog()}
          {getShowFormErrorDialog()}
          {getConvertChecklistDialog()}
        </Fragment>
      );
    case TaskTypes.Monitoring:
      return (
        <Fragment>
          <MonitoringTask
            onSaveAsNewTask={onSaveAsNewTask}
            onChangeCompletionDate={updateCompletionDate}
            task={task}
            orgTask={orgTask}
            isOpen={props.isOpen}
            onSave={onSave}
            onRemove={onRemove}
            onCancel={onCancel}
            onCreateNewTaskTemplate={onCreateNewTaskTemplate}
            onUpdateTask={onUpdate}
            onUpdateOrgTask={setOrgTask}
            copyFromTemplate={copyFromTemplate}
            createFromTemplate={createFromTemplate}
            isActionPending={isActionPending}
            isLoading={isLoading}
            setIsActionPending={setIsActionPending}
            createInOutlook={createInOutlook}
            removeFromOutlook={removeFromOutlook}
            loadRescheduleRange={loadRescheduleRange}
            rescheduleDateRange={rescheduleDateRange}
            tags={taskTags}
            addTagToTaskState={addTagToTaskState}
            removeTagFromTaskState={removeTagFromTaskState}
            links={taskLinks}
            addLinkToTaskState={addLinkToTaskState}
            setLinkToTaskState={setLinkToTaskState}
            removeLinkFromTaskState={removeLinkFromTaskState}
            navigateToEventURL={navigateToEventURL}
            disallowSchedule={getDisallowSchedule()}
            disallowSave={props.disallowSave}
            onUpdateKPIData={onUpdateKPIData}
            onSetKPIData={onSetKPIData}
            onUpdateTaskForForm={onUpdateTaskForForm}
            windowLevel={props.windowLevel}
            showFormErrors={showFormErrors}
            onValidateForm={onValidateForm}
            selectedPivot={selectedPivot}
            onBeforeSaveCallback={onSetBeforeSaveCallback}
            hasFormFeature={hasFormFeature}
            initData={initData}
            reload={reload}
            navigateExternal={props.navigateExternal}
            hasPAFeature={hasPAFeature}
            canUpdate={canUpdate}
            canDelete={canDelete}
            compactView={compactView}
            initPivot={initPivot}
            setCompactView={setCompactView}
            formMode={formMode}
            setFormMode={setFormMode}
            setSelectedPivot={setSelectedPivot}
            onConvertChecklist={() => setShowConvertChecklistDialog(true)}
          ></MonitoringTask>
          {getBeforeSaveDialog()}
          {getAdditionalTaskModal()}
          {getShowFormErrorDialog()}
          {getConvertChecklistDialog()}
        </Fragment>
      );
    default:
      throw new AppError('Unknown task type');
  }
};

export default SingleTask;
